import { Button, LinearProgress } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { Cookies } from 'react-cookie';
import { Link } from 'react-router-dom';
import ListingTable from '../../../../listing/listing';

const OrganizerBookedSlot = () => {

    const cookies = new Cookies();
    const getcookies = cookies.getAll();
    const [responseData, setresponseData] = React.useState([]);
    const [loading, setLoading] = useState(false);

    const tableData = {
        tableId: 'organizerBookedSlotTable',
        tableTitle: 'Calendar Booked Listing',
        showReload: true,
        showFilter: true,
        api: {
            url: process.env.REACT_APP_CALENDER_URL,
            deleteUrl: process.env.REACT_APP_CALENDER_URL,
            endPoint: 'calender-management-api/fetch-google-events',
            tableCountEndpoint: "calender-management-api/fetch-google-events-count",
            //   deleteSingleUserEndpoint: "delete-single-event",
        },
        reqBody: {
            source: '',
            condition: {
                limit: 5,
                skip: 0,
            },
            sort: {
                type: 'desc',
                field: '_id',
            },
            searchcondition: {},
            token:
                'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJmb28iOiJiYXIiLCJleHAiOjE2NDg1NDAyNTgsImlhdCI6MTY0ODQ1Mzg1OH0.QGs0yduTqQSjHj1mEmmuu4Ivb1IO5sgcnTCIH_DOu1E',
            secret: 'JwX.(1QHj',
            userid: getcookies.userInfo?._id
        },
        deleteSingleUserReqBody: {
            "token": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJmb28iOiJiYXIiLCJleHAiOjE2NTM3MTM5ODEsImlhdCI6MTY1MzYyNzU4MX0.QT_QnUUm_0niY3UvVIIZH4baUUKs5I4ZAORzuqRWwWE"
        },
        rowsPerPageOptions: [5, 20, 30],
        sortFields: ['event_title', 'description'],
        // paginationType: "stackPagination",
        customBtnHead: [

        ],
        buttons: [
            {
                id: "reshedule",
                type: 'reschedule',
                label: 'Re-Schedule',
                name: 'reschedule',
            },
            {
                id: "cancel",
                type: 'cancel',
                label: 'Cancel',
                name: 'cancel',
            }
        ],
        deleteModal: {

        },
        searchBarData: {
            heading: "This is Search Bar",
            api: {
                url: process.env.REACT_APP_CALENDER_URL,
                endPoint: "calender-management-api/fetch-google-events",
                reqBody: {
                    "source": "",
                    "condition": {
                        "limit": 10,
                        "skip": 0
                    },
                    "sort": {
                        "type": "asc",
                        "field": "_id"
                    },
                    userid: getcookies.userInfo?._id
                },
            },
            searchSettings: {
                datesearch: [
                    {
                        id: 0,
                        startdatelabel: "Joined On Start Date",
                        enddatelabel: "Joined On End Date",
                        submit: "Search",
                        field: "createdon_datetime",
                    },
                ],
                selectsearch: [
                    // {
                    //     id: 1,
                    //     label: "Search By Status",
                    //     field: "status",
                    //     values: ["Active", "Inactive"],
                    //     value: "",
                    // },
                ],
                textsearch: [
                    // { id: 2, label: "Search By Attende Name ", field: "phone", value: "" }
                ],
                autoCompleteSearch: [

                ],
            }
        }
    }



    const getdata = async () => {
        setLoading(true);
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-type': 'application/json' },
            body: JSON.stringify(tableData.reqBody),
        }
        const response = await fetch(
            process.env.REACT_APP_CALENDER_URL + 'calender-management-api/fetch-google-events',
            requestOptions,
        )
        // console.log("++++response+++++++",response);

        const respdata = await response.json()
        console.log('++++cv+++++++', respdata)

        if (respdata.status === 'success') {
            setLoading(false);
            setresponseData([...respdata.results.res])
            // console.log('setresponseData', setresponseData)
        } else if (respdata.error) {
            setLoading(false);
        }
    }
    var modifyTableHeaders = [
        { val: 'event_title', name: 'Event Title' },
        { val: 'notes', name: ' Decsription' },
        // { val: 'schedule', name: 'Schedule' },
        { val: 'event_type', name: 'Event Type' },
        { val: 'attendee_details', name: 'Attende Details' },
        // { val: 'attendee_details', name: 'Attende Email' },
        { val: 'username', name: 'User Name' },
        // { val: 'atten_email', name: 'Attende Email' },
        // { val: 'phone', name: 'phone' }
    ]

    // useEffect(() => {
    //   // if (responseData.length > 0) {
    //     console.log("useeffect")

    //     stroreDispatch(setTableData({ dataset: testdata,tableId: 'calendarAvailabilityTable'}));
    //   // }

    // }, [responseData])
    useEffect(() => {
        getdata()

    }, [])

    return (
        <>
            {/* {/ Meta Service Start Here /} */}

            {/* {/ Meta Service End Here /} */}
            <div className="mainContentWrapper form">
                <div className="ContentWrapper">
                    <div className="Formdiv">
                        {/* {/ Login Form Start Here /} */}
                        <div className="FormBlock">
                            <div className="buttonsetToolbar">
                                {/* <Button variant="contained">
                                    <Link to="/calendar-management-createEvent">
                                        Create New Availability
                                    </Link>
                                </Button> */}
                                <Button variant="contained">
                                    <Link to="/calendar-management-available-slots">
                                        Availability Slot
                                    </Link>
                                </Button>
                                {/* <Button
                                    href="https://d0fw1rgejc.execute-api.us-east-2.amazonaws.com/production/sync-calendar"
                                    variant="contained"
                                >
                                    Update Google Account
                                </Button> */}
                            </div>
                            {loading == true ? (
                                <LinearProgress />
                            ) : (
                                ""
                            )}
                            {/* {/ Lists Section Starts /} */}
                            <div className="reactTableWrapper">
                                <div className="reactTable">
                                    {responseData && responseData.length > 0 ? (
                                        <ListingTable
                                            tableData={tableData}
                                            dataset={responseData}
                                            modifyHeaders={modifyTableHeaders}
                                        />
                                    ) : (
                                        <p>No Records Found</p>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>

    )
}


export default OrganizerBookedSlot;