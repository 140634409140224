import { Box, LinearProgress } from '@mui/material';
import React, { useRef } from 'react'
import { useSelector } from 'react-redux'
import ListingTable from '../../../listing/listing';
import { TableSkeleton } from '../../Common/TableSkeleton';

const EventDayArrNotCreatedList = ({ reqBody }) => {
    const eDayArrNotCreatedListLoading = useSelector(state => state?.eventSummaryReducer?.eDayArrNotCreatedListLoading ? state.eventSummaryReducer.eDayArrNotCreatedListLoading : null);
    const eDayArrNotCreatedList = useSelector(state => state?.eventSummaryReducer?.eDayArrNotCreatedList ? state.eventSummaryReducer.eDayArrNotCreatedList : null);

    // Event Day Arr Table
    const modifyHeadersEDayArrNotCreated = [
        { val: 'event_user_info', name: 'Event Info/User Info' },
        { val: 'event_start_date', name: 'E-Start Date', type: "datetime", format: "DD-MM-YYYY" },
        { val: 'event_end_date', name: 'E-End Date', type: "datetime", format: "DD-MM-YYYY" },
        { val: 'event_start_end_time', name: 'E-Start/End Time' },
        { val: 'event_creation_time', name: 'Created On', type: "datetime", format: "DD/MM/YYYY hh:mm A" },
        { val: 'event_updation_time', name: 'Updated On', type: "datetime", format: "DD/MM/YYYY hh:mm A" },
        { val: 'timezone', name: 'Location' },
        { val: 'timespan', name: 'Timespan(min)' },
    ]
    const eDayArrNotCreated = useRef({
        tableId: 'eDayArrNotCreated',
        tableTitle: 'Event Day Arr Not Created List',
        showReload: true,
        showFilter: true,
        api: {
            url: process.env.REACT_APP_API_URL,
            deleteUrl: process.env.REACT_APP_BASE_URL,
            endPoint: 'googleapi/eventdayarr-not-created',
            tableCountEndpoint: "googleapi/eventdayarr-not-created",
            deleteSingleUserEndpoint: "googleapi/delete-temp-conflicting-slot",
        },
        reqBody: reqBody,
        deleteSingleUserReqBody: {
            "token": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJmb28iOiJiYXIiLCJleHAiOjE2NTM3MTM5ODEsImlhdCI6MTY1MzYyNzU4MX0.QT_QnUUm_0niY3UvVIIZH4baUUKs5I4ZAORzuqRWwWE"
        },
        rowsPerPageOptions: [5, 10, 20, 30, 50, 100],
        sortFields: [],
        enableRowActionLoader: true,
        // paginationType: "stackPagination",
        customBtnHead: [],
        buttons: [],
        searchData: {
            heading: "Search Event Day Arr Not Created",
            type: "drawer",
            // condition_type: "$or",
            formData: {
                id: 'searchForm',
                // formWrapperClass: "formWrapperClass",// Can be undefined default will be formCls
                // formInlineCLass: "searchForm", // Can be undefined default will be formInline
                formButtonClass: "submitbtnsection",
                // submitBtnName: "Search",
                // submitBtnEndIcon: "search",
                // resetBtnName: "Reset",
                // resetBtnEndIcon: "replay",
                resetForm: true,
                formAdditionalSubmissionData: {},
                fields: [
                    {
                        id: 0,
                        label: "Search By User Email",
                        name: "useremail",
                        type: "text",
                        className: 'formGroup countDiv__column--col4',
                        sx: { m: 1, minWidth: 300 },
                        payloadFormat: { key: "useremail", param: "$regex", options: "i" },

                    },
                    {
                        id: 1,
                        label: "Search By Connected Gmail",
                        name: "connected_gmail",
                        type: "text",
                        className: 'formGroup countDiv__column--col4',
                        sx: { m: 1, minWidth: 300 },
                        payloadFormat: { key: "connected_gmail", param: "$regex", options: "i" },

                    },
                ]
            }
        },
    })


    return (
        <div className="reactTable">
            {eDayArrNotCreatedListLoading && <Box sx={{ width: "100%", mt: "10px" }}><LinearProgress sx={{ height: "5px" }} /></Box>}
            {eDayArrNotCreatedList ? <ListingTable tableData={eDayArrNotCreated.current} dataset={eDayArrNotCreatedList} modifyHeaders={modifyHeadersEDayArrNotCreated} /> : <TableSkeleton count={5} />}
        </div>
    )
}

export default EventDayArrNotCreatedList