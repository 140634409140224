import moment, { tz } from 'moment';
import 'moment-timezone';
import store from '../store';

//////////////////////// Create Async Thunk Helper /////////////////////////////
export const createAsyncThunkHelper = async ({ BASE_URL, endPoint, reqBody, restBodyAttachment, restResponseAttachment, bodyWrapper }) => {
  console.log("From Craete Async Thunk Helper", BASE_URL, endPoint, reqBody, restBodyAttachment, restResponseAttachment, bodyWrapper);
  let newReqBody = { ...reqBody };

  if (typeof (restBodyAttachment) === "object" && restBodyAttachment !== null && Object.keys(restBodyAttachment).length > 0) {
    for (let key of Object.keys(restBodyAttachment)) {
      newReqBody[key] = restBodyAttachment[key];
    }
  }

  /////////////////// for Body Wrapper ////////////////////
  if (bodyWrapper !== "" && bodyWrapper) {
    console.log(newReqBody);
    newReqBody = { [bodyWrapper]: newReqBody };
  }

  const requestOptions = {
    method: "POST",
    headers: { "Content-type": "application/json" },
    body: JSON.stringify(newReqBody),
  }
  const response = await fetch(`${BASE_URL}${endPoint !== "" && endPoint !== undefined ? `${endPoint}` : ""}`, requestOptions);
  const result = await response.json();

  if (typeof (restResponseAttachment) === "object" && restResponseAttachment !== null && Object.keys(restResponseAttachment).length > 0) {
    for (let key of Object.keys(restResponseAttachment)) {
      result[key] = restResponseAttachment[key];
    }
  }

  return result;
}

////////////////////////////////////// Time Converter Helper //////////////////////////////////
export const timeConverter = ({ time = new Date(), format = "dddd MMMM Do YYYY h:mm:ss a z", ago = false, timezone = Intl.DateTimeFormat().resolvedOptions().timeZone } = {}) => {
  if (ago === true) {
    return moment.unix(time / 1000).fromNow();
  } else {
    return moment(time).tz(timezone).format(format);
  }
}
// console.log(timeConverter(1650883133739, "DD/MM/YYYY"));
// console.log(timeConverter(1650883133739))
// timeConverter(1650883133739, 'MMMM Do YYYY, h:mm:ss a');
// timeConverter(1650883133739, "MMM Do YY");
// timeConverter(1650883133739, "MM/DD/YYYY", true);



// moment().format('MMMM Do YYYY, h:mm:ss a'); // May 19th 2022, 5:02:59 pm
// moment().format("MMM Do YY");               // May 19th 22
// moment().format('dddd');                    // Thursday
// moment().format();                          // 2022-05-19T17:02:59+05:30
// moment("20111031", "YYYYMMDD").fromNow();

////////////////////// random Id Generator //////////////////////
export const randomId = () => {
  return Math.random().toString(36).slice(2) + moment().valueOf();
}

/////////////////////////////////// Check if there is already an api call pending ////////////////////////////
let requestCounter = [];
let activeRequest = false;

export const requestSequencer = ({ dispatch, func, args, priority }) => {
  if (requestCounter.length === 0) {
    startInterval();
  }
  if (priority == 1) {
    ///////////////////////////// For Higher Pririty Endpoint Call ////////////////////////////////
    requestCounter.unshift({ dispatch, func, args });
  } else {
    requestCounter.push({ dispatch, func, args });
  }

}

const handleRequest = ({ requestCounter }) => {
  activeRequest = true;
  const { dispatch, func, args } = requestCounter.shift()
  dispatch(func(args)).then(() => {
    activeRequest = false;
  })
}

const startInterval = () => {
  const interval = setInterval(() => {
    // console.log('Interval running', requestCounter)
    if (requestCounter.length > 0 && activeRequest === false) {
      handleRequest({ requestCounter });
    }
    if (requestCounter.length === 0 && activeRequest === false) {
      clearInterval(interval);
    }
  }, 50)
}

/////////////////////////////// Check if a youtube video is valid ///////////////////////////////
export const isValidYoutubeVideo = async (videoId) => {
  if (!videoId) return 'Please Provide Youtube Video Id'
  return new Promise(async (resolve) => {
    const url = `https://www.googleapis.com/youtube/v3/videos?part=id%2C+snippet&id=${videoId}&key=${process.env.REACT_APP_GOOGLE_API_KEY}`;

    const response = await fetch(url);

    const res = await response.json()

    if (res.items.length === 0 && res.pageInfo.totalResults === 0) {
      resolve({
        isValid: false,
      })
    } else {
      resolve({
        isValid: true,
        items: res.items,
        pageInfo: res.pageInfo
      })
    }
  })
}
