import React, { useState, useEffect } from 'react';
import { Autocomplete, TextField, CircularProgress, LinearProgress, FormControl } from '@mui/material';

// Debounce Hook
const useDebouncedValue = (value, delay) => {
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(() => {
    const handler = setTimeout(() => setDebouncedValue(value), delay);
    return () => clearTimeout(handler);
  }, [value, delay]);

  return debouncedValue;
};

// Autocomplete Component with MUI
const AutocompleteWithApi = ({ onSelect,label,api }) => {
  const [inputValue, setInputValue] = useState('');
  const [options, setOptions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null); // New state to track selected option


  // Debounced input value
  const debouncedInput = useDebouncedValue(inputValue, 700);

  useEffect(() => {
    
    // Trigger POST request only if debounced input is non-empty
    if (debouncedInput && !selectedOption) {
      setLoading(true);

      fetch(api, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ searchcondition:{search_string:debouncedInput}}),
      })
        .then((response) => response.json())
        .then((data) => {
            
          setOptions(data.res || []); // Adjust according to API response structure
          setLoading(false);
        })
        .catch((error) => {
          console.error('Error fetching suggestions:', error);
          setLoading(false);
        });
    } else {
      setOptions([]);
    }
  }, [debouncedInput,selectedOption]);

  return (
    <FormControl className="form_control">
        {loading ? <LinearProgress color="inherit" size={15} /> : null}
    <Autocomplete
      options={options}
      getOptionLabel={(option) => option.val} // Use `val` as the label
      onChange={(event, value) => {
        setSelectedOption(value); // Set selected option
        onSelect(value); // Pass selected object
      }}
      inputValue={inputValue}
      onInputChange={(event, newInputValue) => {
        setInputValue(newInputValue);
        setSelectedOption(null); // Reset selected option when typing
      }}
      loading={loading}
      renderInput={(params) => (
        <TextField
          {...params}
          label={label}
          variant="outlined"
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {params.InputProps.endAdornment}
              </>
            ),
          }}
        />
      )}
    />
    </FormControl>
  );
};

export default AutocompleteWithApi;
