import React, { useEffect, useState } from "react";
import "./index.css";
import { createRoot } from "react-dom/client";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import reportWebVitals from "./reportWebVitals";
import { Provider } from "react-redux";
import store from "./store";
import { Cookies, CookiesProvider } from "react-cookie";

/*****Start Backend Route Links******** */
import BackendLayout from "./Components/Backend/BackendLayout";
import Login from "./Components/Backend/Pages/Login/Login";
import ForgetPassword from "./Components/Backend/Pages/ForgetPassword/ForgetPassword";
import CreateEvent from "./Components/Backend/Pages/calendarModule/createEvent";
import AvailableSlots from "./Components/Backend/Pages/calendarModule/availableSlots";
import { setLogedinUserInfo } from "./Components/Backend/Pages/Login/Login_reducer";
import EventListing from "./Components/Backend/Pages/calendarModule/eventListing";
import NoPage from "./Components/Common/nopage";
import CancelSlot from "./Components/Backend/Pages/calendarModule/cancelslotpage";
import MiddleWare from "./Components/Backend/Pages/middleware/middleware";
import Eventsslotsummery from "./Components/Backend/Pages/calendarModule/eventsslotsummery";
import { ThankyouPage } from "./Components/Common/ThankYou";
import OrganizerBookedSlot from "./Components/Backend/Pages/calendarModule/OrganizerBookedSlot";
import IntegrationMeetingPage from "./Components/Backend/Pages/calendarModule/integrationMeeting";
import { ForgotPasswordPage } from "./Components/Backend/Pages/ForgetPassword/ForgotPasswordPage";
import ManageUser from "./Components/Backend/Pages/ManageUser/manageUser";
import DeveloperInfo from "./Components/Backend/Pages/Developer Info/DeveloperInfo";
import CronList from "./Components/Backend/Pages/CronManagement/CronList";

/*****End Backend Route Links******** */

const cookies = new Cookies();
console.log("cookies in layout==>", cookies.getAll());

const getcookies = cookies.getAll();
// console.log("getcookies from index==>", getcookies);
if (
  getcookies !== null &&
  getcookies !== undefined &&
  Object.keys(getcookies).length > 0 &&
  getcookies.userInfo !== undefined
) {
  // console.log('getcookies==>', getcookies)
  store.dispatch(setLogedinUserInfo(getcookies));
}

////////////////////
const useAuth = () => {
  console.log("AUTHED++++++++++", store.getState().loginUserKey.isLoggedIn);
  return store.getState().loginUserKey.isLoggedIn;
};
const PasswordRoute = ({ children }) => {
  const auth = useAuth();
  return auth !== true ? children : <Navigate to="/login" replace={true} />;
};

const PrivateRoute = ({ children }) => {
  const auth = useAuth();
  return auth === true ? children : <Navigate to="/" replace={true} />;
};

/////////////////////
function App() {
  return (
    <CookiesProvider>
      <Provider store={store}>
        <BrowserRouter>
          <Routes>
            {/* BackendLayout*/}
            <Route path="/" element={<BackendLayout />}>
              <Route index element={<Login />} />
              {/*added later*/}
              <Route
                path="forgot-password/:_id/:token"
                element={
                  <PasswordRoute>
                    <ForgotPasswordPage />
                  </PasswordRoute>
                }
              />
              {/*added later*/}
              <Route path="forgot-password" element={<ForgetPassword />} />
              <Route
                path="calendar-management-createEvent"
                element={
                  <PrivateRoute>
                    <CreateEvent />
                  </PrivateRoute>
                }
              />
              <Route
                path="calendar-management-edit-event/:slotID"
                element={
                  <PrivateRoute>
                    <CreateEvent />
                  </PrivateRoute>
                }
              />
              <Route
                path="calendar-management-event-listing"
                element={
                  <PrivateRoute>
                    <EventListing />
                  </PrivateRoute>
                }
              />
              <Route
                path="calendar-management-available-slots"
                element={
                  <PrivateRoute>
                    <AvailableSlots />
                  </PrivateRoute>
                }
              />
              <Route
                path="calendar-management-booked-slots"
                element={
                  <PrivateRoute>
                    <OrganizerBookedSlot />
                  </PrivateRoute>
                }
              />
              <Route
                path="calendar-management/available-slots-sync-google/:code/:secrect"
                element={
                  <PrivateRoute>
                    <AvailableSlots />
                  </PrivateRoute>
                }
              />
              <Route
                path="calendar-management/available-slots-sync-google/undefined"
                element={
                  <PrivateRoute>
                    <AvailableSlots />
                  </PrivateRoute>
                }
              />
              <Route
                path="events-slot-summery"
                element={
                  <PrivateRoute>
                    <Eventsslotsummery />
                  </PrivateRoute>
                }
              />
              <Route
                path="calendar-management-cron-list"
                element={
                  <PrivateRoute>
                    <CronList />
                  </PrivateRoute>
                }
              />
              <Route
                path="calendar-management-manageUser"
                element={
                  <PrivateRoute>
                    <ManageUser />
                  </PrivateRoute>
                }
              />
              <Route
                path="developer-info/:page"
                element={
                  <PrivateRoute>
                    <DeveloperInfo />
                  </PrivateRoute>
                }
              />
              <Route
                path="bio-energetics-discovery/:user_id"
                element={<AvailableSlots />}
              />
              <Route
                path="pece-discovery/:user_id"
                element={<AvailableSlots />}
              />
              {/* <Route path="bio-energetics-discovery" element={<AvailableSlots />} /> */}
              <Route
                path="bio-energetics-discovery-call"
                element={<AvailableSlots />}
              />
              <Route path="beto-paredes-llc" element={<AvailableSlots />} />
              <Route
                path="beto-paredes-aspire-partners/"
                element={<AvailableSlots />}
              />
              <Route
                path="beto-paredes-from-aspire-partners/"
                element={<AvailableSlots />}
              />
              <Route
                path="beto-paredes-from-aspire-partners-with-james"
                element={<AvailableSlots />}
              />
              <Route path="beto-decision-doc" element={<AvailableSlots />} />
              <Route
                path="beto-paredes-review-aspire-pharmacy"
                element={<AvailableSlots />}
              />
              <Route
                path="beto-paredes-review-bluecoast-pharmacy"
                element={<AvailableSlots />}
              />
              <Route
                path="beto-paredes-review-aspire-underpayments"
                element={<AvailableSlots />}
              />
              <Route
                path="beto-paredes-review-bluecoast-underpayments"
                element={<AvailableSlots />}
              />
              <Route
                path="beto-paredes-review-access-health-underpayments"
                element={<AvailableSlots />}
              />
              <Route
                path="beto-paredes-review-access-health-pharmacy"
                element={<AvailableSlots />}
              />
              <Route
                path="underpayment-discovery-meeting-with-lucas"
                element={<AvailableSlots />}
              />
              <Route
                path="practice2lab-meeting-with-lucas"
                element={<AvailableSlots />}
              />
              <Route
                path="aspire-partners-with-beto-jhon/"
                element={<AvailableSlots />}
              />
              <Route
                path="aspire-partners-with-beto-katherine/"
                element={<AvailableSlots />}
              />
              <Route
                path="aspire-partners-with-beto-todd/"
                element={<AvailableSlots />}
              />
              <Route
                path="aspire-partners-with-jeannette/"
                element={<AvailableSlots />}
              />
              <Route
                path="ADHC-underpayments-meeting/"
                element={<AvailableSlots />}
              />
              <Route
                path="ADHC-underpayments-meeting-test/"
                element={<AvailableSlots />}
              />
              <Route
                path="aspire-partners-with-soumya-sudeb/"
                element={<AvailableSlots />}
              />
              <Route
                path="aspire-partners-with-soumya-sutopa/"
                element={<AvailableSlots />}
              />
              <Route
                path="aspire-partners-with-soumya-moumita/"
                element={<AvailableSlots />}
              />
              <Route path="practice-2lab/" element={<AvailableSlots />} />
              <Route path=":user_id" element={<AvailableSlots />} />
              <Route path="clinical-speed/" element={<AvailableSlots />} />
              <Route path="under-payments/" element={<AvailableSlots />} />
              <Route
                path="salesbackoffice-with-lucas/"
                element={<AvailableSlots />}
              />
              <Route
                path="salesbackoffice-with-nathan/"
                element={<AvailableSlots />}
              />
              <Route
                path="meeting-with-divine-admin/"
                element={<AvailableSlots />}
              />
              <Route
                path="salesbackoffice-with-daniel/"
                element={<AvailableSlots />}
              />
              <Route
                path="discoverycall-for-sales-dev/"
                element={<AvailableSlots />}
              />
              <Route
                path="discoverycall-for-sales/"
                element={<AvailableSlots />}
              />
              <Route
                path="gameplan-call-for-sales-dev/"
                element={<AvailableSlots />}
              />
              <Route
                path="strategy-call-for-sales/"
                element={<AvailableSlots />}
              />
              <Route
                path="divineinfinitebeing/"
                element={<AvailableSlots />}
              />
              <Route path="pece-discovery" element={<AvailableSlots />} />
              <Route
                path="intake-bio-discovery/:user_id"
                element={<AvailableSlots />}
              />

              <Route
                path="beto-paredes-appointment"
                element={<AvailableSlots />}
              />

              <Route
                path="courtney-for-medical"
                element={<AvailableSlots />}
              />
               <Route
                path="bryen-for-medical"
                element={<AvailableSlots />}
              />

              <Route
                path="beto-paredes-for-medical"
                element={<AvailableSlots />}
              />


              <Route
                path="intake-pece-discovery/:user_id"
                element={<AvailableSlots />}
              />
              {/* <Route path="bio-energetics-integration" element={<AvailableSlots />} /> */}
              <Route path="pece-integration" element={<AvailableSlots />} />
              <Route
                path="pece-integration-new/:user_id"
                element={<AvailableSlots />}
              />
              <Route
                path="re-schedule-appointment/:identifier"
                element={<AvailableSlots />}
              />
              <Route path="cancel-slot/:identifier" element={<CancelSlot />} />
              <Route path="middleware/:_id" element={<MiddleWare />} />
              <Route
                path="bio-energetics-integration"
                element={<IntegrationMeetingPage />}
              />
              <Route path="/thankyou/:page" element={<ThankyouPage />} />
              <Route
                path="/practice-presence-cancer-discovery/:user_id"
                element={<AvailableSlots />}
              />
              <Route path="*" element={<NoPage />} />{" "}
            </Route>
          </Routes>
        </BrowserRouter>
      </Provider>
    </CookiesProvider>
  );
}

const container = document.getElementById("root");
const root = createRoot(container);
root.render(<App />);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
