import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import moment from "moment";
import betoparadiseopertunityes from '../../../../Assets/jsonfiels/betoparadiseopertunityes.json'


export const fetchCalenderEvents = createAsyncThunk("calendar/availability", async (reqBody) => {
  if (!reqBody.condition.endtimeunixval) reqBody.condition["endtimeunixval"] = { "$lte": moment().add(210, 'days').valueOf() };
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(reqBody),
  };
  const response = await fetch(
    // process.env.REACT_APP_CALENDER_URL + "calender-management-api/view-event-eventdayarr",
    process.env.REACT_APP_API_URL + "googleapi/fetch-event-slots",
    requestOptions
  );
  const respdata = await response.json();


  return respdata;
});

export const getPartnerCalenderEvents = createAsyncThunk("partner-calendar/availability", async (reqBody) => {
  if (!reqBody.req.condition.endtimeunixval) reqBody.req.condition["endtimeunixval"] = { "$lte": moment().add(210, 'days').valueOf() };
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(reqBody.req),
  };
  const response = await fetch(process.env.REACT_APP_API_URL + "googleapi/fetch-event-slots", requestOptions);
  const respdata = await response.json();


  return { ...respdata, loadMore: reqBody.loadMore };
});




export const fetchCalenderIntegrationEvents = createAsyncThunk("calendar/availabilityintegration", async (reqBody) => {


  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(reqBody),
  };
  const response = await fetch(
    process.env.REACT_APP_GOOGLE_CALENDER_URL + "calendar-event/view-event-eventdayarr-integration",
    requestOptions
  );
  const respdata = await response.json();


  return respdata;
});
export const fetchCalenderIntegrationEventsclockmore = createAsyncThunk("calendar/availabilityintegrationclickmore", async (reqBody) => {


  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(reqBody),
  };
  const response = await fetch(
    process.env.REACT_APP_GOOGLE_CALENDER_URL + "calendar-event/view-event-eventdayarr-integration",
    requestOptions
  );
  const respdata = await response.json();


  return respdata;
});

export const fetchCalenderAgainIntegrationEvents = createAsyncThunk("calendar/availabilityintegrationagain", async (reqBody) => {


  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(reqBody),
  };
  const response = await fetch(
    process.env.REACT_APP_GOOGLE_CALENDER_URL + "calendar-event/view-event-eventdayarr-integration",
    requestOptions
  );
  const respdata = await response.json();


  return respdata;
});
export const fetchCalenderPECEIntegrationEvents = createAsyncThunk("calendar/availabilitypeceintegration", async (reqBody) => {


  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(reqBody),
  };
  const response = await fetch(
    process.env.REACT_APP_GOOGLE_CALENDER_URL + "calendar-event/view-event-eventdayarr-pece-integration",
    requestOptions
  );
  const respdata = await response.json();


  return respdata;
});
export const fetchCalenderPECEIntegrationEventsmore = createAsyncThunk("calendar/availabilitypeceintegrationmore", async (reqBody) => {


  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(reqBody),
  };
  const response = await fetch(
    process.env.REACT_APP_GOOGLE_CALENDER_URL + "calendar-event/view-event-eventdayarr-pece-integration",
    requestOptions
  );
  const respdata = await response.json();


  return respdata;
});

export const fetchCalenderEventsLoadMore = createAsyncThunk("calendar/availabilityloadmore", async (reqBody) => {
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(reqBody),
  };
  const response = await fetch(
    // process.env.REACT_APP_CALENDER_URL + "calender-management-api/view-event-eventdayarr",
    process.env.REACT_APP_API_URL + "googleapi/fetch-event-slots",
    requestOptions
  );
  const respdata = await response.json();


  return respdata;
});
export const fetchCalenderEventsLoadMorenoconcat = createAsyncThunk("calendar/availabilityloadmorenoconcat", async (reqBody) => {
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(reqBody),
  };
  const response = await fetch(
    // process.env.REACT_APP_CALENDER_URL + "calender-management-api/view-event-eventdayarr",
    process.env.REACT_APP_API_URL + "googleapi/fetch-event-slots",
    requestOptions
  );
  const respdata = await response.json();


  return respdata;
});

export const fetchBookedSlotData = createAsyncThunk("calendar/bookedSlotData", async (reqBody) => {
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(reqBody),
  };
  const response = await fetch(
    process.env.REACT_APP_CALENDER_URL + "api/booked-slot-data",
    // "http://localhost:3000/dev/api/booked-slot-data",
    requestOptions
  );
  const respdata = await response.json();


  return respdata;
});

export const updateGoogleTokenGmail = createAsyncThunk("calendar/updateGoogleTokenGmail", async (reqBody) => {


  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(reqBody),
  };
  const response = await fetch(
    process.env.REACT_APP_GOOGLE_CALENDER_URL + "updatetokengmail",
    requestOptions
  );
  const respdata = await response.json();


  return respdata;
});

export const booknowevents = createAsyncThunk("calendar/booknowevents", async (reqBody) => {


  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(reqBody),
  };
  const response = await fetch(
    process.env.REACT_APP_GOOGLE_CALENDER_URL + "calendar-event/add-calendar",
    requestOptions
  );
  const respdata = await response.json();


  return respdata;
});

// for event listing
export const fetchEventsData = createAsyncThunk("calendar/eventlist", async (reqBody) => {
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(reqBody),
  };
  const response = await fetch(
    process.env.REACT_APP_API_URL + 'api/availability-list',
    requestOptions
  );
  const respdata = await response.json();


  return respdata;
});
export const fetchEventWithIdentifier = createAsyncThunk("fetch-event-identifier", async (requestBody) => {
  const reqBody = {
    method: "POST",
    headers: { "Content-type": "application/json" },
    body: JSON.stringify(requestBody)
  }
  const res = await fetch(process.env.REACT_APP_API_URL + "api/fetch-event-identifier", reqBody);
  const respData = await res.json();
  return respData
})
export const rescheduleevents = createAsyncThunk("calendar/rescheduleevent", async (reqBody) => {
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(reqBody),
  };
  const response = await fetch(
    process.env.REACT_APP_GOOGLE_CALENDER_URL + "calendar-event/re-schedule-event", requestOptions
  );
  const respdata = await response.json();


  return respdata;
});
export const cancelEvent = createAsyncThunk("cancel-event", async (requestBody) => {
  const reqBody = {
    method: "POST",
    headers: { "Content-type": "application/json" },
    body: JSON.stringify(requestBody),
  }
  const res = await fetch(process.env.REACT_APP_GOOGLE_CALENDER_URL + "calendar-event/cancel-event", reqBody);
  const respData = await res.json();

  return respData;
});

export const fetchOrganizerEvents = createAsyncThunk("calendar/fetch-google-events", async (reqBody) => {
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(reqBody),
  };
  const response = await fetch(
    process.env.REACT_APP_CALENDER_URL + "calender-management-api/fetch-google-events",
    requestOptions
  );
  const respdata = await response.json();


  return respdata;
});
export const fetchOrganizerEventsLoadMore = createAsyncThunk("calendar/fetch-google-events-loadmore", async (reqBody) => {
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(reqBody),
  };
  const response = await fetch(
    process.env.REACT_APP_CALENDER_URL + "calender-management-api/fetch-google-events",
    requestOptions
  );
  const respdata = await response.json();


  return respdata;
});



export const fetchPramanIdData = createAsyncThunk("fetchPramanIdData", async (reqbody) => {
  let body
  if (reqbody.lead_id && reqbody.rep_id) {
    body = {
      "lead_id": reqbody.lead_id,
      "rep_id": reqbody.rep_id
    }
  } else {
    body = { ...reqbody }
  }

  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(body),
  };

  const response = await fetch(process.env.REACT_APP_ASPIRE_PROD_URL + "lead-manage/get-lead-rep", requestOptions);
  const respdata = await response.json();
  return respdata;
})

export const FetchDetails = createAsyncThunk("FetchDetails", async (reqbody) => {
  let body

  if (reqbody.lead_id && reqbody.rep_id) {
    body = {
      "lead_id": reqbody.lead_id,
      "rep_id": reqbody.rep_id
    }
  } else {
    body = { ...reqbody }
  }

  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(body),
  };

  const response = await fetch(process.env.REACT_APP_ASPIRE_PROD_URL + "lead-manage/get-lead-and-rep-details", requestOptions);
  const respdata = await response.json();
  return respdata;


})

export const saveRepLeadData = createAsyncThunk("saveRepLeadData", async (reqbody) => {
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(reqbody),
  };


  const response = await fetch("https://i526y91jwg.execute-api.us-east-2.amazonaws.com/dev/googleapi/save-aspire-temp-info", requestOptions);
  const respdata = await response.json();
  return respdata;

})

export const checkMail = createAsyncThunk("checkMail", async (reqbody) => {
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(reqbody),
  };
  const response = await fetch(process.env.REACT_APP_ASPIRE_PROD_URL + "lead-manage/get-autocomplete-lead-and-rep-details", requestOptions);
  const respdata = await response.json();
  return respdata;
})



export const connectedAccountInfo = createAsyncThunk("connectedAccountInfo", async (reqbody) => {

  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(reqbody),
  }
  const response = await fetch(process.env.REACT_APP_API_URL + "googleapi/get-all-connected-acc", requestOptions);
  const respdata = await response.json();
  return respdata;
})








const calenderEvents = createSlice({
  name: "calender",
  initialState: {
    calenderEventsData: [],
    calenderEventsAgainData: [],
    eventsDataSet: [],
    syncGoogleAcountData: {},
    booknowData: {},
    bookNowSubmissionState: 0,
    eventId:'',
    identifier:'',
    loading: false,
    bookNowload: false,
    booknowMsg: "",
    bookedSlotData: {},
    fetchIdentifierData: {},
    rescheduleData: '',
    rescheduleMsg: '',
    rescheduleSubmissionState: 0,
    successMsg: '',
    error: false,
    errorMsg: '',
    cancelSubmissionState: 0,
    selectedSlot: null,
    openSanckBar: false,
    openSanckBarMessage: null,
    openSanckBarType: null,
    noMoreSlots: false,
    slots_end: null,
    conflictData: [],
    lead_rep_data: {},
    lead_rep_details: {},
    lead_rep_save_data: {},
    mailChackResult: {},
    actionconnectionDetails: {},
    reRunCalenderFlage:false,
    errorMessage:null,
    errorApiHit:false
  },
  reducers: {
    setEroorApiHit(state,action){
      state.errorApiHit=true
    },
    setOpenSanckBar(state, action) {
      state.openSanckBar = action.payload.open
      state.openSanckBarMessage = action.payload.message
      state.openSanckBarType = action.payload.type
    },
    setSlotSelected(state, action) {
      state.selectedSlot = action.payload ? action.payload : null
    },
    setBooknowSubmissionState(state, action) {
      state.bookNowSubmissionState = 0;
    },
    setrescheduleSubmissionStateReset(state, action) {
      state.success = false;
      state.rescheduleMsg = '';
      state.rescheduleSubmissionState = 0;
    },
    cancelSubmissionStateReset(state, action) {
      state.success = false;
      state.successMsg = '';
      state.cancelSubmissionState = 0;
    },
    chageReRunCalenderFlage(state,action){
      state.reRunCalenderFlage = false
    }

  },
  extraReducers: {
    /////////////////// Fetch Booked Slot Data for Cancel //////////////
    [fetchBookedSlotData.pending]: (state, action) => {
      state.loading = true;
    },
    [fetchBookedSlotData.rejected]: (state, action) => {
      state.loading = false;
    },
    [fetchBookedSlotData.fulfilled]: (state, action) => {
      state.loading = false;
      if (action.payload.status === "error") {
        state.errorMessage = action.payload.message;
      }
      if (action.payload.status === "success") {
        state.bookedSlotData = action.payload.results[0];
      }
    },
    // fetchCalenderIntegrationEvents

    [fetchCalenderEvents.pending]: (state, action) => {
      
      state.loading = true;
      state.slots_end = undefined;

      if (action.meta.arg.skip == 0) {
        state.calenderEventsData = []
      }
    },
    [fetchCalenderEvents.rejected]: (state, action) => {
      state.reRunCalenderFlage = true
      state.loading = false;
      state.slots_end = false;
      action.meta.arg.skip = (action.meta.arg.skip - action.meta.arg.limit) > 0 ? action.meta.arg.skip - action.meta.arg.limit : action.meta.arg.skip;

    },
    [fetchCalenderEvents.fulfilled]: (state, action) => {
      state.reRunCalenderFlage = false
      state.loading = false;
      if (action.payload.status === "error") {

        state.errorMessage = action.payload.message;
        state.reRunCalenderFlage = true
      }
      if (action.payload.status === "success") {
        state.calenderEventsData = [...action.payload.data];
        state.slots_end = action.payload.slots_end;
        
      }

    },

    [getPartnerCalenderEvents.pending]: (state, action) => {

      if(action?.meta?.arg?.searchHit){
        state.calenderEventsData=[]
      }
      state.loading = true;
      state.slots_end = undefined;
    },
    [getPartnerCalenderEvents.fulfilled]: (state, action) => {
      state.loading = false;
      if (action.payload.status === "error") state.errorMessage = 'error';
      if (action.payload.status === "success") {
        // Load More
        const urlParams = new URLSearchParams(window.location.search);
        const proudctID = urlParams.get('product_id');
        state.slots_end = action.payload.slots_end;
        if (action.payload.loadMore) {
          state.calenderEventsData = [...state.calenderEventsData, ...action.payload.data];
          state.conflictData = [...state.conflictData, ...action.payload.conflictSlots];

          if (Array.isArray(action.payload.data) && (action.payload.data.length === 0 || action.payload.data.length < action.meta.arg.limit)) state.noMoreSlots = true

          let dataset = state.conflictData;
          if (proudctID && betoparadiseopertunityes.length > 0 && (window.location.pathname !== "/gameplan-call-for-sales-dev" && window.location.pathname !== "/strategy-call-for-sales")) {
            let title = null
            for (let j in betoparadiseopertunityes) {
              if (betoparadiseopertunityes[j]._ids.includes(proudctID)) {
                title = betoparadiseopertunityes[j].title
              }
            }
            if (title) {
              for (let b in dataset) {
                dataset[b].event_title = title
              }
            }

          }
          state.conflictData = dataset;


        } else {

          state.calenderEventsData = [...action.payload.data];
          let datasetnew = state.calenderEventsData
          if (proudctID && betoparadiseopertunityes.length > 0 && (window.location.pathname !== "/gameplan-call-for-sales-dev" && window.location.pathname !== "/strategy-call-for-sales")) {
            let title = null
            for (let j in betoparadiseopertunityes) {
              if (betoparadiseopertunityes[j]._ids.includes(proudctID)) {
                title = betoparadiseopertunityes[j].title
              }
            }
            if (title) {
              for (let b in datasetnew) {
                datasetnew[b].event_title = title
              }
            }

          }
          state.calenderEventsData = datasetnew;

          state.conflictData = [...action.payload.conflictSlots];
        }


        let uniqueValue = [...new Map(state.calenderEventsData.map(item =>
          [item['startUnixVal'], item])).values()];

          state.calenderEventsData= uniqueValue
          state.slots_end = uniqueValue.length>=300?true:false;


      }



    },
    [getPartnerCalenderEvents.rejected]: (state, action) => {
      state.loading = false;
      state.slots_end = false;
      action.meta.arg.skip = (action.meta.arg.skip - action.meta.arg.limit) > 0 ? action.meta.arg.skip - action.meta.arg.limit : action.meta.arg.skip;
    },



    [fetchCalenderIntegrationEvents.pending]: (state, action) => {
      state.loading = true;
    },
    [fetchCalenderIntegrationEvents.rejected]: (state, action) => {
      state.loading = false;
    },
    [fetchCalenderIntegrationEvents.fulfilled]: (state, action) => {
      state.loading = false;
      if (action.payload.status === "error") {
        state.errorMessage = action.payload.message;
      }
      if (action.payload.status === "success") {
        state.calenderEventsData = [...action.payload.data];
      }
    },
    [fetchCalenderIntegrationEventsclockmore.pending]: (state, action) => {
      state.loading = true;
    },
    [fetchCalenderIntegrationEventsclockmore.rejected]: (state, action) => {
      state.loading = false;
    },
    [fetchCalenderIntegrationEventsclockmore.fulfilled]: (state, action) => {
      state.loading = false;
      if (action.payload.status === "error") {
        state.errorMessage = action.payload.message;
      }
      if (action.payload.status === "success") {
        state.calenderEventsData = [...state.calenderEventsData, ...action.payload.data];
      }
    },


    [fetchCalenderAgainIntegrationEvents.pending]: (state, action) => {
      state.loading = true;
    },
    [fetchCalenderAgainIntegrationEvents.rejected]: (state, action) => {
      state.loading = false;
    },
    [fetchCalenderAgainIntegrationEvents.fulfilled]: (state, action) => {
      state.loading = false;
      if (action.payload.status === "error") {
        state.errorMessage = action.payload.message;
      }
      if (action.payload.status === "success") {
        state.calenderEventsAgainData = [...action.payload.data];
      }
    },

    [fetchCalenderPECEIntegrationEvents.pending]: (state, action) => {
      state.loading = true;
    },
    [fetchCalenderPECEIntegrationEvents.rejected]: (state, action) => {
      state.loading = false;
    },
    [fetchCalenderPECEIntegrationEvents.fulfilled]: (state, action) => {
      state.loading = false;
      if (action.payload.status === "error") {
        state.errorMessage = action.payload.message;
      }
      if (action.payload.status === "success") {
        state.calenderEventsData = [...action.payload.data];
      }
    },
    [fetchCalenderPECEIntegrationEventsmore.pending]: (state, action) => {
      state.loading = true;
    },
    [fetchCalenderPECEIntegrationEventsmore.rejected]: (state, action) => {
      state.loading = false;
    },
    [fetchCalenderPECEIntegrationEventsmore.fulfilled]: (state, action) => {
      state.loading = false;
      if (action.payload.status === "error") {
        state.errorMessage = action.payload.message;
      }
      if (action.payload.status === "success") {
        state.calenderEventsData = [...state.calenderEventsData, ...action.payload.data];
      }
      // conflictSlots
    },




    [fetchCalenderEventsLoadMore.pending]: (state, action) => {
      state.loading = true;
      state.slots_end = undefined;
    },
    [fetchCalenderEventsLoadMore.rejected]: (state, action) => {
      state.loading = false;
      state.slots_end = false;
      action.meta.arg.skip = (action.meta.arg.skip - action.meta.arg.limit) > 0 ? action.meta.arg.skip - action.meta.arg.limit : action.meta.arg.skip;
      state.reRunCalenderFlage=true
    },
    [fetchCalenderEventsLoadMore.fulfilled]: (state, action) => {
      state.loading = false;
      state.reRunCalenderFlage = false
      if (action.payload.status === "error") {
        state.errorMessage = action.payload.message;
        action.meta.arg.skip = (action.meta.arg.skip - action.meta.arg.limit) > 0 ? action.meta.arg.skip - action.meta.arg.limit : action.meta.arg.skip
      }
      if (action.payload.status === "success") {
        state.slots_end = action.payload.slots_end;
        state.calenderEventsData = [...state.calenderEventsData, ...action.payload.data];
        if (Array.isArray(action.payload.data) && (action.payload.data.length === 0 || action.payload.data.length < action.meta.arg.limit)) state.noMoreSlots = true

        let uniqueValues = [...new Map(state.calenderEventsData.map(item =>
          [item['startUnixVal'], item])).values()];

          state.calenderEventsData= uniqueValues 

      }
    },

    [fetchCalenderEventsLoadMorenoconcat.pending]: (state, action) => {
      state.loading = true;
      state.slots_end = undefined;
    },
    [fetchCalenderEventsLoadMorenoconcat.rejected]: (state, action) => {
      state.loading = false;
      state.slots_end = false;
      action.meta.arg.skip = (action.meta.arg.skip - action.meta.arg.limit) > 0 ? action.meta.arg.skip - action.meta.arg.limit : action.meta.arg.skip;
    },
    [fetchCalenderEventsLoadMorenoconcat.fulfilled]: (state, action) => {
      state.loading = false;
      if (action.payload.status === "error") {
        state.errorMessage = action.payload.message;
      }
      if (action.payload.status === "success") {
        state.slots_end = action.payload.slots_end;
        state.calenderEventsData = [...action.payload.data];
      }
    },









    [fetchOrganizerEvents.pending]: (state, action) => {
      state.loading = true;
    },
    [fetchOrganizerEvents.rejected]: (state, action) => {
      state.loading = false;
    },
    [fetchOrganizerEvents.fulfilled]: (state, action) => {
      state.loading = false;
      if (action.payload.status === "error") {
        state.errorMessage = action.payload.message;
      }
      if (action.payload.status === "success") {
        state.calenderEventsData = [...action.payload.data];
      }
    },
    [fetchOrganizerEventsLoadMore.pending]: (state, action) => {
      state.loading = true;
    },
    [fetchOrganizerEventsLoadMore.rejected]: (state, action) => {
      state.loading = false;
    },
    [fetchOrganizerEventsLoadMore.fulfilled]: (state, action) => {
      state.loading = false;
      if (action.payload.status === "error") {
        state.errorMessage = action.payload.message;
      }
      if (action.payload.status === "success") {
        state.calenderEventsData = [...state.calenderEventsData, ...action.payload.data];
      }
    },
    // event list start
    [fetchEventsData.pending]: (state, action) => {
      state.loading = true;
    },
    [fetchEventsData.rejected]: (state, action) => {
      state.loading = false;
    },
    [fetchEventsData.fulfilled]: (state, action) => {
      state.loading = false;
      if (action.payload.status === "error") {
        state.errorMessage = action.payload.message;
      }
      if (action.payload.status === "success") {

        state.eventsDataSet = action.payload.results.res;
      }
    },
    // evevnt list end here
    [updateGoogleTokenGmail.pending]: (state, action) => {
      state.loading = true;
    },
    [updateGoogleTokenGmail.rejected]: (state, action) => {
      state.loading = false;
    },
    [updateGoogleTokenGmail.fulfilled]: (state, action) => {
      state.loading = false;
      if (action.payload.status === "error") {
        state.errorMessage = action.payload.message;
      }
      // if (action.payload.status === "success") {
      state.syncGoogleAcountData = action.payload.data;
      state.connectedgmail = action.payload.connectedgmailforce;
      state.gooleconnecttime = action.payload.gooleconnecttime;
      // }
    },
    [booknowevents.pending]: (state, action) => {
      state.loading = true;
      state.bookNowload = true;
      state.booknowData = "pending";
      state.booknowMsg = "";
      state.bookNowSubmissionState = 1;

    },
    [booknowevents.rejected]: (state, action) => {
      state.loading = false;
      state.bookNowload = true;
      state.booknowData = "rejected";
      state.booknowMsg = "";
      state.bookNowSubmissionState = 3;
    },
    [booknowevents.fulfilled]: (state, action) => {
      state.loading = false;
      state.bookNowload = true;
      if (action.payload.status === "error") {
        state.errorMessage = action.payload.message;
      }

      
      // if (action.payload.status === "success") {
      state.booknowData = "success";
      state.booknowMsg = action.payload.status;
      if (state.booknowMsg == "Booking done") {
        state.bookNowSubmissionState = 2;
        state.eventId = action.payload.eventId;
        state.identifier = action.payload.identifier;
      }


      // }
    },
    [fetchEventWithIdentifier.pending]: (state, action) => {
      state.loading = true;
    },
    [fetchEventWithIdentifier.fulfilled]: (state, action) => {
      if (action.payload.status === 'success') {
        state.loading = false;
        state.success = true;
        state.fetchIdentifierData = action.payload.res;
      }
    },
    [fetchEventWithIdentifier.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload
    },
    [rescheduleevents.pending]: (state, action) => {
      state.loading = true;
      state.bookNowload = true;
      state.rescheduleData = "pending";
      state.rescheduleMsg = "";
      state.rescheduleSubmissionState = 1;

    },
    [rescheduleevents.rejected]: (state, action) => {
      state.loading = false;
      state.bookNowload = false;
      state.rescheduleData = "rejected";
      state.rescheduleMsg = "";
      state.rescheduleSubmissionState = 3;
    },
    [rescheduleevents.fulfilled]: (state, action) => {
      state.loading = false;
      state.bookNowload = false;
      if (action.payload.status === "error") {
        state.errorMessage = action.payload.message;
      }
      // if (action.payload.status === "success") {
      state.rescheduleData = "success";
      state.rescheduleMsg = action.payload.status;
      state.rescheduleSubmissionState = 2;
    },
    [cancelEvent.pending]: (state, action) => {
      state.loading = true;
      state.cancelSubmissionState = 1;
    },
    [cancelEvent.fulfilled]: (state, action) => {
      if (action.payload.status === "Event Cancelled" || action.payload.status === "Failed to Cancel event") {
        state.loading = false;
        state.success = true;
        state.successMsg = action.payload.status;
        if (action.payload.status !== "Failed to Cancel event") {
          state.cancelSubmissionState = 2;
        }
      }
    },
    [cancelEvent.rejected]: (state, action) => {
      state.loading = false;
      if (action.error) {
        state.error = true;
        state.errorMsg = action.error.message;
        state.cancelSubmissionState = 3;
      }
    },
    [fetchPramanIdData.pending]: (state, action) => {

    },
    [fetchPramanIdData.fulfilled]: (state, action) => {

      if (action.payload.status === "success") {
        action.payload.result.map((item) => {
          if (item.rep_name) {
            state.lead_rep_data['rep_data'] = item
          } else if (item.lead_name) {
            state.lead_rep_data['lead_data'] = item
          }
        })
      }

    },
    [fetchPramanIdData.rejected]: (state, action) => {

    },
    [FetchDetails.pending]: (state, acton) => {

    },
    [FetchDetails.fulfilled]: (state, action) => {
      state.lead_rep_details = action.payload.result
    },
    [FetchDetails.pending]: (state, action) => {

    },
    [saveRepLeadData.pending]: (state, action) => {

    },
    [saveRepLeadData.fulfilled]: (state, action) => {
    },
    [saveRepLeadData.pending]: (state, action) => {

    },
    [checkMail.pending]: (state, action) => {

    },
    [checkMail.fulfilled]: (state, action) => {
      state.lead_rep_details = action.payload.result
    },
    [checkMail.rejected]: (state, action) => {

    },
    [connectedAccountInfo.pending]: (state, action) => {

    },
    [connectedAccountInfo.fulfilled]: (state, action) => {
      // actionconnectionDetails
    },
    [connectedAccountInfo.rejected]: (state, action) => {

    }


  },
});

export const { setBooknowSubmissionState, setrescheduleSubmissionStateReset, cancelSubmissionStateReset, setSlotSelected, setOpenSanckBar,chageReRunCalenderFlage,setEroorApiHit } = calenderEvents.actions;


export default calenderEvents.reducer;
