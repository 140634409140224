import React, { useEffect, useRef, useState } from "react";
import ListingTable from "../../../../listing/listing";
import store from "../../../../store";
import { ModalBox } from "../../../../listing/ModalBox";
import { Cookies } from "react-cookie";
import { useDispatch } from "react-redux";
import {
  clearCustomButtonClicked,
  resetReloadTableFlag,
  resetRowActionBtnClicked,
  setReloadTableFlag,
  setTableData,
} from "../../../../listing/listReducer";

function SlotListingDev() {
  const cookies = new Cookies();
  const getcookies = cookies.getAll();
  const stroreDispatch = useDispatch();

  const [responseDataDev, setresponseDataDev] = React.useState([]);
  const [useridsetdata, setuseridsetdata] = React.useState(
    getcookies.userInfo?._id
  );
  const [loaderFlag, setloaderFlag] = useState(true);
  const [norecordsFlag, setnorecordsFlag] = React.useState(false);
  const [snakbaropen, setsnakbaropen] = React.useState(false);
  const [snackBarMsg, setSnackBarMsg] = useState("");
  const [show, setShow] = useState(false);
  const [showDev, setShowDev] = useState(false);

  const tableDataCountDev = {
    tableId: "calendarTableCountDev",
    tableTitle: "Conflicting Slot List - Development",
    showReload: true,
    showFilter: true,
    api: {
      url: process.env.REACT_APP_PECE_DEV_URL,
      deleteUrl: process.env.REACT_APP_BASE_URL,
      endPoint: "calendarapi/conflicting-slot-list",
      tableCountEndpoint: "calendarapi/conflicting-slot-list-count",
      deleteSingleUserEndpoint: "delete-single-event",
    },
    reqBody: {
      source: "",
      condition: {
        limit: 5,
        skip: 0,
      },
      sort: {
        type: "asc",
        field: "_id",
      },
      searchcondition: {
        tech_location: "",
        gcalendar_slot_created_at: [],
        tech_location_address: "",
      },
      token:
        "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJmb28iOiJiYXIiLCJleHAiOjE2NDg1NDAyNTgsImlhdCI6MTY0ODQ1Mzg1OH0.QGs0yduTqQSjHj1mEmmuu4Ivb1IO5sgcnTCIH_DOu1E",
      secret: "JwX.(1QHj",
    },
    deleteSingleUserReqBody: {
      token:
        "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJmb28iOiJiYXIiLCJleHAiOjE2NTM3MTM5ODEsImlhdCI6MTY1MzYyNzU4MX0.QT_QnUUm_0niY3UvVIIZH4baUUKs5I4ZAORzuqRWwWE",
    },
    rowsPerPageOptions: [5, 20, 30],
    sortFields: ["event_title", "description"],
    // paginationType: "stackPagination",
    customBtnHead: [
      // {
      //   id: "deleteMultiple",
      //   type: "delete",
      //   title: "Delete",
      //   name: "delete",
      // },
      // {
      //   id: "editMultiple",
      //   type: "toggle",
      //   title: "Status Change",
      //   name: 'toggle'
      // }
    ],
    buttons: [
      // {
      //   id: "delete",
      //   type: "delete",
      //   label: "Delete",
      //   name: "delete",
      // },
      // {
      //   id: "edit",
      //   type: "edit",
      //   label: "Edit",
      //   name: "edit",
      // },
      {
        id: "view",
        type: "view",
        title: "View",
        name: "view",
      },
    ],
    deleteModal: {
      modalClassName: "deleteModal modalblock",
      modalStyle: {
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        width: 400,
        bgcolor: "background.paper",
        border: "2px solid #000",
        boxShadow: 24,
        p: 4,
        textAlign: "center",
      },
    },
    searchBarData: {
      heading: "This is Search Bar",
      api: {
        url: process.env.REACT_APP_PECE_DEV_URL,
        endPoint: "calendarapi/conflicting-slot-list",
        tableCountEndpoint: "calendarapi/conflicting-slot-list-count",
        reqBody: {
          source: "",
          condition: {
            limit: 5,
            skip: 0,
          },
          sort: {
            type: "asc",
            field: "_id",
          },
        },
      },
      searchSettings: {
        datesearch: [
          {
            id: 0,
            startdatelabel: "Joined On Start Date",
            enddatelabel: "Joined On End Date",
            submit: "Search",
            field: "createdon_datetime",
          },
        ],
        selectsearch: [
          {
            id: 1,
            label: "Search By Status",
            field: "status",
            values: ["Active", "Inactive"],
            value: "",
          },
        ],
        textsearch: [
          { id: 1, label: "Search By Location ", field: "tech_location", value: "" },
          { id: 2, label: "Search By Address ", field: "tech_location_address", value: "" },
          { id: 3, label: "Search By State ", field: "tech_location_state", value: "" },
          { id: 4, label: "Search By City ", field: "tech_location_city", value: "" },
        ],
        autoCompleteSearch: [
          // {
          //   id: 5,
          //   label: "Search By Name",
          //   field: "fullname_s",
          //   values: "",
          //   multiple: false,
          //   serverSearchData: {
          //     base_url: "http://localhost:3000/dev/",
          //     endpoint: "api6/autocompletemanagername",
          //     reqBody: {
          //       condition: {
          //         limit: 10,
          //         skip: 0,
          //       },
          //       sort: {
          //         type: "asc",
          //         field: "_id",
          //       },
          //       searchcondition: {},
          //       token:
          //         "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJmb28iOiJiYXIiLCJleHAiOjE2NDg1NDAyNTgsImlhdCI6MTY0ODQ1Mzg1OH0.QGs0yduTqQSjHj1mEmmuu4Ivb1IO5sgcnTCIH_DOu1E",
          //       secret: "JwX.(1QHj",
          //     },
          //   },
          // },
        ],
      },
    },
  };

  var modifyTableHeaders = [
    { val: "slot", name: "Slot" },
    { val: "startdate", name: "Start date" },
    { val: "tech_location", name: "Location" },
    { val: "tech_location_address", name: "Address" },
    { val: "tech_location_state", name: "State" },
    { val: "tech_location_city", name: "City" },
    { val: "tech_location_zip", name: "Zip" },
    { val: "created_at", name: "Created" },
    // { val: "perdayslot", name: "Slots" },
    // { val: "end_date_new", name: " End Date" },
    // { val: 'status', name: 'Status' },
    // { val: 'created_at', name: 'Created by' },
    // { val: "product_id", name: "Product" },
    // { val: 'phone', name: 'phone' }
  ];

  const getdata = async () => {
    console.log("useridsetdata++++++++++", useridsetdata);
    tableDataCountDev.reqBody.searchcondition.tech_location = "";
    tableDataCountDev.reqBody.searchcondition.gcalendar_slot_created_at = "";
    tableDataCountDev.reqBody.searchcondition.tech_location_address = "";
    const requestOptionsDev = {
      method: "POST",
      headers: { "Content-type": "application/json" },
      body: JSON.stringify(tableDataCountDev.reqBody),
    };
    const responseDev = await fetch(
      // process.env.REACT_APP_PECE_DEV_CALENDAR_URL + "calendarapi/event-information-list",
      "https://wfr9bu9th2.execute-api.us-east-1.amazonaws.com/dev/calendarapi/conflicting-slot-list",
      requestOptionsDev
    );
    // console.log("++++response+++++++",response);

    const respdataDev = await responseDev.json();
    console.log("++++cv+++++++", respdataDev);

    if (respdataDev.status == "success") {
      setresponseDataDev([...respdataDev.results.res]);
      setloaderFlag(false);

      if (responseDataDev.length == 0) {
        setnorecordsFlag(true);
      }
      console.log(
        "=================respdata.results.res===================",
        respdataDev.results.res
      );
      console.log("setresponseData", setresponseDataDev);
    } else {
      setsnakbaropen(true);
      snackBarMsg("Something Wrong!");
    }
  };

  const closeModalDev = () => {
    setShowDev(false);
    stroreDispatch(
      clearCustomButtonClicked({
        tableId: "calendarTableCountDev",
        buttonId: "view",
      })
    );
    stroreDispatch(
      resetRowActionBtnClicked({
        tableId: "calendarTableCountDev",
        buttonId: "view",
      })
    );
  };

  store.subscribe(() => {
    const storeData = store.getState();
    // console.log("storeDatastoreData", storeData);
    if (
      storeData.tableSlice.customButtonClicked.calendarTableCountDev &&
      storeData.tableSlice.customButtonClicked.calendarTableCountDev.view
    ) {
      setShowDev(true);
      ActionModalDev.current.data =
        storeData.tableSlice.tempViewData.calendarTableCountDev;
    }
  });

  const ActionModalDev = useRef({
    id: "calendarTableCountDev ",
    className: "modal1 custommodal modalblock",
    style: {},
    heading: "Details",
    labels: {
      slot: "Slot",
      startdate: "Start Date",
      tech_location: "Location",
      tech_location_address: "Address",
      tech_location_state: "State",
      tech_location_city: "City",
      tech_location_zip: "Zip",
    },
    data: null,
  });

  useEffect(() => {
    getdata();
  }, []);

  return (
    <>
      <div>
        <div className="reactTableWrapper">
          <div className="reactTable">
            {responseDataDev && responseDataDev.length > 0 ? (
              <ListingTable
                tableData={tableDataCountDev}
                dataset={responseDataDev}
                modifyHeaders={modifyTableHeaders}
              />
            ) : (
              <p>No Records Found</p>
            )}
          </div>
        </div>
        <div>
          <ModalBox
            open={showDev}
            close={closeModalDev}
            data={ActionModalDev.current}
          />
        </div>
      </div>
    </>
  );
}

export default SlotListingDev;
