import React, { useEffect, useRef } from 'react'
import { Box, LinearProgress } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import ListingTable from '../../../../../listing/listing';
import { getSixMonthSlotsList } from '../developerInfoReducer';
import { TableSkeleton } from '../../../../Common/TableSkeleton';

const SixMonthSlots = ({ env, endPoint, autoEnpointBase }) => {
    const dispatch = useDispatch();
    const { pathname } = useLocation();

    const sixMonthSlotsListLoading = useSelector(state => state?.developerInfoSlice?.sixMonthSlotsListLoading ? state.developerInfoSlice.sixMonthSlotsListLoading : null);
    const sixMonthSlotsList = useSelector(state => state?.developerInfoSlice?.sixMonthSlotsList ? state.developerInfoSlice.sixMonthSlotsList : null);

    let reqBody = {
        source: '',
        condition: { limit: 10, skip: 0 },
        sort: { type: 'asc', field: '_id' },
        searchcondition: {},
        token: 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJmb28iOiJiYXIiLCJleHAiOjE2NDg1NDAyNTgsImlhdCI6MTY0ODQ1Mzg1OH0.QGs0yduTqQSjHj1mEmmuu4Ivb1IO5sgcnTCIH_DOu1E',
        secret: 'JwX.(1QHj'
    };

    // Six Month Slots Table
    const modifyTableHeadersSixMonthSlots = [
        // { val: 'slot_link', name: 'G-Cal Summary' },
        { val: 'user_name', name: 'Name' },
        { val: 'user_email', name: 'Email' },
        { val: 'connected_gmail', name: 'Connected Gmail' },
        // { val: 'full_day', name: 'Slot Type', customVal: { "false": 'Single', "true": "Fullday", } },
        { val: 'slot_start_time', name: 'G-Cal Start Date Time' },
        { val: 'slot_end_time', name: 'G-Cal End Date Time' },
        // { val: 'last_calendar_sync_time', name: 'Conflict Checked On', type: "datetime", format: "DD/MM/YYYY hh:mm A" },
        { val: 'updated_at', name: 'Updated On System', type: "datetime", format: "DD/MM/YYYY hh:mm A" },
    ]
    const sixMonthSlots = useRef({
        tableId: 'sixMonthSlots',
        tableTitle: 'User Six Months Slots (Conflict only checked on 24hrs later G-Cal Slots from current time)',
        showReload: true,
        showFilter: true,
        api: {
            url: env,
            // deleteUrl: env,
            endPoint: endPoint,
            tableCountEndpoint: endPoint,
            deleteSingleUserEndpoint: "googleapi/delete-temp-conflicting-slot",
        },
        reqBody: reqBody,
        deleteSingleUserReqBody: {
            "token": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJmb28iOiJiYXIiLCJleHAiOjE2NTM3MTM5ODEsImlhdCI6MTY1MzYyNzU4MX0.QT_QnUUm_0niY3UvVIIZH4baUUKs5I4ZAORzuqRWwWE"
        },
        rowsPerPageOptions: [5, 10, 20, 30, 50, 100],
        sortFields: ["email", "calendar_status"],
        enableRowActionLoader: true,
        // paginationType: "stackPagination",
        customBtnHead: [],
        buttons: [],
        searchData: {
            heading: "Search User Six Months Slots",
            type: "drawer",
            condition_type: "$or",
            formData: {
                id: 'searchForm',
                // formWrapperClass: "formWrapperClass",// Can be undefined default will be formCls
                // formInlineCLass: "searchForm", // Can be undefined default will be formInline
                formButtonClass: "submitbtnsection",
                // submitBtnName: "Search",
                // submitBtnEndIcon: "search",
                // resetBtnName: "Reset",
                // resetBtnEndIcon: "replay",
                resetForm: true,
                formAdditionalSubmissionData: {},
                fields: [
                    {
                        id: 1,
                        label: "Search By G-Cal Summary",
                        name: "summary",
                        type: "text",
                        className: 'formGroup countDiv__column--col4',
                        sx: { m: 1, minWidth: 300 },
                        payloadFormat: { key: "summary", param: "$regex", options: "i" },

                    },
                    {
                        id: 2,
                        label: "Search By User Name",
                        name: "username",
                        type: "text",
                        className: 'formGroup countDiv__column--col4',
                        sx: { m: 1, minWidth: 300 },
                        payloadFormat: { key: "user_name", param: "$regex", options: "i" },

                    },
                    {
                        id: 3,
                        label: "Search By User Email",
                        name: "user_email",
                        type: "text",
                        className: 'formGroup countDiv__column--col4',
                        sx: { m: 1, minWidth: 300 },
                        payloadFormat: { key: "user_email", param: "$regex", options: "i" },

                    },
                    {
                        id: 4,
                        label: "Search By Connected Gmail",
                        name: "connected_gmail",
                        type: "text",
                        className: 'formGroup countDiv__column--col4',
                        sx: { m: 1, minWidth: 300 },
                        payloadFormat: { key: "connected_gmail", param: "$regex", options: "i" },

                    },
                    {
                        id: 5,
                        label: "Search By Full Day Slots",
                        name: "full_day",
                        className: 'formGroup countDiv__column--col4',
                        type: 'select',
                        values: [
                            { val: true, name: "True" },
                            { val: false, name: "False" },
                        ],
                        sx: { m: 1, minWidth: 120 },
                        payloadFormat: { key: "full_day" },
                        // rules: { required: true },
                        // defaultValue: 2024,
                    },
                    {
                        id: 6,
                        label: "Search by G-Calendar Start Date From",
                        name: "slot_start_time_unix",
                        type: 'datePicker',
                        dateFormat: "dd/MM/yyyy", //////////////////////////// MM should be capital //////////////////
                        className: 'formGroup countDiv__column--col4',
                        payloadFormat: { key: "slot_start_time_unix", param: "$gte" }
                    },
                    {
                        id: 7,
                        label: "Search by G-Calendar Start Date To",
                        name: "slot_start_time_unix_to",
                        type: 'datePicker',
                        dateFormat: "dd/MM/yyyy", //////////////////////////// MM should be capital //////////////////
                        className: 'formGroup countDiv__column--col4',
                        payloadFormat: { key: "slot_start_time_unix", param: "$lte" }
                    },
                    {
                        id: 8,
                        label: "Search by G-Calendar End Date From",
                        name: "slot_end_time_unix",
                        type: 'datePicker',
                        dateFormat: "dd/MM/yyyy", //////////////////////////// MM should be capital //////////////////
                        className: 'formGroup countDiv__column--col4',
                        payloadFormat: { key: "slot_end_time_unix", param: "$gte" }
                    },
                    {
                        id: 9,
                        label: "Search by G-Calendar End Date To",
                        name: "slot_end_time_unix_to",
                        type: 'datePicker',
                        dateFormat: "dd/MM/yyyy", //////////////////////////// MM should be capital //////////////////
                        className: 'formGroup countDiv__column--col4',
                        payloadFormat: { key: "slot_end_time_unix", param: "$lte" }
                    },
                ]
            }
        },
    });

    useEffect(() => {
        dispatch(getSixMonthSlotsList({ reqBody, env, endPoint }));
    }, [])

    return (
        <div className='reactTableWrapper'>
            <div className="reactTable">
                {sixMonthSlotsListLoading && <Box sx={{ width: "100%", mt: "10px" }}><LinearProgress sx={{ height: "5px" }} /></Box>}
                {sixMonthSlotsList ? <ListingTable tableData={sixMonthSlots.current} dataset={sixMonthSlotsList} modifyHeaders={modifyTableHeadersSixMonthSlots} /> : <TableSkeleton count={5} />}
            </div>
        </div>
    )
}

export default SixMonthSlots