import React from 'react'
import CalendarStatus from '../../../Listings/CalendarStatus'
import SlotsNotCreated from '../Common/SlotsNotCreated'
import ConflictingSlots from '../Common/ConflictingSlots'
import EventDayArrNotCreated from '../Common/EventDayArrNotCreated'
import SixMonthSlots from '../Common/SixMonthSlots'

const PeceDev = () => {

    return (
        <div className='ContentWrapper'>
            <CalendarStatus env={process.env.REACT_APP_PECE_DEV_URL} endPoint="googleapi/calendar-status-list" autoEnpointBase="googleapi/" />
            <hr />
            <SixMonthSlots env={process.env.REACT_APP_PECE_DEV_URL} endPoint="googleapi/users-slot-for-six-month-list" autoEnpointBase="googleapi/" />
            <hr />
            <ConflictingSlots env={process.env.REACT_APP_PECE_DEV_URL} endPoint="googleapi/conflicting-slot-list" autoEnpointBase="googleapi/" />
            <hr />
            <SlotsNotCreated env={process.env.REACT_APP_PECE_DEV_URL} endPoint="googleapi/slots-not-created-list" autoEnpointBase="googleapi/" />
            <hr />
            <EventDayArrNotCreated env={process.env.REACT_APP_PECE_DEV_URL} endPoint="googleapi/eventdayarr-not-created-list" autoEnpointBase="googleapi/" />
            <hr />
        </div>
    )
}

export default PeceDev