import React, { useEffect, useRef, useState } from "react";
import { Cookies } from "react-cookie";
import { useDispatch, useSelector } from "react-redux";
import ListingTable from "../../../../listing/listing";
import {
  clearCustomButtonClicked,
  resetReloadTableFlag,
  resetRowActionBtnClicked,
  setReloadTableFlag,
  setTableData,
} from "../../../../listing/listReducer";
import { Modal } from "../../../../listing/Modal";
import { ModalBox } from "../../../../listing/ModalBox";
import store from "../../../../store";

function CronResponseListing() {
  const cookies = new Cookies();
  const getcookies = cookies.getAll();
  const [responseData, setresponseData] = React.useState([]);
  const [loaderFlag, setloaderFlag] = useState(true);
  const [norecordsFlag, setnorecordsFlag] = React.useState(false);
  const [snakbaropen, setsnakbaropen] = React.useState(false);
  const [snackBarMsg, setSnackBarMsg] = useState("");
  const [useridsetdata, setuseridsetdata] = React.useState(
    getcookies.userInfo?._id
  );
  const [show, setShow] = useState(false);
  const storeDispatch = useDispatch();

  const manageCronRes = {
    tableId: "CronResListing",
    tableTitle: "Cron Response Table",
    showReload: true,
    showFilter: true,
    api: {
      url: process.env.REACT_APP_PECE_DEV_URL,
      deleteUrl: process.env.REACT_APP_BASE_URL,
      endPoint: "calendarapi/managecron_response_list",
      tableCountEndpoint: "calendarapi/managecron_response_count",
      deleteSingleUserEndpoint: "calendarapi/addremove-cron-manager",
    },
    reqBody: {
      source: "",
      condition: {
        limit: 5,
        skip: 0,
      },
      sort: {
        type: "desc",
        field: "_id",
      },
      searchcondition: { url: "", created_at: [] },
      token:
        "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJmb28iOiJiYXIiLCJleHAiOjE2NDg1NDAyNTgsImlhdCI6MTY0ODQ1Mzg1OH0.QGs0yduTqQSjHj1mEmmuu4Ivb1IO5sgcnTCIH_DOu1E",
      secret: "JwX.(1QHj",
    },
    deleteSingleUserReqBody: {
      operation: "delete",
    },
    rowsPerPageOptions: [5, 20, 30],
    sortFields: ["event_title", "description"],
    // paginationType: "stackPagination",
    customBtnHead: [
      // {
      //   id: "deleteMultiple",
      //   type: "delete",
      //   title: "Delete",
      //   name: "delete",
      // },
      // {
      //   id: "editMultiple",
      //   type: "toggle",
      //   title: "Status Change",
      //   name: 'toggle'
      // }
    ],
    buttons: [
      {
        id: "delete",
        type: "delete",
        label: "Delete",
        name: "delete",
        body_attach: ["_id", "url"],
      },
      // {
      //   id: "edit",
      //   type: "edit",
      //   label: "Edit",
      //   name: "edit",
      // },
      // {
      //   id: "view",
      //   type: "view",
      //   label: "View",
      //   name: "view",
      // },
    ],
    deleteModal: {
      modalClassName: "deleteModal modalblock",
      modalStyle: {
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        width: 400,
        bgcolor: "background.paper",
        border: "2px solid #000",
        boxShadow: 24,
        p: 4,
        textAlign: "center",
      },
    },
    searchBarData: {
      heading: "Cron Response Table Search Bar",
      api: {
        url: process.env.REACT_APP_PECE_DEV_URL,
        endPoint: "calendarapi/managecron_response_list",
        tableCountEndpoint: "calendarapi/managecron_response_count",
        reqBody: {
          source: "",
          condition: {
            limit: 5,
            skip: 0,
          },
          sort: {
            type: "desc",
            field: "_id",
          },
        },
      },
      searchSettings: {
        datesearch: [
          {
            id: 0,
            startdatelabel: "Joined On Start Date",
            enddatelabel: "Joined On End Date",
            submit: "Search",
            field: "createdon_datetime",
          },
        ],
        selectsearch: [
          // {
          //   id: 1,
          //   label: "Search By Status",
          //   field: "status",
          //   values: ["Active", "Inactive"],
          //   value: "",
          // },
        ],
        textsearch: [
          { id: 2, label: "Search By URL ", field: "url", value: "" },
        ],
        autoCompleteSearch: [
          // {
          //   id: 5,
          //   label: "Search By Name",
          //   field: "fullname_s",
          //   values: "",
          //   multiple: false,
          //   serverSearchData: {
          //     base_url: "http://localhost:3000/dev/",
          //     endpoint: "api6/autocompletemanagername",
          //     reqBody: {
          //       condition: {
          //         limit: 10,
          //         skip: 0,
          //       },
          //       sort: {
          //         type: "desc",
          //         field: "_id",
          //       },
          //       searchcondition: {},
          //     },
          //   },
          // },
        ],
      },
    },
  };

  var modifyTableHeaders = [
    { val: "url", name: "Url" },
    { val: "created_datetime", name: "Creation Date & Time" },
    // { val: "created_datetime", name: "Creation date & time" },
  ];

  const getdata = async () => {
    // console.log("useridsetdata++++++++++", useridsetdata);
    manageCronRes.reqBody.searchcondition.url = "";
    manageCronRes.reqBody.searchcondition.created_at = "";
    const requestOptions = {
      method: "POST",
      headers: { "Content-type": "application/json" },
      body: JSON.stringify(manageCronRes.reqBody),
    };
    const response = await fetch(
      // process.env.REACT_APP_PECE_DEV_URL + "calendarapi/managecron_response_list",
      "https://wfr9bu9th2.execute-api.us-east-1.amazonaws.com/dev/calendarapi/managecron_response_list",
      requestOptions
    );
    // console.log("++++response+++++++",response);

    const respdata = await response.json();
    console.log("++++cv+++++++", respdata);

    if (respdata.status === "success") {
      setresponseData([...respdata.results.res]);
      console.log(
        "=================respdata.results.res===================",
        respdata.results.res
      );
      setloaderFlag(false);

      if (responseData.length === 0) {
        setnorecordsFlag(true);
      }
      console.log("responseData", responseData);
    } else {
      setsnakbaropen(true);
      setSnackBarMsg("Something Wrong!");
    }
  };

  const ActionModal = useRef({
    id: "CronResListing",
    className: "modal1 custommodal modalblock",
    style: {},
    heading: "Details",
    labels: {
      created_datetime: "Creation date & time",
    },
    data: null,
  });

  const closeModal = () => {
    setShow(false);
    storeDispatch(
      clearCustomButtonClicked({
        tableId: "CronResListing",
        buttonId: "view",
      })
    );
    storeDispatch(
      resetRowActionBtnClicked({
        tableId: "CronResListing",
        buttonId: "view",
      })
    );
  };

  store.subscribe(() => {
    const storeData = store.getState();
    // console.log("storeDatastoreData", storeData);

    if (
      storeData.tableSlice.customButtonClicked.CronResListing &&
      storeData.tableSlice.customButtonClicked.CronResListing.view
    ) {
      setShow(true);
      ActionModal.current.data =
        storeData.tableSlice.tempViewData.CronResListing;
    }
  });

  useEffect(() => {
    getdata();
  }, []);

  return (
    <>
      <div>
        <div style={{ textAlign: "center" }}>Cron Response Listing</div>
        <div>
          <div className="reactTableWrapper">
            <div className="reactTable">
              {responseData && responseData.length > 0 ? (
                <ListingTable
                  tableData={manageCronRes}
                  dataset={responseData}
                  modifyHeaders={modifyTableHeaders}
                />
              ) : (
                <p>No Records Found</p>
              )}
            </div>
          </div>
          <div>
            <ModalBox
              open={show}
              close={closeModal}
              data={ActionModal.current}
            />
          </div>
        </div>
      </div>
    </>
  );
}

export default CronResponseListing;
