import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Box, Button, LinearProgress } from '@mui/material'
import { Link } from 'react-router-dom'
import ListingTable from '../../../../listing/listing'
import { TableSkeleton } from '../../../Common/TableSkeleton'
import { getCronList } from './CronReducer'
import { conflictingSlotsList } from '../EventSummaryPage/eventSummaryReducer'


const CronList = () => {
    // ---------------------------------------------------------- Invoking Hooks ---------------------------------------------------------- //
    const dispatch = useDispatch();

    // -------------------------------------------------- Global State from Redux Store -------------------------------------------------- //
    const loading = useSelector((state) => state?.cronReducer?.loading ? state.cronReducer.loading : null);
    const cronListData = useSelector((state) => state?.cronReducer?.cronList ? state.cronReducer.cronList : null);
    const conflictingSlotsData = useSelector((state) => state.eventSummaryReducer.conflictingSlotsData)

    // --------------------------------------------------------- Other Veriables --------------------------------------------------------- //
    const modifyCronTableHeader = [
        { val: 'username', name: 'Name' },
        { val: 'useremail', name: 'Email' },
        { val: 'bookingUserEmail', name: 'Connected Gmail' },
        { val: "event_title", name: "Event Title" },
        { val: 'type', name: 'Type' },
        { val: 'full_day', name: 'Full Day Slot' },
        { val: 'summary', name: 'G-Cal Summary' },
        { val: 'startdate', name: 'Start Date' },
        { val: 'gap_deletion', name: 'Deletion Gap G-Cal (Minutes)' },
        { val: 'gap_deletion_system', name: 'Deletion Gap System (Minutes)' },
        { val: 'slot', name: 'Slot' }
    ];
    const reqBody = {
        source: "",
        condition: { limit: 5, skip: 0 },
        sort: { type: "desc", field: "_id" },
        searchcondition: {},
        token: "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJmb28iOiJiYXIiLCJleHAiOjE2NDg1NDAyNTgsImlhdCI6MTY0ODQ1Mzg1OH0.QGs0yduTqQSjHj1mEmmuu4Ivb1IO5sgcnTCIH_DOu1E",
        secret: "JwX.(1QHj",
        // count: false
    };
    const cronTable = useRef({
        tableId: 'cronListTable',
        tableTitle: 'Cron Summery',
        showReload: true,
        showFilter: true,
        api: {
            url: process.env.REACT_APP_API_URL,
            deleteUrl: process.env.REACT_APP_BASE_URL,
            endPoint: 'googleapi/conflicting-slot-list',
            tableCountEndpoint: "googleapi/conflicting-slot-list",
            // endPoint: 'googleapi/all-crons-list-count',
            // tableCountEndpoint: "googleapi/all-crons-list-count",
            // deleteSingleUserEndpoint: "delete-single-event",
        },
        reqBody: reqBody,
        deleteSingleUserReqBody: {
            "token": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJmb28iOiJiYXIiLCJleHAiOjE2NDg1NDAyNTgsImlhdCI6MTY0ODQ1Mzg1OH0.QGs0yduTqQSjHj1mEmmuu4Ivb1IO5sgcnTCIH_DOu1E"
        },
        rowsPerPageOptions: [5, 20, 30, 50, 100],
        sortFields: ['username', 'useremail'],
        // paginationType: "stackPagination",
        buttons: [],
        customBtnHead: [],
        searchData: {
            // heading: "Search Event",
            type: "drawer",
            condition_type: "$or",
            formData: {
                id: 'searchForm',
                // formWrapperClass: "formWrapperClass",// Can be undefined default will be formCls
                // formInlineCLass: "searchForm", // Can be undefined default will be formInline
                formButtonClass: "submitbtnsection",
                // submitBtnName: "Search",
                // submitBtnEndIcon: "search",
                // resetBtnName: "Reset",
                // resetBtnEndIcon: "replay",
                resetForm: true,
                formAdditionalSubmissionData: {},
                fields: [
                    {
                        id: 0,
                        label: "Search By Event Title",
                        name: "event_title",
                        type: "text",
                        className: 'formGroup countDiv__column--col4',
                        sx: { m: 1, minWidth: 300 },
                        payloadFormat: { key: "event_title", param: "$regex", options: "i" },

                    },
                    {
                        id: 1,
                        label: "Search By Type",
                        name: "type",
                        className: 'formGroup countDiv__column--col4',
                        type: 'select',
                        values: [
                            { val: 1, name: 1 },
                            { val: 2, name: 2 },
                        ],
                        sx: { m: 1, minWidth: 120 },
                        payloadFormat: { key: "type" },
                        // rules: { required: true },
                        // defaultValue: 2024,
                    }
                ]
            }
        },
    })

    // -------------------------------------------------------- useEffect Section -------------------------------------------------------- //
    useEffect(() => {
        console.log("Cron List Initial Load");
        // dispatch(getCronList(reqBody))
        dispatch(conflictingSlotsList(reqBody))
    }, [])

    // ------------------------------------------------------------- return ------------------------------------------------------------- //
    return (
        <>
            <div className="mainContentWrapper form">
                <div className="ContentWrapper">
                    <div className="Formdiv">
                        <div className="FormBlock">
                            <div className="buttonsetToolbar">
                                <Button variant="contained">
                                    <Link to="/calendar-management-createEvent">Create New Availability</Link>
                                </Button>
                                <Button variant="contained">
                                    <Link to="/calendar-management-available-slots">Availability Slot</Link>
                                </Button>
                            </div>

                            {/* <Cron Table Starts Here */}
                            <div className="reactTableWrapper">
                                <div className="reactTableWrapper">
                                    <div className="reactTable">
                                        {loading && <Box sx={{ width: "100%", mt: "10px" }}><LinearProgress sx={{ height: "5px" }} /></Box>}
                                        {conflictingSlotsData ?
                                            <ListingTable tableData={cronTable.current} dataset={conflictingSlotsData} modifyHeaders={modifyCronTableHeader} />
                                            : <TableSkeleton count={5} />}
                                    </div>
                                    <hr />
                                </div>
                                {/* <Snackbar open={open} autoHideDuration={6000} onClose={() => setOpen(false)}>
                                    <Alert onClose={() => setOpen(false)} severity="success" sx={{ width: '100%' }}>
                                        {deletResponse?.deletedCount} Conflict Slots Deleted Successfully
                                    </Alert>
                                </Snackbar> */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>

    )
}

export default CronList