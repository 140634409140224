import { Alert, Box, Button, LinearProgress, Snackbar } from '@mui/material'
import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import ListingTable from '../../../../listing/listing'
import { setReloadTableFlag } from '../../../../listing/listReducer'
import { TableSkeleton } from '../../../Common/TableSkeleton'
import CalendarUsersList from '../../Listings/CalendarUsersList'
import EventDayArrNotCreatedList from '../../Listings/EventDayArrNotCreated'
import EventDaySlotsNotCreatedList from '../../Listings/EventDaySlotsNotCreatedList'
import EventSlotsNotCreatedList from '../../Listings/EventSlotsNotCreatedList'
import { conflictingSlotsList, deleteAllConflictSlots, deleteAllEventDays, deleteAllSixMonthsSlots, deleteAllTempConflictSlots, getEDayArrNotCreatedList, getEDaySlotNotCreatedList, getESlotsNotCreatedList, fetAllEventDaysList, fetAllEventsList, tempConflictingSlotsList, userSixMonthsSlotsList, getCalendarUserList, getConflictNotificationList } from './eventSummaryReducer'

function EventSummary() {
  const dispatch = useDispatch()

  const allEventsDataLoading = useSelector((state) => Boolean(state.eventSummaryReducer.allEventsDataLoading))
  const allEventsData = useSelector((state) => state.eventSummaryReducer.allEventsData)
  const allEventDaysDataLoading = useSelector((state) => Boolean(state.eventSummaryReducer.allEventDaysDataLoading))
  const allEventDaysData = useSelector((state) => state.eventSummaryReducer.allEventDaysData)
  const usersSixMonthsSlotsDataLoading = useSelector((state) => Boolean(state.eventSummaryReducer.usersSixMonthsSlotsDataLoading))
  const usersSixMonthsSlotsData = useSelector((state) => state.eventSummaryReducer.usersSixMonthsSlotsData)
  const conflictingSlotsDataLoading = useSelector((state) => Boolean(state.eventSummaryReducer.conflictingSlotsDataLoading))
  const conflictingSlotsData = useSelector((state) => state.eventSummaryReducer.conflictingSlotsData)
  const tempConflSlotsDataLoading = useSelector(state => Boolean(state?.eventSummaryReducer?.tempConflSlotsDataLoading ? state.eventSummaryReducer.tempConflSlotsDataLoading : false))
  const tempConflSlotsData = useSelector(state => state?.eventSummaryReducer?.tempConflSlotsData ? state.eventSummaryReducer.tempConflSlotsData : null)

  const conflictNotificationListLoading = useSelector(state => Boolean(state?.eventSummaryReducer?.conflictNotificationListLoading ? state.eventSummaryReducer.conflictNotificationListLoading : false))
  const conflictNotificationList = useSelector(state => state?.eventSummaryReducer?.conflictNotificationList ? state.eventSummaryReducer.conflictNotificationList : null);

  const deletResponse = useSelector((state) => state.eventSummaryReducer.deletResponse)

  const [open, setOpen] = useState(false)
  const [btnClicked, setBtnClicked] = useState(null)


  let commonReqBody = {
    source: '',
    condition: { limit: 10, skip: 0 },
    sort: { type: 'desc', field: '_id' },
    searchcondition: {},
    token: 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJmb28iOiJiYXIiLCJleHAiOjE2NDg1NDAyNTgsImlhdCI6MTY0ODQ1Mzg1OH0.QGs0yduTqQSjHj1mEmmuu4Ivb1IO5sgcnTCIH_DOu1E',
    secret: 'JwX.(1QHj',
  };

  // All Events Table
  const modifyTableHeadersEvents = [
    { val: 'username', name: 'Name' },
    { val: 'useremail', name: 'Email' },
    { val: 'event_title', name: 'Event Title' },
    { val: 'start_date_unix', name: 'Start Date', type: "datetime", format: "DD-MM-YYYY" },
    { val: 'end_date_unix', name: 'End Date', type: "datetime", format: "DD-MM-YYYY" },
    { val: 'schedule', name: 'Schedule' },
    { val: 'timespan', name: 'Timespan (Minutes)' },
    { val: 'created_at', name: 'Created On', type: "datetime", format: "DD/MM/YYYY hh:mm A" },
    { val: 'updated_at', name: 'Updated On', type: "datetime", format: "DD/MM/YYYY hh:mm A" },
  ]
  const userEvents = {
    tableId: 'userEvents',
    tableTitle: 'All Events',
    showReload: true,
    showFilter: true,
    api: {
      url: process.env.REACT_APP_API_URL,
      deleteUrl: process.env.REACT_APP_BASE_URL,
      endPoint: 'googleapi/all-events-list-count',
      tableCountEndpoint: "googleapi/all-events-list-count",
      // deleteSingleUserEndpoint: "delete-single-event",
    },
    reqBody: commonReqBody,
    deleteSingleUserReqBody: {
      "token": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJmb28iOiJiYXIiLCJleHAiOjE2NTM3MTM5ODEsImlhdCI6MTY1MzYyNzU4MX0.QT_QnUUm_0niY3UvVIIZH4baUUKs5I4ZAORzuqRWwWE"
    },
    rowsPerPageOptions: [5, 10, 20, 30, 50, 100],
    sortFields: ['username', 'useremail', 'event_title', 'start_date_unix', 'end_date_unix'],
    // paginationType: "stackPagination",
    customBtnHead: [

    ],

    searchData: {
      heading: "Search Events",
      type: "drawer",
      // condition_type: "$or",
      formData: {
        id: 'searchForm',
        // formWrapperClass: "formWrapperClass",// Can be undefined default will be formCls
        // formInlineCLass: "searchForm", // Can be undefined default will be formInline
        formButtonClass: "submitbtnsection",
        // submitBtnName: "Search",
        // submitBtnEndIcon: "search",
        // resetBtnName: "Reset",
        // resetBtnEndIcon: "replay",
        resetForm: true,
        formAdditionalSubmissionData: {},
        fields: [
          {
            id: 0,
            label: "Search By Event Title",
            name: "event_title",
            type: "text",
            className: 'formGroup countDiv__column--col4',
            sx: { m: 1, minWidth: 300 },
            payloadFormat: { key: "event_title", param: "$regex", options: "i" },

          },
          {
            id: 1,
            label: "Search By User Name",
            name: "username",
            type: "text",
            className: 'formGroup countDiv__column--col4',
            sx: { m: 1, minWidth: 300 },
            payloadFormat: { key: "username", param: "$regex", options: "i" },

          },
          {
            id: 2,
            label: "Search By User Email",
            name: "useremail",
            type: "text",
            className: 'formGroup countDiv__column--col4',
            sx: { m: 1, minWidth: 300 },
            payloadFormat: { key: "useremail", param: "$regex", options: "i" },

          },
          // {
          //   id: 3,
          //   label: "Search By Connected Gmail",
          //   name: "connected_gmail",
          //   type: "text",
          //   className: 'formGroup countDiv__column--col4',
          //   sx: { m: 1, minWidth: 300 },
          //   payloadFormat: { key: "connected_gmail", param: "$regex", options: "i" },
          // },
          {
            id: 4,
            label: "Search By Event Type",
            name: "event_type",
            className: 'formGroup countDiv__column--col4',
            type: "select",
            multiple: true,
            values: [
              { val: 1, name: "Discovery Call with Beto Paredes for BioEnergetics" },
              { val: 2, name: "PECE portal discovery webcast with Michael White" },
              { val: 3, name: "BioEnergetics Integration Meeting" },
              { val: 4, name: "PECE™ Integration Meeting" },
              { val: 5, name: "Meeting with Beto Paredes to discuss the Aspire Partners tax and expense reduction programs" }
            ],
            payloadFormat: { key: "event_type", param: "$in" },

          },
          {
            id: 5,
            label: "Search by Product",
            name: "product_id",
            className: 'formGroup countDiv__column--col4',
            multiple: true,
            type: "select",
            values: [
              {
                val: "PECE platform and TM-Flow",
                name: "PECE platform and TM-Flow",
              },
              {
                val: "BioEnergetics platform with neoGEN Series",
                name: "BioEnergetics platform with neoGEN Series",
              },
              {
                val: "Meeting with Beto Paredes to discuss the Aspire Partners tax and expense reduction programs",
                name: "Meeting with Beto Paredes to discuss the Aspire Partners tax and expense reduction programs"
              }
            ],
            payloadFormat: { key: "product_id", param: "$in" },
          },
          {
            id: 6,
            label: "Search by Timespan",
            name: "timespan",
            className: 'formGroup countDiv__column--col4',
            multiple: true,
            type: "select",
            values: [
              { val: 15, name: "15 Min" },
              { val: 30, name: "30 Min" },
              { val: 45, name: "45 Min" },
              { val: 60, name: "60 Min" },
              { val: 90, name: "90 Min" },
              { val: 120, name: "120 Min" },
            ],
            payloadFormat: { key: "timespan", param: "$in" },
          },
          {
            id: 7,
            label: "Search by Start Date From",
            name: "start_date_unix",
            type: 'datePicker',
            dateFormat: "dd/MM/yyyy", //////////////////////////// MM should be capital //////////////////
            className: 'formGroup countDiv__column--col6',
            payloadFormat: { key: "start_date_unix", param: "$gte" }
          },
          {
            id: 8,
            label: "Search by Start Date To",
            name: "start_date_unix_to",
            type: 'datePicker',
            dateFormat: "dd/MM/yyyy", //////////////////////////// MM should be capital //////////////////
            className: 'formGroup countDiv__column--col6',
            payloadFormat: { key: "start_date_unix", param: "$lte" }
          },
          {
            id: 9,
            label: "Search by End Date From",
            name: "end_date_unix",
            type: 'datePicker',
            dateFormat: "dd/MM/yyyy", //////////////////////////// MM should be capital //////////////////
            className: 'formGroup countDiv__column--col6',
            payloadFormat: { key: "end_date_unix", param: "$gte" }
          },
          {
            id: 10,
            label: "Search by End Date To",
            name: "end_date_unix_to",
            type: 'datePicker',
            dateFormat: "dd/MM/yyyy", //////////////////////////// MM should be capital //////////////////
            className: 'formGroup countDiv__column--col6',
            payloadFormat: { key: "end_date_unix", param: "$lte" }
          },

        ]
      }
    },
  }

  // All Event Days Table
  const modifyTableHeadersUserEventDays = [
    { val: 'username', name: 'Name' },
    { val: 'useremail', name: 'Email' },
    { val: 'event_title', name: 'Event Title' },
    { val: 'startdate', name: 'Date' },
    { val: 'slots_str', name: 'Slots' },
    { val: 'timespan', name: 'Timespan (Minutes)' }
  ]
  const userEventDays = {
    tableId: 'userEventDays',
    tableTitle: 'All Event Days',
    showReload: true,
    showFilter: true,
    api: {
      url: process.env.REACT_APP_API_URL,
      deleteUrl: process.env.REACT_APP_BASE_URL,
      endPoint: 'googleapi/all-eventday-list-count',
      tableCountEndpoint: "googleapi/all-eventday-list-count",
      // deleteSingleUserEndpoint: "delete-single-event",
    },
    reqBody: commonReqBody,
    deleteSingleUserReqBody: {
      "token": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJmb28iOiJiYXIiLCJleHAiOjE2NTM3MTM5ODEsImlhdCI6MTY1MzYyNzU4MX0.QT_QnUUm_0niY3UvVIIZH4baUUKs5I4ZAORzuqRWwWE"
    },
    rowsPerPageOptions: [5, 10, 20, 30, 50, 100],
    sortFields: ['username', 'useremail', 'timespan'],
    // paginationType: "stackPagination",
    customBtnHead: [],
    searchData: {
      heading: "Search Event Days",
      type: "drawer",
      // condition_type: "$or",
      formData: {
        id: 'searchForm',
        // formWrapperClass: "formWrapperClass",// Can be undefined default will be formCls
        // formInlineCLass: "searchForm", // Can be undefined default will be formInline
        formButtonClass: "submitbtnsection",
        // submitBtnName: "Search",
        // submitBtnEndIcon: "search",
        // resetBtnName: "Reset",
        // resetBtnEndIcon: "replay",
        resetForm: true,
        formAdditionalSubmissionData: {},
        fields: [
          {
            id: 0,
            label: "Search By Event Title",
            name: "event_title",
            type: "text",
            className: 'formGroup countDiv__column--col4',
            sx: { m: 1, minWidth: 300 },
            payloadFormat: { key: "event_title", param: "$regex", options: "i" },

          },
          {
            id: 2,
            label: "Search By User Name",
            name: "username",
            type: "text",
            className: 'formGroup countDiv__column--col4',
            sx: { m: 1, minWidth: 300 },
            payloadFormat: { key: "username", param: "$regex", options: "i" },

          },
          // {
          //   id: 3,
          //   label: "Search By Connected Gmail",
          //   name: "connected_gmail",
          //   type: "text",
          //   className: 'formGroup countDiv__column--col4',
          //   sx: { m: 1, minWidth: 300 },
          //   payloadFormat: { key: "connected_gmail", param: "$regex", options: "i" },
          // },
          {
            id: 4,
            label: "Search By User Email",
            name: "useremail",
            type: "text",
            className: 'formGroup countDiv__column--col4',
            sx: { m: 1, minWidth: 300 },
            payloadFormat: { key: "useremail", param: "$regex", options: "i" },

          },
          {
            id: 5,
            label: "Search by Event Day From",
            name: "start_datetime_unix",
            type: 'datePicker',
            dateFormat: "dd/MM/yyyy", //////////////////////////// MM should be capital //////////////////
            className: 'formGroup countDiv__column--col6',
            payloadFormat: { key: "start_datetime_unix", param: "$gte" }
          },
          {
            id: 6,
            label: "Search by Event Day To",
            name: "start_datetime_unix_tp",
            type: 'datePicker',
            dateFormat: "dd/MM/yyyy", //////////////////////////// MM should be capital //////////////////
            className: 'formGroup countDiv__column--col6',
            payloadFormat: { key: "start_datetime_unix", param: "$lte" }
          },
        ]
      }
    },
  }

  // Six Month Slots Table
  const modifyTableHeadersUserSixMonthsSlot = [
    { val: 'slot_link', name: 'G-Cal Summary' },
    { val: 'user_name', name: 'Name' },
    { val: 'user_email', name: 'Email' },
    { val: 'connected_gmail', name: 'Connected Gmail' },
    { val: 'full_day', name: 'Slot Type', customVal: { "false": 'Single', "true": "Fullday", } },
    { val: 'slot_start_time', name: 'G-Cal Start Date Time' },
    { val: 'slot_end_time', name: 'G-Cal End Date Time' },
    { val: 'last_calendar_sync_time', name: 'Conflict Checked On', type: "datetime", format: "DD/MM/YYYY hh:mm A" },
    { val: 'updated_at', name: 'Updated On System', type: "datetime", format: "DD/MM/YYYY hh:mm A" },
  ]
  const userSixMonthsSlot = {
    tableId: 'userSixMonthsSlot',
    tableTitle: 'User Six Months Slots (Conflict only checked on 24hrs later G-Cal Slots from current time)',
    showReload: true,
    showFilter: true,
    api: {
      url: process.env.REACT_APP_API_URL,
      deleteUrl: process.env.REACT_APP_BASE_URL,
      endPoint: 'googleapi/users-slot-for-six-month-list',
      tableCountEndpoint: "googleapi/users-slot-for-six-month-list",
      // deleteSingleUserEndpoint: "delete-single-event",
    },
    reqBody: { ...commonReqBody, condition: { limit: 20, skip: 0 } },
    deleteSingleUserReqBody: {
      "token": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJmb28iOiJiYXIiLCJleHAiOjE2NTM3MTM5ODEsImlhdCI6MTY1MzYyNzU4MX0.QT_QnUUm_0niY3UvVIIZH4baUUKs5I4ZAORzuqRWwWE"
    },
    rowsPerPageOptions: [5, 20, 30, 50, 100],
    sortFields: ['user_name', 'user_email', 'connected_gmail', 'updated_at'],
    // paginationType: "stackPagination",
    customBtnHead: [

    ],
    searchData: {
      heading: "Search User Six Months Slots",
      type: "drawer",
      condition_type: "$or",
      formData: {
        id: 'searchForm',
        // formWrapperClass: "formWrapperClass",// Can be undefined default will be formCls
        // formInlineCLass: "searchForm", // Can be undefined default will be formInline
        formButtonClass: "submitbtnsection",
        // submitBtnName: "Search",
        // submitBtnEndIcon: "search",
        // resetBtnName: "Reset",
        // resetBtnEndIcon: "replay",
        resetForm: true,
        formAdditionalSubmissionData: {},
        fields: [
          {
            id: 1,
            label: "Search By G-Cal Summary",
            name: "summary",
            type: "text",
            className: 'formGroup countDiv__column--col4',
            sx: { m: 1, minWidth: 300 },
            payloadFormat: { key: "summary", param: "$regex", options: "i" },

          },
          {
            id: 2,
            label: "Search By User Name",
            name: "username",
            type: "text",
            className: 'formGroup countDiv__column--col4',
            sx: { m: 1, minWidth: 300 },
            payloadFormat: { key: "user_name", param: "$regex", options: "i" },

          },
          {
            id: 3,
            label: "Search By User Email",
            name: "user_email",
            type: "text",
            className: 'formGroup countDiv__column--col4',
            sx: { m: 1, minWidth: 300 },
            payloadFormat: { key: "user_email", param: "$regex", options: "i" },

          },
          {
            id: 4,
            label: "Search By Connected Gmail",
            name: "connected_gmail",
            type: "text",
            className: 'formGroup countDiv__column--col4',
            sx: { m: 1, minWidth: 300 },
            payloadFormat: { key: "connected_gmail", param: "$regex", options: "i" },

          },
          {
            id: 5,
            label: "Search By Full Day Slots",
            name: "full_day",
            className: 'formGroup countDiv__column--col4',
            type: 'select',
            values: [
              { val: true, name: "True" },
              { val: false, name: "False" },
            ],
            sx: { m: 1, minWidth: 120 },
            payloadFormat: { key: "full_day" },
            // rules: { required: true },
            // defaultValue: 2024,
          },
          {
            id: 6,
            label: "Search by G-Calendar Start Date From",
            name: "slot_start_time_unix",
            type: 'datePicker',
            dateFormat: "dd/MM/yyyy", //////////////////////////// MM should be capital //////////////////
            className: 'formGroup countDiv__column--col4',
            payloadFormat: { key: "slot_start_time_unix", param: "$gte" }
          },
          {
            id: 7,
            label: "Search by G-Calendar Start Date To",
            name: "slot_start_time_unix_to",
            type: 'datePicker',
            dateFormat: "dd/MM/yyyy", //////////////////////////// MM should be capital //////////////////
            className: 'formGroup countDiv__column--col4',
            payloadFormat: { key: "slot_start_time_unix", param: "$lte" }
          },
          {
            id: 8,
            label: "Search by G-Calendar End Date From",
            name: "slot_end_time_unix",
            type: 'datePicker',
            dateFormat: "dd/MM/yyyy", //////////////////////////// MM should be capital //////////////////
            className: 'formGroup countDiv__column--col4',
            payloadFormat: { key: "slot_end_time_unix", param: "$gte" }
          },
          {
            id: 9,
            label: "Search by G-Calendar End Date To",
            name: "slot_end_time_unix_to",
            type: 'datePicker',
            dateFormat: "dd/MM/yyyy", //////////////////////////// MM should be capital //////////////////
            className: 'formGroup countDiv__column--col4',
            payloadFormat: { key: "slot_end_time_unix", param: "$lte" }
          },
        ]
      }
    },

  }

  // Conflicting Slots Table
  const modifyTableHeadersConflictingSlots = [
    { val: "event_user_info", name: "Event Details" },
    { val: 'deletion_type_slot_type', name: 'D-Type/S-Type' },
    { val: 'gcalendar_slot_created_at', name: 'G-Created', type: "datetime", format: "DD/MM/YYYY hh:mm:ss A" },
    { val: 'gcalendar_slot_entered_at', name: 'G-Entered', type: "datetime", format: "DD/MM/YYYY hh:mm:ss A" },
    { val: 'gcalendar_slot_deleted_at', name: 'G-Deleted', type: "datetime", format: "DD/MM/YYYY hh:mm:ss A" },
    { val: 'startdate_slot', name: 'Start Date/Slot' },
    { val: 'gap_deletion_format', name: 'Gap G-Cal' },
    { val: 'gap_deletion_system_format', name: 'Gap Sys' },
    { val: 'g_event_created_after_flag_format', name: 'G-Creation' },
  ]
  const conflictingSlots = {
    tableId: 'conflictingSlots',
    tableTitle: 'Conflicting Slots',
    showReload: true,
    showFilter: true,
    api: {
      url: process.env.REACT_APP_API_URL,
      deleteUrl: process.env.REACT_APP_BASE_URL,
      endPoint: 'googleapi/conflicting-slot-list',
      tableCountEndpoint: "googleapi/conflicting-slot-list",
      // deleteSingleUserEndpoint: "delete-single-event",
    },
    reqBody: { ...commonReqBody, condition: { limit: 20, skip: 0 } },
    deleteSingleUserReqBody: {
      "token": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJmb28iOiJiYXIiLCJleHAiOjE2NTM3MTM5ODEsImlhdCI6MTY1MzYyNzU4MX0.QT_QnUUm_0niY3UvVIIZH4baUUKs5I4ZAORzuqRWwWE"
    },
    rowsPerPageOptions: [5, 20, 30, 50, 100],
    sortFields: ['username', 'useremail'],
    // paginationType: "stackPagination",
    buttons: [
      // {
      //   id: "viewApi",
      //   label: "View API",
      //   type: "api",
      //   category: "view",
      //   icon_type: "preview",
      //   className: "preview",
      //   modalTitle: "Event Details",
      //   modalHeader: "",
      //   modalClassName: "view_modal event_view_modal",
      //   api_data: {
      //     base_url: process.env.REACT_APP_API_URL,
      //     endpoint: "googleapi/conflicting-slot-list",
      //     body: {
      //       "source": "",
      //       "condition": { "limit": 1, "skip": 0 },
      //       "sort": { "type": "asc", "field": "fullname" },
      //       "searchcondition": {}
      //     }
      //   },
      //   headers: [
      //     { val: "event_title", label: "Event Title" },
      //     { val: "username", label: "User Name" },
      //     { val: "useremail", label: "User Email" },
      //     { val: "product_id", label: "Product" },
      //     { val: "description", label: "Description" },
      //     { val: "timespan", label: "Timespan" },
      //     { val: "created_at", label: "Created On", type: "datetime", format: "MM/DD/YYYY hh:mm:ss A" },
      //   ]
      // },
    ],
    customBtnHead: [],
    searchData: {
      heading: "Search Conflicting Slots",
      type: "drawer",
      // condition_type: "$or",
      formData: {
        id: 'searchForm',
        // formWrapperClass: "formWrapperClass",// Can be undefined default will be formCls
        // formInlineCLass: "searchForm", // Can be undefined default will be formInline
        formButtonClass: "submitbtnsection",
        // submitBtnName: "Search",
        // submitBtnEndIcon: "search",
        // resetBtnName: "Reset",
        // resetBtnEndIcon: "replay",
        resetForm: true,
        formAdditionalSubmissionData: {},
        fields: [
          {
            id: 13,
            label: "Search By G-Event Created",
            name: "g_event_created_after_flag",
            className: 'formGroup countDiv__column--col4',
            type: 'select',
            values: [
              { val: true, name: "After" },
              { val: false, name: "Before" },
            ],
            sx: { m: 1, minWidth: 120 },
            payloadFormat: { key: "g_event_created_after_flag" },
            // defaultValue: "",
          },
          {
            id: 1,
            label: "Search By Type",
            name: "type",
            className: 'formGroup countDiv__column--col4',
            type: 'select',
            values: [
              { val: 1, name: 1 },
              { val: 2, name: 2 },
            ],
            sx: { m: 1, minWidth: 120 },
            payloadFormat: { key: "type" },
            // rules: { required: true },
            // defaultValue: 2024,
          },
          {
            id: 2,
            label: "Search By Day Type",
            name: "full_day",
            className: 'formGroup countDiv__column--col4',
            type: 'select',
            values: [
              { val: true, name: "Full Day" },
              { val: false, name: "Single Day" },
            ],
            sx: { m: 1, minWidth: 120 },
            payloadFormat: { key: "full_day" },
            // rules: { required: true },
            // defaultValue: 2024,
          },
          {
            id: 3,
            label: "Search By G-Cal Summary",
            name: "summary",
            type: "text",
            className: 'formGroup countDiv__column--col4',
            sx: { m: 1, minWidth: 300 },
            payloadFormat: { key: "summary", param: "$regex", options: "i" },

          },
          {
            id: 4,
            label: "Search By User Name",
            name: "username",
            type: "text",
            className: 'formGroup countDiv__column--col4',
            sx: { m: 1, minWidth: 300 },
            payloadFormat: { key: "username", param: "$regex", options: "i" },

          },
          {
            id: 5,
            label: "Search By User Email",
            name: "useremail",
            type: "text",
            className: 'formGroup countDiv__column--col6',
            sx: { m: 1, minWidth: 300 },
            payloadFormat: { key: "useremail", param: "$regex", options: "i" },

          },
          {
            id: 6,
            label: "Search By Connected Gmail",
            name: "connected_gmail",
            type: "text",
            className: 'formGroup countDiv__column--col6',
            sx: { m: 1, minWidth: 300 },
            payloadFormat: { key: "bookingUserEmail", param: "$regex", options: "i" },

          },
          {
            id: 7,
            label: "Search by Calendar Start Date From",
            name: "start_date_format_unix",
            type: 'datePicker',
            dateFormat: "dd/MM/yyyy", //////////////////////////// MM should be capital //////////////////
            className: 'formGroup countDiv__column--col6',
            payloadFormat: { key: "start_date_format_unix", param: "$gte" }
          },
          {
            id: 8,
            label: "Search by Calendar Start Date To",
            name: "start_date_format_unix_to",
            type: 'datePicker',
            dateFormat: "dd/MM/yyyy", //////////////////////////// MM should be capital //////////////////
            className: 'formGroup countDiv__column--col6',
            payloadFormat: { key: "start_date_format_unix", param: "$lte" }
          },
          {
            id: 9,
            label: "Search by G-Calendar Start Date From",
            name: "slot_start_time_unix",
            type: 'datePicker',
            dateFormat: "dd/MM/yyyy", //////////////////////////// MM should be capital //////////////////
            className: 'formGroup countDiv__column--col6',
            payloadFormat: { key: "google_calendar_slot_data.slot_start_time_unix", param: "$gte" }
          },
          {
            id: 10,
            label: "Search by G-Calendar Start Date To",
            name: "slot_start_time_unix_to",
            type: 'datePicker',
            dateFormat: "dd/MM/yyyy", //////////////////////////// MM should be capital //////////////////
            className: 'formGroup countDiv__column--col6',
            payloadFormat: { key: "google_calendar_slot_data.slot_start_time_unix", param: "$lte" }
          },
          {
            id: 11,
            label: "Search by G-Calendar End Date From",
            name: "slot_end_time_unix",
            type: 'datePicker',
            dateFormat: "dd/MM/yyyy", //////////////////////////// MM should be capital //////////////////
            className: 'formGroup countDiv__column--col6',
            payloadFormat: { key: "google_calendar_slot_data.slot_end_time_unix", param: "$gte" }
          },
          {
            id: 12,
            label: "Search by G-Calendar End Date To",
            name: "slot_end_time_unix_to",
            type: 'datePicker',
            dateFormat: "dd/MM/yyyy", //////////////////////////// MM should be capital //////////////////
            className: 'formGroup countDiv__column--col6',
            payloadFormat: { key: "google_calendar_slot_data.slot_end_time_unix", param: "$lte" }
          },
          {
            id: 0,
            label: "Search By Event Title",
            name: "event_title",
            type: "text",
            className: 'formGroup countDiv__column--col4',
            sx: { m: 1, minWidth: 300 },
            payloadFormat: { key: "event_title", param: "$regex", options: "i" },

          },
          // {
          //   id: 5,
          //   label: "Search by Calendar End Date From",
          //   name: "endtimeunixval",
          //   type: 'datePicker',
          //   dateFormat: "dd/MM/yyyy", //////////////////////////// MM should be capital //////////////////
          //   className: 'formGroup countDiv__column--col6',
          //   payloadFormat: { key: "endtimeunixval", param: "$gte" }
          // },
          // {
          //   id: 10,
          //   label: "Search by Calendar End Date To",
          //   name: "endtimeunixval_to",
          //   type: 'datePicker',
          //   dateFormat: "dd/MM/yyyy", //////////////////////////// MM should be capital //////////////////
          //   className: 'formGroup countDiv__column--col6',
          //   payloadFormat: { key: "endtimeunixval", param: "$lte" }
          // },
        ]
      }
    },
  }

  // Temp Conflicting Slots Table
  const modifyHeadersTempConfSlots = [
    { val: 'slot_link', name: 'Slot Link' },
    { val: 'slot_start_end_time', name: 'Slot Timing' },
    // { val: 'useremail', name: 'User Email' },
    // { val: 'organizer_email', name: 'Organizer Email' },
    { val: 'useremail_organizer_email', name: 'User/Organizer Email' },
    { val: "createdon_datetime", name: "Created On", type: "datetime", format: "DD/MM/YYYY hh:mm:ss A" },
    // { val: 'g_event_created_at', name: 'G-Created', type: "datetime", format: "DD/MM/YYYY hh:mm:ss A" },
    { val: 'g_event_updated_at', name: 'G-Updated', type: "datetime", format: "DD/MM/YYYY hh:mm:ss A" },
    { val: 'location', name: 'Location' },
    // { val: 'slot_end_time', name: 'Slot End' },
    // { val: 'slot_start_time', name: 'Slot Start' },
    { val: 'full_day_format', name: 'Slot Type' },
  ]
  const tempConflictingSlots = useRef({
    tableId: 'tempConflictingSlots',
    tableTitle: 'Temp Conflicting Slots',
    showReload: true,
    showFilter: true,
    api: {
      url: process.env.REACT_APP_API_URL,
      deleteUrl: process.env.REACT_APP_BASE_URL,
      endPoint: 'googleapi/temp-conflicting-slot-list',
      tableCountEndpoint: "googleapi/temp-conflicting-slot-list",
      deleteSingleUserEndpoint: "googleapi/delete-temp-conflicting-slot",
    },
    reqBody: { ...commonReqBody, condition: { limit: 20, skip: 0 } },
    deleteSingleUserReqBody: {
      "token": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJmb28iOiJiYXIiLCJleHAiOjE2NTM3MTM5ODEsImlhdCI6MTY1MzYyNzU4MX0.QT_QnUUm_0niY3UvVIIZH4baUUKs5I4ZAORzuqRWwWE"
    },
    rowsPerPageOptions: [5, 20, 30, 50, 100],
    sortFields: [],
    enableRowActionLoader: true,
    // paginationType: "stackPagination",
    customBtnHead: [],
    buttons: [
      {
        id: "delete",
        label: "Delete Record",
        type: "api",
        category: "modal",
        icon_type: "delete",
        className: "delete_data",
        modalTitle: "<p>Are you sure you want to delete these records?</p>",
        modalHeader: "<p>Alert !!</p>",
        modalClassName: "delete_modal",
        btn_label: ['Yes', "No"],
        api_data: {
          base_url: process.env.REACT_APP_API_URL,
          endpoint: "googleapi/delete-temp-conflicting-slot",
          body: {
            "source": "",
            "secret": "AZ|lepL`",
            "token": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJmb28iOiJiYXIiLCJleHAiOjE2NTIxNjM3MDUsImlhdCI6MTY1MjA3NzMwNX0.3kB4iRr5JbmV81Dl5gKA7fiTaMEEp0xbsWoO-wA0XUo",
          }
        }
      },
    ],
    searchData: {
      heading: "Search Temp Conflicting Slots",
      type: "drawer",
      // condition_type: "$or",
      formData: {
        id: 'searchForm',
        // formWrapperClass: "formWrapperClass",// Can be undefined default will be formCls
        // formInlineCLass: "searchForm", // Can be undefined default will be formInline
        formButtonClass: "submitbtnsection",
        // submitBtnName: "Search",
        // submitBtnEndIcon: "search",
        // resetBtnName: "Reset",
        // resetBtnEndIcon: "replay",
        resetForm: true,
        formAdditionalSubmissionData: {},
        fields: [
          {
            id: 0,
            label: "Search By Day Type",
            name: "full_day",
            className: 'formGroup countDiv__column--col4',
            type: 'select',
            values: [
              { val: true, name: "Full Day" },
              { val: false, name: "Single Day" },
            ],
            sx: { m: 1, minWidth: 120 },
            payloadFormat: { key: "full_day" },
            // rules: { required: true },
            // defaultValue: 2024,
          },
          {
            id: 1,
            label: "Search By User Email",
            name: "useremail",
            type: "text",
            className: 'formGroup countDiv__column--col4',
            sx: { m: 1, minWidth: 300 },
            payloadFormat: { key: "useremail", param: "$regex", options: "i" },

          },
          // {
          //   id: 2,
          //   label: "Search By Connected Gmail",
          //   name: "connected_gmail",
          //   type: "text",
          //   className: 'formGroup countDiv__column--col4',
          //   sx: { m: 1, minWidth: 300 },
          //   payloadFormat: { key: "connected_gmail", param: "$regex", options: "i" },
          // },
          {
            id: 3,
            label: "Search By Organizer Email",
            name: "organizer_email",
            type: "text",
            className: 'formGroup countDiv__column--col4',
            sx: { m: 1, minWidth: 300 },
            payloadFormat: { key: "organizer_email", param: "$regex", options: "i" },

          }
        ]
      }
    },
  })


  useEffect(() => {
    dispatch(getCalendarUserList({ ...commonReqBody, condition: { limit: 10, skip: 0 } }))
    dispatch(fetAllEventsList(userEvents.reqBody))
    dispatch(conflictingSlotsList(conflictingSlots.reqBody))
    dispatch(userSixMonthsSlotsList(userSixMonthsSlot.reqBody))
    dispatch(fetAllEventDaysList(userEventDays.reqBody))
    dispatch(tempConflictingSlotsList(tempConflictingSlots.current.reqBody))
    dispatch(getEDayArrNotCreatedList(commonReqBody))
    dispatch(getEDaySlotNotCreatedList({ ...commonReqBody, condition: { limit: 20, skip: 0 } }))
    dispatch(getESlotsNotCreatedList({ ...commonReqBody, condition: { limit: 20, skip: 0 } }))
    dispatch(getConflictNotificationList({ "data": true }))
  }, [])

  useEffect(() => {
    if (deletResponse && deletResponse.deletedCount > 0) {
      if (btnClicked === 4) dispatch(setReloadTableFlag({ tableId: "conflictingSlots" }))
      if (btnClicked === 3) dispatch(setReloadTableFlag({ tableId: "userSixMonthsSlot" }))
      if (btnClicked === 2) dispatch(setReloadTableFlag({ tableId: "userEventDays" }))
      if (btnClicked === 5) dispatch(setReloadTableFlag({ tableId: "tempConflictingSlots" }))
      setOpen(true)
      setBtnClicked(null)
      setTimeout(() => setOpen(false), 6000)
    }
  }, [deletResponse])

  useEffect(() => {
    console.log("conflictNotificationList", conflictNotificationList);
  }, [conflictNotificationList])


  return (
    <div className="reactTableWrapper">
      <div className="reactTableWrapper">

        <CalendarUsersList reqBody={{ ...commonReqBody, condition: { limit: 10, skip: 0 } }} />

        <hr />

        <div className="reactTable">
          {allEventsDataLoading ? (
            <Box sx={{ width: "100%", mt: "10px" }}>
              <LinearProgress sx={{ height: "5px" }} />
            </Box>
          ) : (
            ""
          )}
          {allEventsData ? (
            <ListingTable
              tableData={userEvents}
              dataset={allEventsData}
              modifyHeaders={modifyTableHeadersEvents}
            />
          ) : (
            <TableSkeleton count={5} />
          )}
        </div>

        <hr />

        <div className="reactTable">
          <div className='submitbtnsection delete_all_btn_container'>
            <Button disabled={usersSixMonthsSlotsDataLoading} className='form_custom_button' onClick={() => {
              dispatch(deleteAllEventDays())
              setBtnClicked(2)
            }}>Delete All</Button>
          </div>
          {allEventDaysDataLoading ? (
            <Box sx={{ width: "100%", mt: "10px" }}>
              <LinearProgress sx={{ height: "5px" }} />
            </Box>
          ) : (
            ""
          )}
          {allEventDaysData ? (
            <ListingTable
              tableData={userEventDays}
              dataset={allEventDaysData}
              modifyHeaders={modifyTableHeadersUserEventDays}
            />
          ) : (
            <TableSkeleton count={5} />
          )}
        </div>

        <hr />

        <div className="reactTable">
          <div className='submitbtnsection delete_all_btn_container'>
            <Button disabled={usersSixMonthsSlotsDataLoading} className='form_custom_button' onClick={() => {
              dispatch(deleteAllSixMonthsSlots())
              setBtnClicked(3)
            }}>Delete All</Button>
          </div>
          {usersSixMonthsSlotsDataLoading ? (
            <Box sx={{ width: "100%", mt: "10px" }}>
              <LinearProgress sx={{ height: "5px" }} />
            </Box>
          ) : (
            ""
          )}
          {usersSixMonthsSlotsData ? (
            <ListingTable
              tableData={userSixMonthsSlot}
              dataset={usersSixMonthsSlotsData}
              modifyHeaders={modifyTableHeadersUserSixMonthsSlot}
            />
          ) : (
            <TableSkeleton count={5} />
          )}
        </div>

        <hr />

        {conflictNotificationListLoading && <Box sx={{ width: "100%", mt: "10px" }}><LinearProgress sx={{ height: "5px" }} /></Box>}
        {conflictNotificationList && <div className='summery_block'>
          <h2>CONFLICTING SLOTS SUMMERY FROM YESTERDAY</h2>
          <div className='connectedemail'>
            <div>
              <p>Total number of conflict slots were deleted on previous day : {conflictNotificationList?.cSlot_delete_count}</p>
              <p>Slot delete duration avg : {conflictNotificationList?.slot_delete_duration_avg}</p>
              <p>Maximum deletion time : {conflictNotificationList?.max}</p>
              <p>Minimum deletion time : {conflictNotificationList?.min}</p>
              <p>Slot deletion time more than 10 minutes : {conflictNotificationList?.gt10_count} ({conflictNotificationList?.gt10_percentage} %)</p>
              <p>Slot deletion time more than 15 minutes : {conflictNotificationList?.gt15_count} ({conflictNotificationList?.gt15_percentage} %)</p>
              <p>Slot deletion time more than 20 minutes : {conflictNotificationList?.gt20_count} ({conflictNotificationList?.gt20_percentage} %)</p>
              <p>Slot deletion time more than 25 minutes : {conflictNotificationList?.gt25_count} ({conflictNotificationList?.gt25_percentage} %)</p>
              <p>G-Cal Slots created after system event creation (Count / AVG Deletion Gap) : {conflictNotificationList?.g_cal_created_after_sys_events} ({conflictNotificationList?.g_cal_created_after_sys_events_avg_deletion_gap})</p>
              <p>G-Cal Slots created after system event creation minimum deletion time (Type1 / Type2) : {conflictNotificationList?.g_cal_created_after_sys_events_deletion_min_time} ({conflictNotificationList?.g_cal_created_after_sys_events_deletion_min_time_type1}/ {conflictNotificationList?.g_cal_created_after_sys_events_deletion_min_time_type2})</p>
            </div>

            <div>
              <p>Type 1 Conflict Slots (Total / AVG) : {conflictNotificationList?.type1_total} ({conflictNotificationList?.type1_total_avg} %)</p>
              <p>Type 2 Conflict Slots (Total / AVG) : {conflictNotificationList?.type2_total} ({conflictNotificationList?.type2_total_avg} %)</p>
              <p>Type 1 Conflict Slots deletion average time : {conflictNotificationList?.type1_total_avg_deletion_time}</p>
              <p>Type 2 Conflict Slots deletion average time : {conflictNotificationList?.type2_total_avg_deletion_time}</p>
              <p>Type 1 maximum deletion time : {conflictNotificationList?.type1_total_max_deletion_time}</p>
              <p>Type 2 maximum deletion time : {conflictNotificationList?.type2_total_max_deletion_time}</p>
              <p>Type 1 minimum deletion time : {conflictNotificationList?.type1_total_min_deletion_time}</p>
              <p>Type 2 minimum deletion time : {conflictNotificationList?.type2_total_min_deletion_time}</p>
              <p>G-Cal Slots created after system event creation maximum deletion time (Type1 / Type2) : {conflictNotificationList?.g_cal_created_after_sys_events_deletion_max_time} ({conflictNotificationList?.g_cal_created_after_sys_events_deletion_max_time_type1} / {conflictNotificationList?.g_cal_created_after_sys_events_deletion_max_time_type2})</p>
              <p>G-Cal Slots created after system event creation AVG deletion time (Type 1 / Type2) : {conflictNotificationList?.g_cal_created_after_sys_events_deletion_avg_type1} / {conflictNotificationList?.g_cal_created_after_sys_events_deletion_avg_type2}</p>
            </div>
          </div>
        </div>}

        <div className="reactTable">
          <div className='submitbtnsection delete_all_btn_container'>
            <Button disabled={conflictingSlotsDataLoading} className='form_custom_button' onClick={() => {
              dispatch(deleteAllConflictSlots())
              setBtnClicked(4)
            }}>Delete All</Button>
          </div>
          {conflictingSlotsDataLoading && <Box sx={{ width: "100%", mt: "10px" }}><LinearProgress sx={{ height: "5px" }} /></Box>}

          {conflictingSlotsData ?
            <ListingTable tableData={conflictingSlots} dataset={conflictingSlotsData} modifyHeaders={modifyTableHeadersConflictingSlots} />
            : <TableSkeleton count={5} />
          }
        </div>

        <hr />

        <div className="reactTable">
          <div className='submitbtnsection delete_all_btn_container'>
            <Button disabled={tempConflSlotsDataLoading} className='form_custom_button'
              onClick={() => {
                dispatch(deleteAllTempConflictSlots());
                setBtnClicked(5);
              }}>Delete All
            </Button>
          </div>
          {tempConflSlotsDataLoading && <Box sx={{ width: "100%", mt: "10px" }}><LinearProgress sx={{ height: "5px" }} /></Box>}

          {tempConflSlotsData ?
            <ListingTable tableData={tempConflictingSlots.current} dataset={tempConflSlotsData} modifyHeaders={modifyHeadersTempConfSlots} />
            : <TableSkeleton count={5} />
          }
        </div>

        <hr />
        <EventDayArrNotCreatedList reqBody={commonReqBody} />
        <hr />
        <EventDaySlotsNotCreatedList reqBody={{ ...commonReqBody, condition: { limit: 20, skip: 0 } }} />
        <hr />
        <EventSlotsNotCreatedList reqBody={{ ...commonReqBody, condition: { limit: 20, skip: 0 } }} />
        <hr />

      </div>
      <Snackbar open={open} autoHideDuration={6000} onClose={() => setOpen(false)}>
        <Alert onClose={() => setOpen(false)} severity="success" sx={{ width: '100%' }}>
          {deletResponse?.deletedCount} Conflict Slots Deleted Successfully
        </Alert>
      </Snackbar>
    </div>
  )
}

export default EventSummary