import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";


export const fetAllEventsList = createAsyncThunk("calendar/all-events-list-count", async (reqBody) => {
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(reqBody),
  };
  console.log("process.env==>", process.env);
  const response = await fetch(
    process.env.REACT_APP_API_URL + "googleapi/all-events-list-count",
    requestOptions
  );
  const respdata = await response.json();

  console.log("respdata after endpoint fetch==>", respdata);

  return respdata;
});
export const fetAllEventDaysList = createAsyncThunk("calendar/all-eventday-list-count", async (reqBody) => {
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(reqBody),
  };
  console.log("process.env==>", process.env);
  const response = await fetch(
    process.env.REACT_APP_API_URL + "googleapi/all-eventday-list-count",
    requestOptions
  );
  const respdata = await response.json();

  console.log("respdata after endpoint fetch==>", respdata);

  return respdata;
});
export const userSixMonthsSlotsList = createAsyncThunk("calendar/users-six-month-slots", async (reqBody) => {
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(reqBody),
  };
  console.log("process.env==>", process.env);
  const response = await fetch(
    process.env.REACT_APP_API_URL + "googleapi/users-slot-for-six-month-list",
    requestOptions
  );
  const respdata = await response.json();

  console.log("respdata after endpoint fetch==>", respdata);

  return respdata;
});
export const conflictingSlotsList = createAsyncThunk("calendar/conflicting-slots", async (reqBody) => {
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(reqBody),
  };
  console.log("process.env==>", process.env);
  const response = await fetch(
    process.env.REACT_APP_API_URL + "googleapi/conflicting-slot-list",
    requestOptions
  );
  const respdata = await response.json();

  console.log("respdata after endpoint fetch==>", respdata);

  return respdata;
});
export const tempConflictingSlotsList = createAsyncThunk("calendar/temp-conflicting-slots", async (reqBody) => {
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(reqBody),
  };
  console.log("process.env==>", process.env);
  const response = await fetch(
    process.env.REACT_APP_API_URL + "googleapi/temp-conflicting-slot-list",
    requestOptions
  );
  const respdata = await response.json();

  console.log("respdata after endpoint fetch==>", respdata);

  return respdata;
});
export const getEDayArrNotCreatedList = createAsyncThunk("calendar/event-day-arr-list", async (reqBody) => {
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(reqBody),
  };
  console.log("process.env==>", process.env);
  const response = await fetch(
    process.env.REACT_APP_API_URL + "googleapi/eventdayarr-not-created",
    requestOptions
  );
  const respdata = await response.json();

  console.log("respdata after endpoint fetch==>", respdata);

  return respdata;
});
export const getEDaySlotNotCreatedList = createAsyncThunk("calendar/event-day-arr-slots-list", async (reqBody) => {
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(reqBody),
  };
  console.log("process.env==>", process.env);
  const response = await fetch(
    process.env.REACT_APP_API_URL + "googleapi/event-day-slot-not-created",
    requestOptions
  );
  const respdata = await response.json();

  console.log("respdata after endpoint fetch==>", respdata);

  return respdata;
});
export const getESlotsNotCreatedList = createAsyncThunk("calendar/event-slots-list", async (reqBody) => {
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(reqBody),
  };
  console.log("process.env==>", process.env);
  const response = await fetch(
    process.env.REACT_APP_API_URL + "googleapi/event-slot-not-created",
    requestOptions
  );
  const respdata = await response.json();

  console.log("respdata after endpoint fetch==>", respdata);

  return respdata;
});
export const getCalendarUserList = createAsyncThunk("calendar/get-calendar-user-list", async (reqBody) => {
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(reqBody),
  };
  console.log("process.env==>", process.env);
  const response = await fetch(
    process.env.REACT_APP_API_URL + "googleapi/users-google-calendar-list",
    requestOptions
  );
  const respdata = await response.json();

  console.log("respdata after endpoint fetch==>", respdata);

  return respdata;
});
export const getConflictNotificationList = createAsyncThunk("calendar/get-conflict-notification-list", async (reqBody) => {
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(reqBody),
  };
  console.log("process.env==>", process.env);
  const response = await fetch(
    process.env.REACT_APP_API_URL + "googleapi/conflict-notification",
    requestOptions
  );
  const respdata = await response.json();

  console.log("respdata after endpoint fetch==>", respdata);

  return respdata;
});



export const deleteAllEventDays = createAsyncThunk("calendar/delete-all-events-days", async (reqBody) => {
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({ delete: "all" }),
  };
  console.log("process.env==>", process.env);
  const response = await fetch(
    process.env.REACT_APP_API_URL + "googleapi/delete-event-days",
    requestOptions
  );
  const respdata = await response.json();

  console.log("respdata after endpoint fetch==>", respdata);

  return respdata;
});
export const deleteAllSixMonthsSlots = createAsyncThunk("calendar/delete-all-six-months-slots", async (reqBody) => {
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({ delete: "all" }),
  };
  console.log("process.env==>", process.env);
  const response = await fetch(
    process.env.REACT_APP_API_URL + "googleapi/delete-user-six-months-slot",
    requestOptions
  );
  const respdata = await response.json();

  console.log("respdata after endpoint fetch==>", respdata);

  return respdata;
});
export const deleteAllConflictSlots = createAsyncThunk("calendar/delete-all-conflict-slots", async (reqBody) => {
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({ delete: "all" }),
  };
  console.log("process.env==>", process.env);
  const response = await fetch(
    process.env.REACT_APP_API_URL + "googleapi/delete-conflicting-slot",
    requestOptions
  );
  const respdata = await response.json();

  console.log("respdata after endpoint fetch==>", respdata);

  return respdata;
});
export const deleteAllTempConflictSlots = createAsyncThunk("calendar/delete-all-temp-conflict-slots", async (reqBody) => {
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({ delete: "all" }),
  };
  console.log("process.env==>", process.env);
  const response = await fetch(
    process.env.REACT_APP_API_URL + "googleapi/delete-temp-conflicting-slot",
    requestOptions
  );
  const respdata = await response.json();

  console.log("respdata after endpoint fetch==>", respdata);

  return respdata;
});


const eventSummaryReducer = createSlice({
  name: "eventSummaryReducer",
  initialState: {
    conflictingSlotsDataLoading: false,
    conflictingSlotsData: null,
    usersSixMonthsSlotsDataLoading: false,
    usersSixMonthsSlotsData: null,
    allEventsDataLoading: false,
    allEventsData: null,
    allEventDaysDataLoading: false,
    allEventDaysData: null,
    tempConflSlotsDataLoading: false,
    tempConflSlotsData: null,
    eDayArrNotCreatedListLoading: false,
    eDayArrNotCreatedList: null,
    eDaySlotsNotCreatedListLoading: false,
    eDaySlotsNotCreatedList: null,
    eSlotsNotCreatedListLoading: false,
    eSlotsNotCreatedList: null,
    calendarUserListLoading: false,
    calendarUserList: null,
    conflictNotificationListLoading: false,
    conflictNotificationList: null,
    deletResponse: null
  },
  reducers: {
    resetInitState(state, action) {
      state.deletResponse = null
    }

  },
  extraReducers: {
    // For All Events List
    [fetAllEventsList.pending]: (state, action) => {
      state.allEventsDataLoading = true;
    },
    [fetAllEventsList.fulfilled]: (state, action) => {
      state.allEventsDataLoading = false;
      state.allEventsData = action.payload?.results?.res ? action.payload?.results?.res : []
    },
    [fetAllEventsList.rejected]: (state, action) => {
      state.allEventsDataLoading = false;
      state.allEventsData = []
    },

    // For All Event Days List
    [fetAllEventDaysList.pending]: (state, action) => {
      state.allEventDaysDataLoading = true;
    },
    [fetAllEventDaysList.fulfilled]: (state, action) => {
      state.allEventDaysDataLoading = false;
      state.allEventDaysData = action.payload?.results?.res ? action.payload?.results?.res : []
    },
    [fetAllEventDaysList.rejected]: (state, action) => {
      state.allEventDaysDataLoading = false;
      state.allEventDaysData = []
    },

    // For user Six Months Slots List
    [userSixMonthsSlotsList.pending]: (state, action) => {
      state.usersSixMonthsSlotsDataLoading = true;
    },
    [userSixMonthsSlotsList.fulfilled]: (state, action) => {
      state.usersSixMonthsSlotsDataLoading = false;
      state.usersSixMonthsSlotsData = action.payload?.results?.res ? action.payload?.results?.res : []
    },
    [userSixMonthsSlotsList.rejected]: (state, action) => {
      state.usersSixMonthsSlotsDataLoading = false;
      state.usersSixMonthsSlotsData = []
    },

    // For Conflicting Slots List
    [conflictingSlotsList.pending]: (state, action) => {
      state.conflictingSlotsDataLoading = true;
    },
    [conflictingSlotsList.fulfilled]: (state, action) => {
      state.conflictingSlotsDataLoading = false;
      state.conflictingSlotsData = action.payload?.results?.res ? action.payload?.results?.res : []
    },
    [conflictingSlotsList.rejected]: (state, action) => {
      state.conflictingSlotsDataLoading = false;
      state.conflictingSlotsData = []
    },

    // For Temp Conflicting Slots List
    [tempConflictingSlotsList.pending]: (state, action) => {
      state.tempConflSlotsDataLoading = true;
    },
    [tempConflictingSlotsList.fulfilled]: (state, action) => {
      state.tempConflSlotsDataLoading = false;
      state.tempConflSlotsData = action.payload?.results?.res ? action.payload?.results?.res : null
    },
    [tempConflictingSlotsList.rejected]: (state, action) => {
      state.tempConflSlotsDataLoading = false;
      state.tempConflSlotsData = null
    },

    // For Event Day Arr List
    [getEDayArrNotCreatedList.pending]: (state, action) => {
      state.eDayArrNotCreatedListLoading = true;
    },
    [getEDayArrNotCreatedList.fulfilled]: (state, action) => {
      state.eDayArrNotCreatedListLoading = false;
      state.eDayArrNotCreatedList = action.payload?.results?.res ? action.payload?.results?.res : null
    },
    [getEDayArrNotCreatedList.rejected]: (state, action) => {
      state.eDayArrNotCreatedListLoading = false;
      state.eDayArrNotCreatedList = null
    },

    // For Event Day Arr Slots List
    [getEDaySlotNotCreatedList.pending]: (state, action) => {
      state.eDaySlotsNotCreatedListLoading = true;
    },
    [getEDaySlotNotCreatedList.fulfilled]: (state, action) => {
      state.eDaySlotsNotCreatedListLoading = false;
      state.eDaySlotsNotCreatedList = action.payload?.results?.res ? action.payload?.results?.res : null
    },
    [getEDaySlotNotCreatedList.rejected]: (state, action) => {
      state.eDaySlotsNotCreatedListLoading = false;
      state.eDaySlotsNotCreatedList = null
    },

    // For Event Slots List
    [getESlotsNotCreatedList.pending]: (state, action) => {
      state.eSlotsNotCreatedListLoading = true;
    },
    [getESlotsNotCreatedList.fulfilled]: (state, action) => {
      state.eSlotsNotCreatedListLoading = false;
      state.eSlotsNotCreatedList = action.payload?.results?.res ? action.payload?.results?.res : null
    },
    [getESlotsNotCreatedList.rejected]: (state, action) => {
      state.eSlotsNotCreatedListLoading = false;
      state.eSlotsNotCreatedList = null
    },

    // For Calendar User List
    [getCalendarUserList.pending]: (state, action) => {
      state.calendarUserListLoading = true;
    },
    [getCalendarUserList.fulfilled]: (state, action) => {
      state.calendarUserListLoading = false;
      state.calendarUserList = action.payload?.results?.res ? action.payload?.results?.res : null
    },
    [getCalendarUserList.rejected]: (state, action) => {
      state.calendarUserListLoading = false;
      state.calendarUserList = null
    },

    // For Calendar User List
    [getConflictNotificationList.pending]: (state, action) => {
      state.conflictNotificationListLoading = true;
    },
    [getConflictNotificationList.fulfilled]: (state, action) => {
      state.conflictNotificationListLoading = false;
      state.conflictNotificationList = action.payload?.results ? action.payload?.results : null
    },
    [getConflictNotificationList.rejected]: (state, action) => {
      state.conflictNotificationListLoading = false;
      state.conflictNotificationList = null
    },





    // For Deleting All Event Days List
    [deleteAllEventDays.pending]: (state, action) => {
      state.allEventDaysDataLoading = true;
    },
    [deleteAllEventDays.fulfilled]: (state, action) => {
      state.allEventDaysDataLoading = false;
      state.deletResponse = action.payload?.results ? action.payload.results : null
    },
    [deleteAllEventDays.rejected]: (state, action) => {
      state.allEventDaysDataLoading = false;
      state.deletResponse = null
    },

    // For Deleting All Six Months Slots List
    [deleteAllSixMonthsSlots.pending]: (state, action) => {
      state.usersSixMonthsSlotsDataLoading = true;
    },
    [deleteAllSixMonthsSlots.fulfilled]: (state, action) => {
      state.usersSixMonthsSlotsDataLoading = false;
      state.deletResponse = action.payload?.results ? action.payload.results : null
    },
    [deleteAllSixMonthsSlots.rejected]: (state, action) => {
      state.usersSixMonthsSlotsDataLoading = false;
      state.deletResponse = null
    },

    // For Deleting All Conflict Slots List
    [deleteAllConflictSlots.pending]: (state, action) => {
      state.conflictingSlotsDataLoading = true;
    },
    [deleteAllConflictSlots.fulfilled]: (state, action) => {
      state.conflictingSlotsDataLoading = false;
      state.deletResponse = action.payload?.results ? action.payload.results : null
    },
    [deleteAllConflictSlots.rejected]: (state, action) => {
      state.conflictingSlotsDataLoading = false;
      state.deletResponse = null
    },

    // For Deleting All Temp Conflict Slots List
    [deleteAllTempConflictSlots.pending]: (state, action) => {
      state.tempConflSlotsDataLoading = true;
    },
    [deleteAllTempConflictSlots.fulfilled]: (state, action) => {
      state.tempConflSlotsDataLoading = false;
      state.deletResponse = action.payload?.results ? action.payload.results : null
    },
    [deleteAllTempConflictSlots.rejected]: (state, action) => {
      state.tempConflSlotsDataLoading = false;
      state.deletResponse = null
    },
  },
});

export const { resetInitState } = eventSummaryReducer.actions;


export default eventSummaryReducer.reducer;
