import React, { useState } from "react";
import { Button } from "@mui/material";
import ListingPro from "./ListingPro";
import ListingDev from "./ListingDev";

export const GoogleEvents = () => {
  const [state, setState] = useState(1);

  return (
    <>
      <div>
        <div style={{ textAlign: "center" }}>Six Months Google Events</div>
        <div className="buttonsetToolbar">
          <Button
            variant="contained"
            onClick={() => setState(1)}
            style={{ backgroundColor: state === 1 ? "#195f82" : "#1976d2" }}
          >
            Developement
          </Button>
          <Button
            variant="contained"
            onClick={() => setState(0)}
            style={{ backgroundColor: state === 0 ? "#195f82" : "#1976d2" }}
          >
            Production
          </Button>
        </div>
        <div>{state === 0 ? <ListingPro /> : <ListingDev />}</div>
      </div>
    </>
  );
};
