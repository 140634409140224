import React, { useState } from "react";
import { Button } from "@mui/material";
import ManageCronDevListing from "./ManageCronDevListing";
import { ManageCronForm } from "./ManageCronForm";
import CronResponseListing from "./CronResponseListing";


export const ManageCron = () => {


  return (
    <>
      <div>
        <div style={{ textAlign: "center", marginBottom: "25px" }}>
          Manage Cron
        </div>
        <div>
          <div>
            <ManageCronForm />
          </div>
          <div>
            <ManageCronDevListing />
          </div>
          <div>
            <CronResponseListing />
          </div>
        </div>

      </div>
    </>
  );
};
