// import { Box, Button, LinearProgress } from '@mui/material';
import MetaTags from "react-meta-tags";
import React, { useEffect, useState } from 'react';
import "./middleware.css";
// import ListingTable from '../../../../listing/listing';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { fetchMiddlewareData } from "./middlewareReducer";
import { useDispatch } from "react-redux";
import { Stroller } from "@mui/icons-material";
import store from '../../../../store';
import { Cookies, CookiesProvider, useCookies } from "react-cookie"
import { Box, LinearProgress } from "@mui/material";


const MiddleWare = () => {
  const id = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [loaderFlag, setLoaderFlag] = useState(false);


  useEffect(() => {
    console.log(id);
    if (id !== null && id !== 'undefined' && id._id != null) {
      dispatch(fetchMiddlewareData(id))
    }
  }, []);

  store.subscribe(() => {
    const storeData = store.getState();
    setLoaderFlag(storeData.middlewareDatareducer.loading);
    managenav(setMiddlewareDataCookie, storeData, navigate);
    console.log("middleware from store ", storeData.middlewareDatareducer);

  });

    const [middlewaredata, setMiddlewareDataCookie] = useCookies(["middlewaredata"]);
    //managenav( setMiddlewareDataCookie);

  return (
    <>
      {loaderFlag == true && (
        <Box sx={{ width: "100%", mt: "10px" }}>
          <LinearProgress sx={{ height: "5px" }} />
        </Box>
      )}
      <div className="container"> 
         <h3 className="heading">Redirecing to new page.....</h3>
      </div>
    </>
  )
}

function managenav(setMiddlewareDataCookie, storeData, navigate){
    console.log("storeData++", storeData);
  
    if ( storeData.middlewareDatareducer.midlleWareData.length !== 0 ) {
     
        setMiddlewareDataCookie("middleware", storeData.middlewareDatareducer.midlleWareData[0], { path: "/" });

        const cookies = new Cookies();
        console.log("cookies in layout==>", cookies.getAll());

        const getcookies = cookies.getAll();
        console.log("getcookies from middleware==>", getcookies.middleware);

        if (getcookies.middleware.length !== {} && getcookies.middleware.is_pece != null && getcookies.middleware.is_pece != 'undefined') {
            console.warn("first console")

            if (getcookies.middleware.is_pece== 1){
                navigate("/pece-discovery", { replace: true }); // navite to PECE url   
                console.warn("pece console")   
            }
            else{
                navigate("/bio-energetics-discovery", { replace: true }); // navite to BIO url
                console.warn("BIO console")   

            }
            console.log('getcookies==>', getcookies)
        }
    }

  }

export default MiddleWare