import { Button, Menu, MenuItem } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import CalendarStatus from '../../Listings/CalendarStatus'
import PeceDev from './PECE/PeceDev'
import PeceLive from './PECE/PeceLive'
// import PeceDev from './Projects/Pece_Dev/PeceDev'
// import PeceLive from './Projects/Pece_Live/PeceLive'

function DeveloperInfo() {
  const navigate = useNavigate()
  const { pathname } = useLocation();

  const isDev = useSelector((state) => state.loginUserKey.userInfo.isDev)


  const [anchorEl, setAnchorEl] = useState(null)
  const openCalendarInfo = Boolean(anchorEl);

  const handleCalendarInfoClose = (path) => {
    setAnchorEl(null);
    if (path) navigate(`/${path}`)
  }
  return (
    <div>
      <div className="buttonsetToolbar">
        <Button variant="contained">
          <Link to="/calendar-management-createEvent">
            Create New Availability
          </Link>
        </Button>
        <Button variant="contained">
          <Link to="/calendar-management-event-listing">
            Availability List
          </Link>
        </Button>
        <Button
          href="https://d0fw1rgejc.execute-api.us-east-2.amazonaws.com/production/sync-calendar"
          variant="contained"
        >
          Update Google Account
        </Button>
        <Button variant="contained">
          <Link to="/calendar-management-booked-slots">
            Booked Slot
          </Link>
        </Button>

        {isDev === 1 &&
          <>
            <Button variant="contained">
              <Link to="/events-slot-summery">
                Event Summary
              </Link>
            </Button>

            <Button variant="contained" id="button">
              <Link to="/calendar-management-manageUser">
                Manage User
              </Link>
            </Button>
            <Button variant="contained" onClick={(event) => setAnchorEl(event.currentTarget)}>
              Developer Info
            </Button>
            <Menu
              id="basic-menu"
              anchorEl={anchorEl}
              open={openCalendarInfo}
              onClose={() => handleCalendarInfoClose()}
              MenuListProps={{
                'aria-labelledby': 'basic-button',
              }}
            >
              <MenuItem onClick={() => handleCalendarInfoClose('developer-info/pece-dev')}>Pece Developement</MenuItem>
              <MenuItem onClick={() => handleCalendarInfoClose('developer-info/pece-live')}>Pece Live</MenuItem>
              <MenuItem onClick={() => handleCalendarInfoClose('developer-info/calendar-management')}>Calendar Management</MenuItem>
              <MenuItem onClick={() => handleCalendarInfoClose('developer-info/aspire-dev')}>Aspire Dev</MenuItem>
              <MenuItem onClick={() => handleCalendarInfoClose('developer-info/aspire-prod')}>Aspire Prod</MenuItem>
            </Menu>
          </>
        }
      </div>


      {/* For PECE */}
      {pathname === '/developer-info/pece-dev' && <PeceDev />}
      {pathname === '/developer-info/pece-live' && <PeceLive />}

      {/* {pathname === '/developer-info/pece-dev' && <CalendarStatus env={process.env.REACT_APP_PECE_DEV_URL} endPoint="googleapi/calendar-status-list" autoEnpointBase="googleapi/" />} */}
      {/* {pathname === '/developer-info/pece-live' && <CalendarStatus env={process.env.REACT_APP_PECE_PROD_URL} endPoint="googleapi/calendar-status-list" autoEnpointBase="googleapi/" />} */}

      {/* For Aspire */}
      {pathname === '/developer-info/aspire-dev' && <CalendarStatus env={process.env.REACT_APP_ASPIRE_DEV_URL} endPoint="calendar-api/calendar-status-list" autoEnpointBase="calendar-api/" />}
      {pathname === '/developer-info/aspire-prod' && <CalendarStatus env={process.env.REACT_APP_ASPIRE_PROD_URL} endPoint="calendar-api/calendar-status-list" autoEnpointBase="calendar-api/" />}

    </div>
  )
}

export default DeveloperInfo