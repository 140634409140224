import React from 'react'
import ListingTable from '../../../../listing/listing'

export const Listevent = ({ responseData }) => {

    console.log("last console");
    const tableData = {
        tableId: 'calendarAvailabilityTable',
        tableTitle: 'Calendar Availability Listing',
        showReload: true,
        showFilter: true,
        api: {
            url: process.env.REACT_APP_API_URL,
            endPoint: 'api/availability-list',
        },
        reqBody: {
            source: '',
            condition: {
                limit: 5,
                skip: 0,
            },
            sort: {
                type: 'desc',
                field: '_id',
            },
            searchcondition: {},
            token:
                'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJmb28iOiJiYXIiLCJleHAiOjE2NDg1NDAyNTgsImlhdCI6MTY0ODQ1Mzg1OH0.QGs0yduTqQSjHj1mEmmuu4Ivb1IO5sgcnTCIH_DOu1E',
            secret: 'JwX.(1QHj',
        },
        rowsPerPageOptions: [5, 20, 30],
        sortFields: ['event_title', 'description'],
        // paginationType: "stackPagination",
        buttons: [
            {
                type: 'delete',
                label: 'Delete',
                name: 'delete',
            },
            {
                type: 'edit',
                label: 'Edit',
                name: 'edit',
            },
            {
                type: 'view',
                label: 'View',
                name: 'view',
            },
        ],
    }
    var modifyTableHeaders = [
        { val: 'event_title', name: 'Event Title' },
        { val: 'description', name: ' Decsription' },
        { val: 'schedule', name: 'Schedule' },
        { val: 'event_type', name: 'Event Type' },
        { val: 'status', name: 'Status' },
        { val: 'created_at', name: 'Created by' },
        { val: 'product_id', name: 'Product' },
        { val: 'phone', name: 'phone' }
    ]

    return (
        <div>
            <ListingTable
                tableData={tableData}
                dataset={responseData}
                modifyHeaders={modifyTableHeaders}
            />
        </div>
    )
}
