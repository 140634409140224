import React, { useState } from "react";
import "./ForgetPassword.css";
import { useForm } from "react-hook-form";
import { Link } from "react-router-dom";
import { MetaTags } from "react-meta-tags";
// import { Box } from "@mui/system";
import { Alert, Button, LinearProgress, Snackbar } from "@mui/material";
import store from "../../../../store";
import { forgetpassword, getipInfo } from "./ForgetPassword_reduces";
import { useDispatch } from "react-redux";
import { Box } from "@mui/system";

// let storedata = {};
store.dispatch(getipInfo());

const ForgetPassword = () => {
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState(null);
  const dispatch = useDispatch();
  // const [loading, setLoading] = useState(false);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onSubmit = (data) => {
    console.log("Logged User data==>", data);
    let reqBody = {
      source: "users",
      secret: "AZ|lepL`",

      data: {
        email: data.email,
        link: `${process.env.REACT_APP_API_EXTERNAL_URL}forgot-password/`,
      }, /////////////////////////////////
    };
    dispatch(forgetpassword(reqBody));
  };

  store.subscribe(() => {
    const storedata = store.getState();
    if (storedata.forgetPasswordUserKey?.loading === false) {

      setOpen(storedata.forgetPasswordUserKey?.messageflag);
      setMessage(storedata.forgetPasswordUserKey?.message);
    }
    setLoading(storedata.forgetPasswordUserKey?.loading);
  });

  return (
    <>
      <div>
        <div className="snackbarcls">
          <Snackbar
            open={open}
            onClose={() => {
              setOpen(false);
              setMessage(null);
            }}
            anchorOrigin={{ vertical: "top", horizontal: "center" }}
            autoHideDuration={3000}
          >

            <Alert>{message}</Alert>
          </Snackbar>
        </div>

        <div className="mainContentWrapper login-form">
          {/* /////////////////////////////// LOADER ///////////////////////// */}

          <div className="loginFormdiv forgetBlock">
            {/* Login Form Start Here */}
            <div className="loginFormTopBlock">
              {/* Logo Section */}
              <h1 className="logoText">Welcome To Calendar Management</h1>
              <h1 className="logoText"> Calendar Management</h1>

              {/* <h3 className="logoText"></h3> */}
              {/* Welcome Text Section */}
            </div>
            <div className="loginFormBlock">
              {/* Form Section Starts */}

              <h1 className="logoFormText">Forgot Password</h1>
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className="countDiv">
                  <div className="countDiv__column--col12 loginFormInputBox">
                    <label>Email</label>
                    <input
                      className="loginFormTextInput"
                      {...register("email", {
                        required: true,
                        pattern: {
                          value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                        },
                      })}
                    />
                    {errors.email && (
                      <p className="error">Invalid email address</p>
                    )}
                  </div>
                  {loading && (
                    <Box sx={{ width: "100%" }}>
                      <LinearProgress />
                    </Box>
                  )}
                  <Link to="/" className="linkText">
                    Back to Login
                  </Link>
                </div>

                {/* /////////////////////////////// LOADER ///////////////////////// */}

                <div className="buttonLists">
                  <Button className="loginButton" type="submit">
                    Submit
                  </Button>
                </div>
              </form>
              {/* Form Section Ends */}
            </div>
            {/* Login Form Ends Here */}
          </div>
        </div>
      </div>
    </>
  );
};
export default ForgetPassword;
