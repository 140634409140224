import React, { useEffect, useRef, useState } from "react";
import ListingTable from "../../../../listing/listing";
import store from "../../../../store";
import { ModalBox } from "../../../../listing/ModalBox";
import { Modal } from "../../../../listing/Modal";
import { Cookies } from "react-cookie";
import { Button } from "@mui/material";
import { useDispatch } from "react-redux";
import {
  clearCustomButtonClicked,
  resetReloadTableFlag,
  resetRowActionBtnClicked,
  setReloadTableFlag,
  setTableData,
} from "../../../../listing/listReducer";
import { useSelector } from "react-redux";
import EventsTableDev from "./EventsTableDev";
import EventsTableProd from "./EventsTableProd";

export const EventSummary = () => {
  const [state, setState] = useState(1);

  return (
    <>
      <div style={{ textAlign: "center" }}>Event Summary</div>
      <div className="buttonsetToolbar">
        <Button
          variant="contained"
          onClick={() => setState(1)}
          style={{ backgroundColor: state === 1 ? "#195f82" : "#1976d2" }}
        >
          Developement
        </Button>
        <Button
          variant="contained"
          onClick={() => setState(0)}
          style={{ backgroundColor: state === 0 ? "#195f82" : "#1976d2" }}
        >
          Production
        </Button>
      </div>
      <div className="reactTableWrapper">
        <div className="reactTable">
          {state === 1 ? <EventsTableDev /> : <EventsTableProd />}

          {/* {norecordsFlag == true && <p>No Records Found</p>} */}
        </div>
      </div>

      {/* <div style={{textAlign:"center"}}>
        <Button variant="contained" style={{marginBottom:"25px"}} onClick={() => setShow(true)}>Modal</Button>
      </div> */}
    </>
  );
};
