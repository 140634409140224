import { Button, IconButton, TextField } from '@mui/material';
import React from 'react'
import { Controller, useForm } from 'react-hook-form';
import { AutoCompleteSearch } from './components/AutoCompleteSearch';
import { DateSearch } from './components/DateSearch';
import { SelectSearch } from './components/SelectSearch';
import { TextSearch } from './components/TextSearch';
import { useDispatch } from 'react-redux';
import { fetchTableCount, fetchTableList } from '../listing/listReducer';
import CloseIcon from '@mui/icons-material/Close';
import { cloneDeep } from 'lodash';


export const SearchBar = ({ tableData, searchBarData, tableId, handleCloseDrawer }) => {
  const dispatch = useDispatch();
  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
    setValue,
    watch,
    getValues,
    clearErrors,
    setError
  } = useForm();

  ///////////////////////////////////// Form Data Check ////////////////////////////////
  React.useEffect(() => {
    const subscription = watch((value, { name, type }) => {
      console.log(
        "value",
        value,
        "name",
        name,
        "type",
        type,
      )
      console.log("errors", errors)
    })
    return () => subscription.unsubscribe()
  }, [watch])

  const onSubmit = (data) => {
    console.log(data);
    let searchcondition = {};
    for (let key in data) {
      if (data[key] !== undefined) {
        searchcondition[key] = data[key];
      }
    }
    searchBarData.api.reqBody.searchcondition = searchcondition;
    tableData.reqBody.searchcondition = searchcondition;
    dispatch(fetchTableList({ BASE_URL: searchBarData.api.url, endPoint: searchBarData.api.endPoint, reqBody: searchBarData.api.reqBody, restResponseAttachment: { tableId: tableId } }))
    if (searchBarData.api.tableCountEndpoint) {
      dispatch(fetchTableCount({ BASE_URL: searchBarData.api.url, endPoint: searchBarData.api.tableCountEndpoint, reqBody: { ...cloneDeep(searchBarData.api.reqBody), count: true }, restResponseAttachment: { tableId: tableId } }));
    }
    handleCloseDrawer();
  }

  return (
    <>
      <div className='searchBarHead'>
        <h1 className='searchBarHeading'>{searchBarData.heading}</h1>
        <span className='searchBarCloseIcon'>
          <IconButton onClick={() => handleCloseDrawer()}><CloseIcon /></IconButton>
        </span>
      </div>
      <form className='searchForm' onSubmit={handleSubmit(onSubmit)}>
        {Object.keys(searchBarData.searchSettings).map(searchType => {
          const mappedFields = searchBarData.searchSettings[searchType].map(eachField => {
            switch (searchType) {
              case "textsearch":
                return <Controller
                  name={eachField.field}
                  key={eachField.id}
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <TextSearch fielddata={eachField} setValue={setValue} getValues={getValues} />
                  )}
                />
              case "selectsearch":
                return <Controller
                  name={eachField.field}
                  key={eachField.id}
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <SelectSearch fielddata={eachField} setValue={setValue} getValues={getValues} />
                  )}
                />
              case "datesearch":
                return <Controller
                  name={eachField.field}
                  key={eachField.id}
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <DateSearch fielddata={eachField} setValue={setValue} getValues={getValues} />
                  )}
                />
              case "autoCompleteSearch":
                return <Controller
                  name={eachField.field}
                  key={eachField.id}
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <AutoCompleteSearch fielddata={eachField} setValue={setValue} getValues={getValues} />
                  )}
                />
              default:
                return null;
            }
          })
          return mappedFields;
        })}
        <Button className='searchBtn' type='submit' variant='contained'>Search</Button>
      </form>
    </>
  )
}
