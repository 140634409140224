import React from 'react';
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { Cookies } from "react-cookie";
import { Link } from "react-router-dom";

import "./BackendHeader.css";
import { logOut } from '../../Backend/Pages/Login/Login_reducer';

const BackendHeader = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  
  const [anchorEl, setAnchorEl] = React.useState(null);
  const handleClose = () => {
    setAnchorEl(null);

  };

  return (
    <div className="pageWrapper">
      <Grid container spacing={0} className="topMenu">
        <Grid item xs={12} md={3} className="topLogo">
          <div className="loginFormLogo">
            <h1>Calendar Management</h1>
          </div>
        </Grid>
        <Grid item xs={12} md={7} id="topMenuLists" className="topMenuLists">
          <div className="topMenuListsWrapper">
            {/* <Button><Link to='/calendar-management-available-slots'>Calendar</Link></Button>
            <Button>Products</Button> */}
          </div>
        </Grid>
        <Grid item xs={12} md={2} className="topRightMenu">
          <Button className="logOut" onClick={() => {
            dispatch(logOut())
            const cookies = new Cookies();
            cookies.remove("userInfo", { path: '/' });
            cookies.remove("secret", { path: '/' });
            cookies.remove("token", { path: '/' });
            cookies.remove("connected_gmail", { path: '/' });
            cookies.remove("google_calendar_connect_time", { path: '/' });
            cookies.remove("lastLoginTime", { path: '/' });
            handleClose()
            navigate('/', { replace: true })
            window.location.reload();

          }}>Log Out</Button>
        </Grid>
      </Grid>
    </div>
  )
}
export default BackendHeader;