import { Box, LinearProgress } from '@mui/material';
import React, { useEffect, useRef } from 'react'
import { useSelector } from 'react-redux';
import ListingTable from '../../../listing/listing';
import { TableSkeleton } from '../../Common/TableSkeleton';

const CalendarUsersList = ({ reqBody }) => {
    const calendarUserListLoading = useSelector(state => state?.eventSummaryReducer?.calendarUserListLoading ? state.eventSummaryReducer.calendarUserListLoading : null);
    const calendarUserList = useSelector(state => state?.eventSummaryReducer?.calendarUserList ? state.eventSummaryReducer.calendarUserList : null);

    // Event Day Arr Table
    const modifyHeadersCalendarUser = [
        { val: 'user_name', name: 'User Name' },
        { val: 'email', name: 'User Email' },
        { val: 'google_calendar_synced_format', name: 'G-Cal Sync Status' },
        { val: 'slot_status', name: 'Slot Status' },
        { val: 'developer_account', name: 'Account Type' },
        { val: 'auto_test_account', name: 'Auto Event Creation Account' },
        { val: 'createdon_datetime', name: 'Created On', type: "datetime", format: "DD/MM/YYYY hh:mm A" },
        // { val: 'updated_at', name: 'Updated On', type: "datetime", format: "DD/MM/YYYY hh:mm A" },
    ]
    const calendarUser = useRef({
        tableId: 'calendarUser',
        tableTitle: 'Calendar User List',
        showReload: true,
        showFilter: true,
        api: {
            url: process.env.REACT_APP_API_URL,
            deleteUrl: process.env.REACT_APP_BASE_URL,
            endPoint: 'googleapi/users-google-calendar-list',
            tableCountEndpoint: "googleapi/users-google-calendar-list",
            // deleteSingleUserEndpoint: "googleapi/delete-temp-conflicting-slot",
        },
        reqBody: reqBody,
        deleteSingleUserReqBody: {
            "token": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJmb28iOiJiYXIiLCJleHAiOjE2NTM3MTM5ODEsImlhdCI6MTY1MzYyNzU4MX0.QT_QnUUm_0niY3UvVIIZH4baUUKs5I4ZAORzuqRWwWE"
        },
        rowsPerPageOptions: [5, 10, 20, 30, 50, 100],
        sortFields: [],
        enableRowActionLoader: true,
        // paginationType: "stackPagination",
        customBtnHead: [],
        buttons: [],
        searchData: {
            heading: "Search Calendar User",
            type: "drawer",
            // condition_type: "$or",
            formData: {
                id: 'searchForm',
                // formWrapperClass: "formWrapperClass",// Can be undefined default will be formCls
                // formInlineCLass: "searchForm", // Can be undefined default will be formInline
                formButtonClass: "submitbtnsection",
                // submitBtnName: "Search",
                // submitBtnEndIcon: "search",
                // resetBtnName: "Reset",
                // resetBtnEndIcon: "replay",
                resetForm: true,
                formAdditionalSubmissionData: {},
                fields: [
                    {
                        id: 0,
                        label: "Search By User Email",
                        name: "email",
                        type: "text",
                        className: 'formGroup countDiv__column--col4',
                        sx: { m: 1, minWidth: 300 },
                        payloadFormat: { key: "email", param: "$regex", options: "i" },

                    },
                    {
                        id: 1,
                        label: "Search By Connected Gmail",
                        name: "connected_gmail",
                        type: "text",
                        className: 'formGroup countDiv__column--col4',
                        sx: { m: 1, minWidth: 300 },
                        payloadFormat: { key: "connected_gmail", param: "$regex", options: "i" },

                    },
                ]
            }
        },
    })


    return (
        <div className="reactTable">
            {calendarUserListLoading && <Box sx={{ width: "100%", mt: "10px" }}><LinearProgress sx={{ height: "5px" }} /></Box>}
            {calendarUserList ? <ListingTable tableData={calendarUser.current} dataset={calendarUserList} modifyHeaders={modifyHeadersCalendarUser} /> : <TableSkeleton count={5} />}
        </div>
    )
}

export default CalendarUsersList