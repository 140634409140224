import React, { useEffect, useRef, useState } from "react";
import ListingTable from "../../../../listing/listing";
import store from "../../../../store";
import { Cookies } from "react-cookie";
import { Button } from "@mui/material";
import { useDispatch } from "react-redux";
import {
  clearCustomButtonClicked,
  resetReloadTableFlag,
  resetRowActionBtnClicked,
  setReloadTableFlag,
  setTableData,
} from "../../../../listing/listReducer";
import SlotListingDev from "./SlotListingDev";
import SLotListingPro from "./SLotListingPro";

export const ConflictingSlot = () => {
  const [state, setState] = useState(1);

  return (
    <>
      <div>
        <div style={{ textAlign: "center" }}>Conflicting Slot List</div>
        <div className="buttonsetToolbar">
          <Button
            variant="contained"
            onClick={() => setState(1)}
            style={{ backgroundColor: state === 1 ? "#195f82" : "#1976d2" }}
          >
            Developement
          </Button>
          <Button
            variant="contained"
            onClick={() => setState(0)}
            style={{ backgroundColor: state === 0 ? "#195f82" : "#1976d2" }}
          >
            Production
          </Button>
        </div>
        <div>{state === 1 ? <SlotListingDev /> : <SLotListingPro />}</div>
      </div>
    </>
  );
};
