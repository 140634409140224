import { useEffect, useState } from "react";

export const RedundantClicksList = () => {

    const [listCount, setListCount] = useState(0);

    useEffect(() => {
        getdata()
    })


    const getdata = async () => {
        let reqBody = {}
        const requestOptions = {
            method: "POST",
            headers: { "Content-type": "application/json" },
            body: JSON.stringify(reqBody),
        };
        const response = await fetch(process.env.REACT_APP_PECE_DEV_URL + "calendarapi/redundant-click-count", requestOptions);
        const respdata = await response.json();
        console.log("Redundant Clicks List ", respdata);
        if (respdata && respdata.count) {
            setListCount(respdata.count)
        }
    }
    return (
        <div>Redundant Clicks List Count : {listCount}</div>
    )
}