import { Snackbar } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Link, useParams } from "react-router-dom";
import { resetForm } from "../../../../form/formReducer";
import store from "../../../../store";
import { Form } from "../../../../form/Form";
import { MetaTags } from "react-meta-tags";

export const ForgotPasswordPage = () => {
  let userId = useParams();
  let token = useParams();
  console.log("userId", userId, token);
  const [open, setOpen] = useState(false);
  const [formSubmissionState, setFormSubmissionState] = useState(0);
  const dispatch = useDispatch();

  useEffect(() => {
    /////////////////// 1 for pending form submission //////////////////

    //////////////////// 2 for successful form submission //////////////////
    if (formSubmissionState === 2) {
      setOpen(true);
      dispatch(resetForm({ formId: "forgotPasswordForm" }));
    }
  }, [formSubmissionState]);

  store.subscribe(() => {
    const storeData = store.getState();
    //////////////////// Listener For form submission ///////////////////////
    setFormSubmissionState(
      storeData.formSlice.formSubmissionState.forgotPasswordForm
    );
  });

  const dataform = {
    id: "forgotPasswordForm",
    api_url: process.env.REACT_APP_API_URL,
    endPoint: "api/updatepassword",
    endPointBodyWrapper: "data",
    submitBtnName: "UPDATE PASSWORD",
    formButtonClass: "submitbuttonmyacc",

    formAdditionalSubmissionData: {
      _id: userId !== undefined ? userId._id : undefined,
      token: token !== undefined ? token.token : undefined,
    },

    fields: [
      ///////////////////////////////// Password /////////////////////////////////
      {
        id: 1,
        // heading: "New Password",
        label: "New Password",
        name: "password",
        className: "formGroup password inputcol-3",
        style:"width: 100%",    
        type: "password",
        // defaultValue: (typeof editdataforform.password !== undefined) ? editdataforform.password : undefined,

        rules: { required: true },
        errorMessage: {
          required: "This Field is Required",
        },
      },
      {
        id: 2,
        // heading: "Confirm New Password",
        label: "Confirm New Password",
        name: "confirmPassword",
        className: "formGroup confirmPassword inputcol-3",
        style:"width: 100%",
        type: "password",
        // defaultValue: (typeof editdataforform.confirmPassword !== undefined) ? editdataforform.confirmPassword : undefined,

        rules: { required: true },
        errorMessage: {
          required: "This Field is Required",
          errorMessageConfirmPassword: "Password does not match", ////////////////////// Include this for confirm password field //////////
        },
      },
    ],
  };
  return (
    <>
      <div className="mainContentWrapper login-form">
        <div className="loginFormdiv forgetBlock">
          {/* Login From Start Here */}
          <div className="loginFormTopBlock">
            {/* Logo Section */}
            <h1 className="logoText">Welcome To</h1>
            <h3 className="logoText">Calendar Management</h3>
            {/* Welcome Text Section */}
          </div>
          <div className="loginFormBlock">
            {/* Form Section Starts */}
            <h1 className="logoFormText">Create New Password</h1>
            <Form formData={dataform} />

            <Link to="/" className="linkText">
              Back to Login
            </Link>
            {/* Form Section Ends */}
          </div>
          {/* Login From Ends Here */}

          <Snackbar
            open={open}
            onClose={() => setOpen(false)}
            autoHideDuration={1000}
            message="Password Changed Successfully"
          />
        </div>
      </div>
    </>
  );
};
