import { Autocomplete, CircularProgress, FormControl, InputLabel, TextField } from '@mui/material';
import React, { useCallback, useEffect, useState } from 'react';
import debounce from 'lodash.debounce';
import { useDispatch } from 'react-redux';
import store from '../../store';
import { fetchAutocompleteSearchList } from '../searchReducer';



export const AutoCompleteSearch = ({ fielddata, setValue, getValues }) => {
  const dispatch = useDispatch();

  const [query, setQuery] = useState(null);
  const [options, setOptions] = useState([]);
  const [loading, setLoading] = useState(false);

  //////////////////////////////////// LOADASH DEBOUNCE ////////////////////////////////////////////////////
  const debouncedFetchData = useCallback(debounce((query) => {
    dispatch(fetchAutocompleteSearchList({ BASE_URL: fielddata.serverSearchData.base_url, endPoint: fielddata.serverSearchData.endpoint, reqBody: fielddata.serverSearchData.reqBody, restBodyAttachment: { query: query }, restResponseAttachment: { autoCompleteId: fielddata.field } }));
  }, 1000), []);

  //////////////////////////////////////////////// Checking Value ///////////////////////////////////////////
  useEffect(() => {
    if (query !== null && query !== undefined && query !== "") {
      debouncedFetchData(query);
    }
  }, [query]);


  /////////////////////////////////////////////// STORE SUBSCRIPTION FOR REAL TIME SEARCH //////////////////////
  store.subscribe(() => {
    const storeData = store.getState();
    if (storeData.searchSlice.loading[fielddata.field] !== undefined) {
      setLoading(storeData.searchSlice.loading[fielddata.field]);
    }
    if (storeData.searchSlice.autoCompleteList[fielddata.field] !== undefined && storeData.formSlice.autoCompleteList[fielddata.field] !== null) {
      setOptions(storeData.searchSlice.autoCompleteList[fielddata.field]);
    }
  })


  return (
    <div className={fielddata.field}>
      {/* HEADING */}
      {/* {fielddata.heading && <InputLabel ><div dangerouslySetInnerHTML={{ __html: fielddata.heading }}></div></InputLabel>} */}
      {/* FIELD */}
      <FormControl style={{ width: '100%', margin: "10px" }}>
        <Autocomplete
          label={fielddata.label}
          multiple={fielddata.multiple}
          disablePortal
          // value={getValues(fielddata.name) ? getValues(fielddata.name) : ""}
          onChange={(e, newValue) => {
            console.log("newValue", newValue, e.target.value);
            ///////////////// Value set for single selection //////////////
            if (!fielddata.multiple && newValue !== null) setValue(fielddata.field, newValue.key);
            ///////////////// Value set for multiple selection //////////////
            if (fielddata.multiple && newValue !== null) {
              const tempArray = newValue.map(val => val.key);
              setValue(fielddata.field, tempArray);
            }
          }}
          options={options}
          renderInput={(params) => {
            return <TextField
              {...params}
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <React.Fragment>
                    {/* LOADER ON FETCH OPERATION */}
                    {loading ? <CircularProgress color="inherit" size={20} /> : null}
                    {params.InputProps.endAdornment}
                  </React.Fragment>
                ),
              }}
              onChange={(e) => {
                setQuery(e.target.value);
              }} label={fielddata.label} />
          }
          }
        />
      </FormControl>
    </div>
  )
}
