import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

export const getCronList = createAsyncThunk('getCronList', async (reqBody) => {
    const reqOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(reqBody),
    }

    const res = await fetch(`${process.env.REACT_APP_API_URL}googleapi/all-crons-list-count`, reqOptions);
    const respdata = await res.json();
    // console.log("respdata after endpoint fetch==>", res, respdata);
    return respdata;
});

const CronSlice = createSlice({
    name: "CronSlice",
    initialState: {
        loading: false,
        message: null,
        cronList: null
    },
    reducers: {},
    extraReducers: {
        // <------------ Cron List Section ------------>
        [getCronList.pending]: (state, action) => {
            state.loading = true
        },
        [getCronList.fulfilled]: (state, action) => {
            state.cronList = action?.payload?.results?.res;
            state.loading = false;
            state.message = action?.payload?.results?.message ? action?.payload?.results?.message : "Data Loaded Successfully !!"
        },
        [getCronList.rejected]: (state, action) => {
            console.log('action', action);
            state.loading = false;
            state.message = action?.payload?.results?.message ? action?.payload?.results?.message : "Somthing Went Wrong, please try later !!"
        }
    }
});

export const { } = CronSlice.actions;
export default CronSlice.reducer;