import React, { useState } from 'react';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import { FormControl } from '@mui/material';
import CloseIcon from "@mui/icons-material/Close";



export const Modal = props => {

  const [show, setShow] = useState(false);

  if (!props.show) {
    return null
  }

  return (
    <div className='modal' onClick={props.onClose}>
      <div className='modal-content' onClick={e => e.stopPropagation()}>
        <div className='modal-header'>
          <h4 className='modal-title'>Details</h4>
        </div>
        <div className='modal-body'>
          <p><span style={{ color: "skyblue" }}>Event: </span>{props.modalData.event_title}</p>
          <p><span style={{ color: "skyblue" }}>Description: </span>{props.modalData.description}</p>
          <p><span style={{ color: "skyblue" }}>User name: </span>{props.modalData.username}</p>
          <p><span style={{ color: "skyblue" }}>User email: </span>{props.modalData.useremail}</p>
          <p><span style={{ color: "skyblue" }}>Start date: </span>{props.modalData.start_date}</p>
          <p><span style={{ color: "skyblue" }}>End date: </span>{props.modalData.end_date}</p>
          <p><span style={{ color: "skyblue" }}>Start time: </span>{props.modalData.start_time}</p>
          <p><span style={{ color: "skyblue" }}>End time: </span>{props.modalData.end_time}</p>
          <p><span style={{ color: "skyblue" }}>Time Span: </span>{props.modalData.timespan} minutes</p>
          <p style={{ backgroundColor: '#89abc1' }}>Day-wise Slots</p>

        </div>
        <div className='modal_slots'>
          {props.modalData.perdayslot.map((x) =>
          (
            <>
              <p>Date:  {x.startdate}</p>
              <div style={{ display: "flex", flexDirection: 'row', flexWrap: 'nowrap', gap: "5px", borderBottom: '2px solid grey' }}>
                <p>Slots</p>
                {x.slots?.map((y, i) => <>{y.length > 0 ? <p> {i + 1} : {y}</p> : <p>No slots</p>}</>)}
                {/* <FormControl fullWidth>
                            <InputLabel id="demo-simple-select-label">{x.startdate}</InputLabel>
                            <Select
                                sx={{ width: 200, height: 30 }}
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={x.startdate}
                                label={x.startdate}
                            >
                                <MenuItem >{x.slots}</MenuItem>                                
                            </Select>
                        </FormControl> */}
              </div>
            </>
          )
          )}
        </div>
        <div className='modal-footer'>
          <button className='modal-button' onClick={props.onClose}>×</button>
        </div>
      </div>
    </div>
  )
}




