import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";


export const getEventSlotsNotCreated = createAsyncThunk("getEventSlotsNotCreated/list-count", async ({ body = {}, path = "", baseUrl = "" }) => {

  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(body)
  };
  const response = await fetch(baseUrl + path, requestOptions);
  const respdata = await response.json();
  return respdata;
});

export const getEventDayArrNotCreated = createAsyncThunk("getEventDayArrNotCreated/list-count", async ({ body = {}, path = "", baseUrl = "" }) => {

  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(body)
  };
  const response = await fetch(baseUrl + path, requestOptions);
  const respdata = await response.json();
  return respdata;
});

export const getEventDaySlotsEmpty = createAsyncThunk("getEventDaySlotsEmpty/list-count", async ({ body = {}, path = "", baseUrl = "" }) => {

  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(body)
  };
  const response = await fetch(baseUrl + path, requestOptions);
  const respdata = await response.json();
  return respdata;
});

export const getCalendarStatusList = createAsyncThunk("calendar/get-calendar-status-list", async (data) => {
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(data?.reqBody),
  };
  console.log("process.env==>", process.env);
  const response = await fetch(
    data?.env + data?.endPoint,
    requestOptions
  );
  const respdata = await response.json();

  console.log("respdata after endpoint fetch==>", respdata);

  return respdata;
});

export const getConflictingSlotsList = createAsyncThunk("calendar/get-conflicting-slots-list", async (data) => {
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(data?.reqBody),
  };
  const response = await fetch(data?.env + data?.endPoint, requestOptions);
  const respdata = await response.json();

  return respdata;
});

export const getSlotsNotCreatedList = createAsyncThunk("calendar/get-slots-not-created-list", async (data) => {
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(data?.reqBody),
  };
  const response = await fetch(data?.env + data?.endPoint, requestOptions);
  const respdata = await response.json();

  return respdata;
});

export const getEventDayArrNotCreatedList = createAsyncThunk("calendar/get-event-day-arr-not-created-list", async (data) => {
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(data?.reqBody),
  };
  const response = await fetch(data?.env + data?.endPoint, requestOptions);
  const respdata = await response.json();

  return respdata;
});

export const getSixMonthSlotsList = createAsyncThunk("calendar/get-six-month-slots-list", async (data) => {
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(data?.reqBody),
  };
  const response = await fetch(data?.env + data?.endPoint, requestOptions);
  const respdata = await response.json();

  return respdata;
});



const developerInfoSlice = createSlice({
  name: 'developerInfoSlice',
  initialState: {
    eventDayArrNotCreated: null,
    eventDayArrNotCreatedLoading: false,
    eventSlotsNotCreated: null,
    eventSlotsCreatedLoading: false,
    eventSlotsEmpty: null,
    eventSlotsEmptyLoading: false,

    calendarStatusList: null,
    calendarStatusListLoading: false,
    slotsNotCreatedList: null,
    slotsNotCreatedListLoading: false,
    conflictingSlotsList: null,
    conflictingSlotsListLoading: false,
    eventDayArrNotCreatedList: null,
    eventDayArrNotCreatedListLoading: false,
    sixMonthSlotsList: null,
    sixMonthSlotsListLoading: false
  },
  reducers: {
    resetStates(state, action) {
      state.eventDayArrNotCreated = null;
      state.eventDayArrNotCreatedLoading = false;
      state.eventSlotsNotCreated = null;
      state.eventSlotsCreatedLoading = false;
      state.eventSlotsEmpty = null;
      state.eventSlotsEmptyLoading = false;
    }
  },
  extraReducers: {
    [getEventSlotsNotCreated.pending]: (state, action) => {
      state.eventSlotsNotCreated = null
      state.eventSlotsCreatedLoading = true
    },
    [getEventSlotsNotCreated.rejected]: (state, action) => {
      state.eventSlotsCreatedLoading = false
    },
    [getEventSlotsNotCreated.fulfilled]: (state, action) => {
      state.eventSlotsCreatedLoading = false
      if (action.payload.status === 'success') state.eventSlotsNotCreated = action.payload.results.res
      else state.eventSlotsNotCreated = []
    },

    [getEventDayArrNotCreated.pending]: (state, action) => {
      state.eventDayArrNotCreated = null
      state.eventDayArrNotCreatedLoading = true
    },
    [getEventDayArrNotCreated.rejected]: (state, action) => {
      state.eventDayArrNotCreatedLoading = false

    },
    [getEventDayArrNotCreated.fulfilled]: (state, action) => {
      state.eventDayArrNotCreatedLoading = false
      if (action.payload.status === 'success') state.eventDayArrNotCreated = action.payload.results.res
      else state.eventDayArrNotCreated = []
    },

    [getEventDaySlotsEmpty.pending]: (state, action) => {
      state.eventSlotsEmpty = null
      state.eventSlotsEmptyLoading = true
    },
    [getEventDaySlotsEmpty.rejected]: (state, action) => {
      state.eventSlotsEmptyLoading = false

    },
    [getEventDaySlotsEmpty.fulfilled]: (state, action) => {
      state.eventSlotsEmptyLoading = false
      if (action.payload.status === 'success') state.eventSlotsEmpty = action.payload.results.res
      else state.eventSlotsEmpty = []
    },

    // For Calendar Status List
    [getCalendarStatusList.pending]: (state, action) => {
      console.log("pending");
      state.calendarStatusListLoading = true;
    },
    [getCalendarStatusList.fulfilled]: (state, action) => {

      state.calendarStatusListLoading = false;
      state.calendarStatusList = action.payload?.results?.res ? action.payload?.results?.res : null
      console.log("fulfilled ---------?", state.calendarStatusList);
    },
    [getCalendarStatusList.rejected]: (state, action) => {
      state.calendarStatusListLoading = false;
      state.calendarStatusList = null
    },

    // For Conflicting Slots List
    [getConflictingSlotsList.pending]: (state, action) => {
      state.conflictingSlotsListLoading = true;
    },
    [getConflictingSlotsList.fulfilled]: (state, action) => {
      state.conflictingSlotsListLoading = false;
      state.conflictingSlotsList = action.payload?.results?.res ? action.payload?.results?.res : null;
    },
    [getConflictingSlotsList.rejected]: (state, action) => {
      state.conflictingSlotsListLoading = false;
      state.conflictingSlotsList = null
    },

    // For Slots Not Created List
    [getSlotsNotCreatedList.pending]: (state, action) => {
      state.slotsNotCreatedListLoading = true;
    },
    [getSlotsNotCreatedList.fulfilled]: (state, action) => {
      state.slotsNotCreatedListLoading = false;
      state.slotsNotCreatedList = action.payload?.results?.res ? action.payload?.results?.res : null
    },
    [getSlotsNotCreatedList.rejected]: (state, action) => {
      state.slotsNotCreatedListLoading = false;
      state.slotsNotCreatedList = null
    },

    // For Event Day Arr Not Created List
    [getEventDayArrNotCreatedList.pending]: (state, action) => {
      state.eventDayArrNotCreatedListLoading = true;
    },
    [getEventDayArrNotCreatedList.fulfilled]: (state, action) => {
      state.eventDayArrNotCreatedListLoading = false;
      state.eventDayArrNotCreatedList = action.payload?.results?.res ? action.payload?.results?.res : null;
    },
    [getEventDayArrNotCreatedList.rejected]: (state, action) => {
      state.eventDayArrNotCreatedListLoading = false;
      state.eventDayArrNotCreatedList = null
    },

    // For Six Month Slots List
    [getSixMonthSlotsList.pending]: (state, action) => {
      state.sixMonthSlotsListLoading = true;
    },
    [getSixMonthSlotsList.fulfilled]: (state, action) => {
      state.sixMonthSlotsListLoading = false;
      state.sixMonthSlotsList = action.payload?.results?.res ? action.payload?.results?.res : null;
    },
    [getSixMonthSlotsList.rejected]: (state, action) => {
      state.sixMonthSlotsListLoading = false;
      state.sixMonthSlotsList = null
    },
  }
})

export default developerInfoSlice.reducer;
export const { resetStates } = developerInfoSlice.actions;