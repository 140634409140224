import React, { useEffect, useState } from 'react'
import { DateRangePicker } from '@mui/x-date-pickers-pro/DateRangePicker';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { Box } from '@mui/system';
import { FormControl, FormHelperText, InputLabel, TextField } from '@mui/material';

export const DateSearch = ({ fielddata, setValue, getValues }) => {
  const [dateRange, setDateRange] = useState([null, null]);

  return (
    <div className={fielddata.field}>
      {/* HEADING */}
      {fielddata.heading && <InputLabel ><div dangerouslySetInnerHTML={{ __html: fielddata.heading }}></div></InputLabel>}
      {/* FIELD */}
      <FormControl style={{ width: '100%', margin: "10px" }}>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DateRangePicker
            startText={fielddata.startdatelabel}
            endText={fielddata.enddatelabel}
            value={dateRange}
            onOpen={() => {
              //////////////////////// Removing Missing License Key Message /////////////////////
              setTimeout(() => {
                if (document.querySelector(fielddata.field) != undefined && document.querySelector(fielddata.field).style != undefined)
                  document.querySelector(fielddata.field).style.display = 'none';

                let e = document.getElementsByTagName("div");
                for (let i = 0; i < e.length; i++) {
                  let textval = e[i].innerText;
                  if (textval.includes('Missing license key') && textval.length < 30) {
                    e[i].style.display = 'none';
                  }

                }
                if (document.querySelector(fielddata.field) != undefined && document.querySelector(fielddata.field).style != undefined)
                  document.querySelector(fielddata.field).style.display = 'block'
              }, 200);
            }}
            onChange={(newValue) => {
              console.log('newValue', newValue);
              newValue.forEach(val => console.log(val));
              setDateRange(newValue);
            }}
            onClose={() => {
              if (!dateRange.includes(null)) {
                setValue(fielddata.field, dateRange.map(date => date.getTime())); //////////// Converting Date Object to milisecond ////////////
              }
            }}
            renderInput={(startProps, endProps) => (
              <>
                <TextField {...startProps} />
                <Box sx={{ mx: 2 }}> to </Box>
                <TextField {...endProps} />
              </>
            )}
          />
        </LocalizationProvider>
      </FormControl>
    </div>
  )
}

// Remove License Console Error : go to the file node_modules/@mui/x-license-pro/utils/licenseErrorMessageUtils.js and remove console.error() of showError() function
