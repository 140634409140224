import React, { useEffect, useRef } from 'react'
import { Box, LinearProgress } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import ListingTable from '../../../../../listing/listing';
import { getEventDayArrNotCreatedList } from '../developerInfoReducer';
import { TableSkeleton } from '../../../../Common/TableSkeleton';

const EventDayArrNotCreated = ({ env, endPoint, autoEnpointBase }) => {
  const dispatch = useDispatch();
  const { pathname } = useLocation();

  const eventDayArrNotCreatedListLoading = useSelector(state => state?.developerInfoSlice?.eventDayArrNotCreatedListLoading ? state.developerInfoSlice.eventDayArrNotCreatedListLoading : null);
  const eventDayArrNotCreatedList = useSelector(state => state?.developerInfoSlice?.eventDayArrNotCreatedList ? state.developerInfoSlice.eventDayArrNotCreatedList : null);

  let reqBody = {
    source: '',
    condition: { limit: 10, skip: 0 },
    sort: { type: 'asc', field: '_id' },
    searchcondition: {},
    token: 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJmb28iOiJiYXIiLCJleHAiOjE2NDg1NDAyNTgsImlhdCI6MTY0ODQ1Mzg1OH0.QGs0yduTqQSjHj1mEmmuu4Ivb1IO5sgcnTCIH_DOu1E',
    secret: 'JwX.(1QHj'
  };

  // Slots Not Created Table
  const modifyHeadersEventDayArrNotCreated = [
    { val: 'event_user_info', name: 'Event Info/User Info' },
    { val: 'event_start_date', name: 'E-Start Date', type: "datetime", format: "DD-MM-YYYY" },
    { val: 'event_end_date', name: 'E-End Date', type: "datetime", format: "DD-MM-YYYY" },
    { val: 'event_start_end_time', name: 'E-Start/End Time' },
    { val: 'event_creation_time', name: 'Created On', type: "datetime", format: "DD/MM/YYYY hh:mm A" },
    { val: 'event_updation_time', name: 'Updated On', type: "datetime", format: "DD/MM/YYYY hh:mm A" },
    { val: 'timezone', name: 'Location' },
    { val: 'timespan', name: 'Timespan(min)' },
  ]
  const eventDayArrNotCreated = useRef({
    tableId: 'eventDayArrNotCreatedList',
    tableTitle: 'Event Day Arr Not Created List',
    showReload: true,
    showFilter: true,
    api: {
      url: env,
      // deleteUrl: env,
      endPoint: endPoint,
      tableCountEndpoint: endPoint,
      deleteSingleUserEndpoint: "googleapi/delete-temp-conflicting-slot",
    },
    reqBody: reqBody,
    deleteSingleUserReqBody: {
      "token": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJmb28iOiJiYXIiLCJleHAiOjE2NTM3MTM5ODEsImlhdCI6MTY1MzYyNzU4MX0.QT_QnUUm_0niY3UvVIIZH4baUUKs5I4ZAORzuqRWwWE"
    },
    rowsPerPageOptions: [5, 10, 20, 30, 50, 100],
    sortFields: ["email", "calendar_status"],
    enableRowActionLoader: true,
    // paginationType: "stackPagination",
    customBtnHead: [],
    buttons: [],
    searchData: {
      heading: "Search Event Day Arr Not Created",
      type: "drawer",
      // condition_type: "$or",
      formData: {
        id: 'searchForm',
        // formWrapperClass: "formWrapperClass",// Can be undefined default will be formCls
        // formInlineCLass: "searchForm", // Can be undefined default will be formInline
        formButtonClass: "submitbtnsection",
        // submitBtnName: "Search",
        // submitBtnEndIcon: "search",
        // resetBtnName: "Reset",
        // resetBtnEndIcon: "replay",
        resetForm: true,
        formAdditionalSubmissionData: {},
        fields: [
          {
            id: 0,
            label: "Search By User Email",
            name: "useremail",
            type: "text",
            className: 'formGroup countDiv__column--col4',
            sx: { m: 1, minWidth: 300 },
            payloadFormat: { key: "useremail", param: "$regex", options: "i" },

          },
          {
            id: 1,
            label: "Search By Connected Gmail",
            name: "connected_gmail",
            type: "text",
            className: 'formGroup countDiv__column--col4',
            sx: { m: 1, minWidth: 300 },
            payloadFormat: { key: "connected_gmail", param: "$regex", options: "i" },

          },
        ]
      }
    },
  });

  useEffect(() => {
    dispatch(getEventDayArrNotCreatedList({ reqBody, env, endPoint }));
  }, [])

  return (
    <div className='reactTableWrapper'>
      <div className="reactTable">
        {eventDayArrNotCreatedListLoading && <Box sx={{ width: "100%", mt: "10px" }}><LinearProgress sx={{ height: "5px" }} /></Box>}
        {eventDayArrNotCreatedList ? <ListingTable tableData={eventDayArrNotCreated.current} dataset={eventDayArrNotCreatedList} modifyHeaders={modifyHeadersEventDayArrNotCreated} /> : <TableSkeleton count={5} />}
      </div>
    </div>
  )
}

export default EventDayArrNotCreated