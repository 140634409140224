import React, { useEffect, useState } from 'react'
import { Cookies, useCookies } from 'react-cookie';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { timeConverter } from '../../helper/helperFunctions';
import store from '../../store';
import './thankyou.css';

export const ThankyouPage = () => {
    const [calendarData, setcalendarData] = useState({});
    const [cookies, setCookies] = useCookies(["SlotData"]);
    const [bookingPageHeading, setBookingPageHeading] = useState('Thank you for scheduling a meeting')
    const [reschedulePageHeading, setReschedulePageHeading] = useState('Thank you for re-scheduling a meeting to discuss our government releif and expense reduction programs for your business contacts and executives.')
    const [cancelPageHeading, setCancelPageHeading] = useState('Your appointment canceled successfully')
    const selectedSlot = useSelector((state) => state.calenderEventsStore.selectedSlot)
    const fetchIdentifierData = useSelector((state) => state.calenderEventsStore.fetchIdentifierData)
    const storageSelectedSlot = window.localStorage.getItem('selectedSlot')
    const storagefetchIdentifierDat = window.localStorage.getItem('fetchIdentifierData')
    const [showseletedSlot, setShowselectedSlot] = useState(null)
    const [showfetchIdentifierData, setShowfetchIdentifierData] = useState(null)
    const userSeletedTimezone = localStorage.getItem("currenttimezone")


    useEffect(() => {
        if (selectedSlot && Object.keys(selectedSlot).length > 0) {
            window.localStorage.setItem('selectedSlot', JSON.stringify(selectedSlot))
        }
    }, [JSON.stringify(selectedSlot)])

    useEffect(() => {
        if (fetchIdentifierData && Object.keys(fetchIdentifierData).length > 0) {
            window.localStorage.setItem('fetchIdentifierData', JSON.stringify(fetchIdentifierData))
        }
    }, [JSON.stringify(fetchIdentifierData)])






    useEffect(() => {
        if (storageSelectedSlot) {
            let datac = JSON.parse(storageSelectedSlot)
            setShowselectedSlot(datac)
        }
        if ((selectedSlot && Object.keys(selectedSlot))) {
            let datac = selectedSlot
            setShowselectedSlot(datac)
        }
    }, [JSON.stringify(selectedSlot), JSON.stringify(storageSelectedSlot)])



    useEffect(() => {
        if ((fetchIdentifierData && Object.keys(fetchIdentifierData))) {
            let datab = fetchIdentifierData
            setShowfetchIdentifierData(datab)
        }
        if (storagefetchIdentifierDat) {
            let datab = JSON.parse(storagefetchIdentifierDat)
            setShowfetchIdentifierData(datab)
        }
    }, [JSON.stringify(storagefetchIdentifierDat), JSON.stringify(fetchIdentifierData)])



    const previousPath = window.localStorage.getItem('previousPath');
    const { page } = useParams();
    const abbrs = {
        HST: 'Hawaii',
        EDT: 'Eastern Daylight',
        EST: 'Eastern',
        CST: 'Central',
        CDT: 'Central Daylight',
        MST: 'Mountain  ',
        MDT: 'Mountain Daylight',
        PST: 'Pacific',
        PDT: 'Pacific Daylight',
        IST: 'India',
        AKDT: 'Alaska Daylight',
        AKST: 'Alaska'

    };

    store.subscribe(() => {
        const storeData = store.getState();
    })

    useEffect(() => {
        switch (previousPath) {
            case '/practice-2lab':

                import('../Backend/Pages/calendarModule/Practice2Lab.css')

                document.body.classList.add('practice2lab');

                const favicon1 = document.querySelector('link[rel="icon"]');
                if (favicon1) {
                    favicon1.href = 'https://all-frontend-assets.s3.amazonaws.com/practice2lab/practice2lab_favicon.ico';
                }

                break;
            case '/clinical-speed':

                import('../Backend/Pages/calendarModule/clinicalspeed.css')
                document.body.classList.add('clinicalspeed');
                const favicon2 = document.querySelector('link[rel="icon"]');
                if (favicon2) {
                    favicon2.href = 'https://all-frontend-assets.s3.amazonaws.com/clinical_speed_next_ui/favicon.ico';
                }
                break;
            case '/under-payments':

                import('../Backend/Pages/calendarModule/underpayments.css')

                document.body.classList.add('underpayments');

                const favicon3 = document.querySelector('link[rel="icon"]');
                if (favicon3) {
                    favicon3.href = 'https://all-frontend-assets.s3.amazonaws.com/aspire-lead-fire-nest/aspireN_healthcare_favicon.webp';
                }
                break;
            default:

                break;
        }
    }, [previousPath]);

    useEffect(() => {

        if (calendarData && cookies && cookies.SlotData && Object.keys(calendarData).length === 0 && Object.keys(cookies.SlotData).length > 0) {
            setcalendarData(cookies.SlotData);
        }

    })

    useEffect(() => {
        if (cookies?.SlotData?.event_type) {
            if (cookies?.SlotData?.event_type == 1 || cookies?.SlotData?.event_type == 6) {
                setBookingPageHeading("Thank you for scheduling a meeting with us")
                setReschedulePageHeading("Thank you for re-scheduling a meeting with us")
            }
            if (cookies?.SlotData?.event_type == 7 || cookies?.SlotData?.event_type == 6) {
                setBookingPageHeading("Thank you for scheduling a meeting with us")
                setReschedulePageHeading("Thank you for re-scheduling a meeting with us")
            }
            if (cookies?.SlotData?.event_title.includes("Jeannette") && cookies?.SlotData?.locationPath.includes("re-schedule-appointment")) {
                setReschedulePageHeading("Thank you for re-scheduling a meeting with us")

            }
            if (cookies?.SlotData?.event_type == 8 || cookies?.SlotData?.event_type == 9) {
                setBookingPageHeading("Thank you for scheduling with me to review your business opportunities on our free Pharmacy programs")
                setReschedulePageHeading("Thank you for re-scheduling with me to review your business opportunities on our free Pharmacy programs")
            }
            if (cookies?.SlotData?.event_type == 10 || cookies?.SlotData?.event_type == 11) {
                setBookingPageHeading("I have received your lead and I will be reviewing. Please come prepared to explain the relationship and any further details")
                setReschedulePageHeading("I have received your lead and I will be reviewing.  Please come prepared to explain the relationship and any further details")
            }
            if (cookies?.SlotData?.event_type == 12) {
                setBookingPageHeading("We look forward to meeting with you to discuss our auditing, communications and technology services offered to assist in recovering your underpaid reimbursements.  No other company can get you the percentages we can and we are excited to share with you why")
                setReschedulePageHeading("We look forward to meeting with you to discuss our auditing, communications and technology services offered to assist in recovering your underpaid reimbursements.  No other company can get you the percentages we can and we are excited to share with you why")
            }
            if (cookies?.SlotData?.event_type == 13) {
                setBookingPageHeading("Our Access Health free Pharmacy programs work incredibly with affiliates and larger organizations. We look forward to discussing these opportunities with you on this call !")
                setReschedulePageHeading("Our Access Health free Pharmacy programs work incredibly with affiliates and larger organizations. We look forward to discussing these opportunities with you on this call !")
            }
            if (cookies?.SlotData?.event_type == 14) {
                setBookingPageHeading("Thank you for scheduling with me to review your business opportunities on our free Underpayment programs !")
                setReschedulePageHeading("Thank you for re-scheduling with me to review your business opportunities on our free Underpayment programs !")
            }
            if (cookies?.SlotData?.event_type == 15) {
                setBookingPageHeading("Thank you for scheduling with me to review your business opportunities on our Pratice2Lab !")
                setReschedulePageHeading("Thank you for re-scheduling with me to review your business opportunities on our Pratice2Lab !")
            }
            if (cookies?.SlotData?.event_type == 16) {
                setBookingPageHeading("Thank you for scheduling with me to review your business opportunities on our Pratice2Lab !")
                setReschedulePageHeading("Thank you for re-scheduling with me to review your business opportunities on our Pratice2Lab !")
            }
            if (cookies?.SlotData?.event_type == 17) {
                setBookingPageHeading("Thank you for scheduling with me to review your business opportunities on our Clinicalspeed !")
                setReschedulePageHeading("Thank you for re-scheduling with me to review your business opportunities on our Clinicalspeed !")
            }

            if (cookies?.SlotData?.event_type == 18) {
                setBookingPageHeading("Thank you for scheduling with me to review your business opportunities on our Underpayments !")
                setReschedulePageHeading("Thank you for re-scheduling with me to review your business opportunities on our Underpayments !")
            }
        }
    }, [cookies])

    return (
    <>
        <div className={'darktheme'}>
            <div className='thankyou-container'>
                <div className='thankyou-block'>
                    <div className='content'>
                        <h1>{page === "booking" ? bookingPageHeading : page === "reschedule" ? reschedulePageHeading : cancelPageHeading}</h1>
                            <p> {showseletedSlot?.event_title ? showseletedSlot?.username === 'Jeannette Skinner' ? 'Meeting Scheduled With Jeannette Skinner For ADHC Underpayments' : showseletedSlot?.event_title : showfetchIdentifierData?.username === 'Jeannette Skinner' ? 'Meeting Scheduled With Jeannette Skinner For ADHC Underpayments' : showfetchIdentifierData?.event_title} </p>
                            <p> E-mail: {showseletedSlot?.bookingUserEmail ? showseletedSlot?.bookingUserEmail == 'jeanette@adhcunderpayment.com' ? 'jeannette@adhcunderpayment.com' : showseletedSlot?.bookingUserEmail : showfetchIdentifierData?.bookingUserEmail == 'jeanette@adhcunderpayment.com' ? 'jeannette@adhcunderpayment.com' : showfetchIdentifierData?.bookingUserEmail} </p>
                            <p> Date: {timeConverter({
                                time: new Date(showseletedSlot?.startDateTime ? showseletedSlot?.startDateTime : showfetchIdentifierData?.startDateTime),
                                format: "LL",
                                timezone: userSeletedTimezone ? userSeletedTimezone : showseletedSlot?.user_timezone ? showseletedSlot?.user_timezone : showfetchIdentifierData?.user_timezone
                            })} </p>
                            <p> Start: {timeConverter({
                                time: new Date(showseletedSlot?.startDateTime ? showseletedSlot?.startDateTime : showfetchIdentifierData?.startDateTime),
                                format: "h:mm A",
                                timezone: userSeletedTimezone ? userSeletedTimezone : showseletedSlot?.user_timezone ? showseletedSlot?.user_timezone : showfetchIdentifierData?.user_timezone
                            })}</p>
                            <p>End: {timeConverter({
                                time: new Date(showseletedSlot?.endDateTime ? showseletedSlot?.endDateTime : showfetchIdentifierData?.endDateTime),
                                format: "h:mm A",
                                timezone: userSeletedTimezone ? userSeletedTimezone : showseletedSlot?.user_timezone ? showseletedSlot?.user_timezone : showfetchIdentifierData?.user_timezone
                            })}</p>
                            <p>Duration: {showseletedSlot?.timespan ? showseletedSlot?.timespan : showfetchIdentifierData?.timespan} mins</p>
                            <p>Timezone: {abbrs[timeConverter({
                                time: new Date(showseletedSlot?.endDateTime ? showseletedSlot?.endDateTime : showfetchIdentifierData?.endDateTime),
                                format: "zz",
                                timezone: userSeletedTimezone ? userSeletedTimezone : showseletedSlot?.user_timezone ? showseletedSlot?.user_timezone : showfetchIdentifierData?.user_timezone
                            })]}</p>
                        <p style={{display:'none'}} id='identifier'>{cookies?.BookingIdentity?.identifier}</p><p style={{display:'none'}} id='eventId'>{cookies?.BookingIdentity?.eventId}</p>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

