import React, { useEffect, useRef, useState } from 'react'
import { Cookies } from 'react-cookie';
import { useDispatch, useSelector } from 'react-redux';
import ListingTable from '../../../../listing/listing';
import { clearCustomButtonClicked } from '../../../../listing/listReducer';
import { Modal } from '../../../../listing/Modal';
import store from '../../../../store';

function EventsTableProd() {
  const cookies = new Cookies();
  const getcookies = cookies.getAll();
  const [responseData, setresponseData] = React.useState([]);
  const [useridsetdata, setuseridsetdata] = React.useState(getcookies.userInfo?._id);
  const [loaderFlag, setloaderFlag] = useState(true);
  const [norecordsFlag, setnorecordsFlag] = React.useState(false);
  const [snakbaropen, setsnakbaropen] = React.useState(false);
  const [snackBarMsg, setSnackBarMsg] = useState("");
  const [show, setShow] = useState(false);

  const stroreDispatch = useDispatch();
  const modalInfo = useSelector((state) => state.tableSlice?.tempViewData?.calendarProTable)
  console.log("modallllinfo", modalInfo);

  const tableDataPro = {
    tableId: "calendarProTable",
    tableTitle: "Calendar Availability Listing - Production",
    showReload: true,
    showFilter: true,
    api: {
      url: process.env.REACT_APP_PECE_PROD_URL,
      deleteUrl: process.env.REACT_APP_BASE_URL,
      endPoint: "calendarapi/event-information-list",
      tableCountEndpoint: "calendarapi/event-information-list-count",
      deleteSingleUserEndpoint: "delete-single-event",
    },
    reqBody: {
      source: "",
      condition: {
        limit: 5,
        skip: 0,
      },
      sort: {
        type: "desc",
        field: "_id",
      },
      searchcondition: { userid: useridsetdata },
      token:
        "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJmb28iOiJiYXIiLCJleHAiOjE2NDg1NDAyNTgsImlhdCI6MTY0ODQ1Mzg1OH0.QGs0yduTqQSjHj1mEmmuu4Ivb1IO5sgcnTCIH_DOu1E",
      secret: "JwX.(1QHj",
    },
    deleteSingleUserReqBody: {
      token:
        "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJmb28iOiJiYXIiLCJleHAiOjE2NTM3MTM5ODEsImlhdCI6MTY1MzYyNzU4MX0.QT_QnUUm_0niY3UvVIIZH4baUUKs5I4ZAORzuqRWwWE",
    },
    rowsPerPageOptions: [5, 20, 30],
    sortFields: ["event_title", "description"],
    // paginationType: "stackPagination",
    customBtnHead: [
      // {
      //   id: "deleteMultiple",
      //   type: "delete",
      //   title: "Delete",
      //   name: "delete",
      // },
      // {
      //   id: "editMultiple",
      //   type: "toggle",
      //   title: "Status Change",
      //   name: 'toggle'
      // }
    ],
    buttons: [
      // {
      //   id: "delete",
      //   type: "delete",
      //   label: "Delete",
      //   name: "delete",
      // },
      // {
      //   id: "edit",
      //   type: "edit",
      //   label: "Edit",
      //   name: "edit",
      // },
      {
        id: "view",
        type: "view",
        title: "View",
        name: "view",
      },
    ],
    deleteModal: {
      modalClassName: "deleteModal modalblock",
      modalStyle: {
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        width: 400,
        bgcolor: "background.paper",
        border: "2px solid #000",
        boxShadow: 24,
        p: 4,
        textAlign: "center",
      },
    },
    searchBarData: {
      heading: "Event Summary Search Bar",
      api: {
        url: process.env.REACT_APP_PECE_PROD_URL,
        endPoint: "calendarapi/event-information-list",
        tableCountEndpoint: "calendarapi/event-information-list-count",
        reqBody: {
          source: "",
          condition: {
            limit: 10,
            skip: 0,
          },
          sort: {
            type: "desc",
            field: "_id",
          },
        },
      },
      searchSettings: {
        datesearch: [
          {
            id: 0,
            startdatelabel: "Joined On Start Date",
            enddatelabel: "Joined On End Date",
            submit: "Search",
            field: "createdon_datetime",
          },
        ],
        selectsearch: [
          // {
          //   id: 1,
          //   label: "Search By Status",
          //   field: "status",
          //   values: ["Active", "Inactive"],
          //   value: "",
          // },
        ],
        textsearch: [
          { id: 2, label: "Search By User Name ", field: "username", value: "" },
          { id: 3, label: "Search By User Email ", field: "useremail", value: "" },
          { id: 4, label: "Search By Event Title ", field: "event_title", value: "" },
          { id: 5, label: "Search By Description ", field: "description", value: "" },
        ],
        autoCompleteSearch: [
          // {
          //   id: 5,
          //   label: "Search By Name",
          //   field: "fullname_s",
          //   values: "",
          //   multiple: false,
          //   serverSearchData: {
          //     base_url: "http://localhost:3000/dev/",
          //     endpoint: "api6/autocompletemanagername",
          //     reqBody: {
          //       condition: {
          //         limit: 10,
          //         skip: 0,
          //       },
          //       sort: {
          //         type: "desc",
          //         field: "_id",
          //       },
          //       searchcondition: {},
          //     },
          //   },
          // },
        ],
      },
    },
  };
  useEffect(() => {
    getdata();
  }, []);
  const ActionModal = useRef({
    id: "calendarProTable",
    className: "modal1 custommodal modalblock",
    style: {},
    heading: "Details",
    labels: {
      event_title: "Event Title",
      description: "Description",
      start_date: "Start Date",
      end_date: "End Date",
      start_time: "Start Time",
      end_time: "End Time",
      username: "User Name",
      useremail: "User Email"
    },
    data: null,
  });

  var modifyTableHeaders = [
    { val: "username", name: "User Name" },
    { val: "useremail", name: "User Email" },
    { val: "event_title", name: "Event Title" },
    { val: "description", name: "Description" },
    { val: "end_date", name: "End date" },
    { val: "end_time", name: "End time" },
    // { val: "perdayslot", name: "Slots" },
    // { val: "end_date_new", name: " End Date" },
    // { val: 'status', name: 'Status' },
    // { val: 'created_at', name: 'Created by' },
    // { val: "product_id", name: "Product" },
    // { val: 'phone', name: 'phone' }
  ];
  const getdata = async () => {
    console.log("useridsetdata++++++++++", useridsetdata);
    tableDataPro.reqBody.searchcondition.userid = useridsetdata;
    const requestOptions = {
      method: "POST",
      headers: { "Content-type": "application/json" },
      body: JSON.stringify(tableDataPro.reqBody),
    };
    const response = await fetch(process.env.REACT_APP_PECE_PROD_URL + "calendarapi/event-information-list", requestOptions);
    // console.log("++++response+++++++",response);

    const respdata = await response.json();
    console.log("++++cv+++++++", respdata);

    if (respdata.status == "success") {
      setresponseData([...respdata.results.res]);
      console.log(
        "=================respdata.results.res===================",
        respdata.results.res
      );
      setloaderFlag(false);

      if (responseData.length == 0) {
        setnorecordsFlag(true);
      }
      console.log("setresponseData", setresponseData);
    } else {
      setsnakbaropen(true);
      setSnackBarMsg("Something Wrong!");
    }
  };

  const closeModal = () => {
    setShow(false);
    stroreDispatch(
      clearCustomButtonClicked({
        tableId: "calendarProTable",
        buttonId: "view",
      })
    );
  };

  store.subscribe(() => {
    const storeData = store.getState();
    console.log("storeDatastoreData", storeData);
    if (storeData.tableSlice.customButtonClicked.calendarProTable && storeData.tableSlice.customButtonClicked.calendarProTable.view) {
      setShow(true);
      ActionModal.current.data =
        storeData.tableSlice.tempViewData.calendarProTable;
    }
  });

  return (
    <>
      {
        (responseData && responseData.length > 0 ? (
          <ListingTable
            tableData={tableDataPro}
            dataset={responseData}
            modifyHeaders={modifyTableHeaders}
          />
        ) : (
          <p>No Records Found</p>
        ))
      }
      <Modal show={show} onClose={closeModal} modalData={modalInfo} />
    </>
  )
}

export default EventsTableProd