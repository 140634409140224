import React, { useEffect, useRef, useState } from "react";
import ListingTable from "../../../../listing/listing";
import store from "../../../../store";
import { ModalBox } from "../../../../listing/ModalBox";
import { Cookies } from "react-cookie";
import { useDispatch } from "react-redux";
import {
  clearCustomButtonClicked,
  resetReloadTableFlag,
  resetRowActionBtnClicked,
  setReloadTableFlag,
  setTableData,
} from "../../../../listing/listReducer";

function ListingPro() {
  const cookies = new Cookies();
  const getcookies = cookies.getAll();
  const stroreDispatch = useDispatch();

  const [responseDataPro, setresponseDataPro] = React.useState([]);
  const [useridsetdata, setuseridsetdata] = React.useState(
    getcookies.userInfo?._id
  );
  const [loaderFlag, setloaderFlag] = useState(true);
  const [norecordsFlag, setnorecordsFlag] = React.useState(false);
  const [snakbaropen, setsnakbaropen] = React.useState(false);
  const [snackBarMsg, setSnackBarMsg] = useState("");
  const [show, setShow] = useState(false);
  const [state, setState] = useState(0);

  const googleEventTablePro = {
    tableId: "googleEventTablePro",
    tableTitle: "Six months google events - Production",
    showReload: true,
    showFilter: true,
    api: {
      url: process.env.REACT_APP_PECE_PROD_URL,
      deleteUrl: process.env.REACT_APP_BASE_URL,
      endPoint: "calendarapi/six-months-slot",
      tableCountEndpoint: "calendarapi/six-months-slot-count",
      deleteSingleUserEndpoint: "delete-single-event",
    },
    reqBody: {
      source: "",
      condition: {
        limit: 5,
        skip: 0,
      },
      sort: {
        type: "desc",
        field: "_id",
      },
      searchcondition: {
        url: "",
        created_at: [],
        connected_gmail: "",
        updated_at: [],
      },
      token:
        "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJmb28iOiJiYXIiLCJleHAiOjE2NDg1NDAyNTgsImlhdCI6MTY0ODQ1Mzg1OH0.QGs0yduTqQSjHj1mEmmuu4Ivb1IO5sgcnTCIH_DOu1E",
      secret: "JwX.(1QHj",
    },
    deleteSingleUserReqBody: {
      token:
        "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJmb28iOiJiYXIiLCJleHAiOjE2NTM3MTM5ODEsImlhdCI6MTY1MzYyNzU4MX0.QT_QnUUm_0niY3UvVIIZH4baUUKs5I4ZAORzuqRWwWE",
    },
    rowsPerPageOptions: [5, 20, 30],
    sortFields: ["event_title", "description"],
    // paginationType: "stackPagination",
    customBtnHead: [
      // {
      //   id: "deleteMultiple",
      //   type: "delete",
      //   title: "Delete",
      //   name: "delete",
      // },
      // {
      //   id: "editMultiple",
      //   type: "toggle",
      //   title: "Status Change",
      //   name: 'toggle'
      // }
    ],
    buttons: [
      // {
      //   id: "delete",
      //   type: "delete",
      //   label: "Delete",
      //   name: "delete",
      // },
      // {
      //   id: "edit",
      //   type: "edit",
      //   label: "Edit",
      //   name: "edit",
      // },
      {
        id: "view",
        type: "view",
        title: "View",
        name: "view",
      },
    ],
    deleteModal: {
      modalClassName: "deleteModal modalblock",
      modalStyle: {
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        width: 400,
        bgcolor: "background.paper",
        border: "2px solid #000",
        boxShadow: 24,
        p: 4,
        textAlign: "center",
      },
    },
    searchBarData: {
      heading: "Six Months Google Events Search Bar",
      api: {
        url: process.env.REACT_APP_PECE_PROD_URL,
        endPoint: "calendarapi/six-months-slot",
        tableCountEndpoint: "calendarapi/six-months-slot-count",
        reqBody: {
          source: "",
          condition: {
            limit: 5,
            skip: 0,
          },
          sort: {
            type: "desc",
            field: "_id",
          },
        },
      },
      searchSettings: {
        datesearch: [
          {
            id: 0,
            startdatelabel: "Joined On Start Date",
            enddatelabel: "Joined On End Date",
            submit: "Search",
            field: "createdon_datetime",
          },
        ],
        selectsearch: [
          // {
          //   id: 1,
          //   label: "Search By Status",
          //   field: "status",
          //   values: ["Active", "Inactive"],
          //   value: "",
          // },
        ],
        textsearch: [
          { id: 1, label: "Search By Summary ", field: "summary", value: "" },
          { id: 2, label: "Search By Event Type ", field: "eventType", value: "" },
          { id: 3, label: "Search By User Email ", field: "user_email", value: "" }
        ],
        autoCompleteSearch: [
          // {
          //   id: 5,
          //   label: "Search By Name",
          //   field: "fullname_s",
          //   values: "",
          //   multiple: false,
          //   serverSearchData: {
          //     base_url: "http://localhost:3000/dev/",
          //     endpoint: "api6/autocompletemanagername",
          //     reqBody: {
          //       condition: {
          //         limit: 10,
          //         skip: 0,
          //       },
          //       sort: {
          //         type: "desc",
          //         field: "_id",
          //       },
          //       searchcondition: {},
          //     },
          //   },
          // },
        ],
      },
    },
  };

  var modifyTableHeaders = [
    { val: "eventType", name: "Event Type" },
    { val: "summary", name: "Summary" },
    { val: "user_email", name: "User Email" },
    { val: "created", name: "Created" },
    { val: "updated", name: "Updated" },
    // { val: "perdayslot", name: "Slots" },
    // { val: "end_date_new", name: " End Date" },
    // { val: 'status', name: 'Status' },
    // { val: 'created_at', name: 'Created by' },
    // { val: "product_id", name: "Product" },
    // { val: 'phone', name: 'phone' }
  ];

  const getdata = async () => {
    console.log("useridsetdata++++++++++", useridsetdata);
    googleEventTablePro.reqBody.searchcondition.url = "";
    googleEventTablePro.reqBody.searchcondition.created_at = "";
    googleEventTablePro.reqBody.searchcondition.connected_gmail = "";
    googleEventTablePro.reqBody.searchcondition.updated_at = "";

    const requestOptions = {
      method: "POST",
      headers: { "Content-type": "application/json" },
      body: JSON.stringify(googleEventTablePro.reqBody),
    };

    const responsePro = await fetch(
      // process.env.REACT_APP_PECE_DEV_CALENDAR_URL + "calendarapi/event-information-list",
      "https://qzgvkmkqh6.execute-api.us-east-1.amazonaws.com/production/calendarapi/six-months-slot",
      requestOptions
    );

    const respdataPro = await responsePro.json();
    console.log("++++cv+++++++", respdataPro);

    if (respdataPro.status == "success") {
      setresponseDataPro([...respdataPro.results.res]);
      console.log(
        "=================respdata.results.res===================",
        respdataPro.results.res
      );
      setloaderFlag(false);

      if (responseDataPro.length == 0) {
        setnorecordsFlag(true);
      }
      console.log("setresponseData", setresponseDataPro);
    } else {
      setsnakbaropen(true);
      snackBarMsg("Something Wrong!");
    }
  };

  store.subscribe(() => {
    const storeData = store.getState();
    console.log("storeDatastoreData", storeData);
    if (
      storeData.tableSlice.customButtonClicked.googleEventTablePro &&
      storeData.tableSlice.customButtonClicked.googleEventTablePro.view
    ) {
      setShow(true);
      ActionModalPro.current.data =
        storeData.tableSlice.tempViewData.googleEventTablePro;
    }
  });

  const ActionModalPro = useRef({
    id: "googleEventTablePro ",
    className: "modal1 custommodal modalblock",
    style: {},
    heading: "Details",
    labels: {
      summary: "Summary",
      user_email: "User Email",
      created: "Created",
      updated: "Updated",
      createdTimeDifference: "Created Time Difference",
      updatedTimeDifference: "Updated Time Difference",
      eventType: "Event Type",
      kind: "Kind",
      status: "Status",
      connected_gmail: "Connected gmail",
    },
    data: null,
  });

  const closeModal = () => {
    setShow(false);
    stroreDispatch(
      clearCustomButtonClicked({
        tableId: "googleEventTablePro",
        buttonId: "view",
      })
    );
    stroreDispatch(
      resetRowActionBtnClicked({
        tableId: "googleEventTablePro",
        buttonId: "view",
      })
    );
  };

  useEffect(() => {
    getdata();
  }, []);

  return (
    <>
      <div>
        <div className="reactTableWrapper">
          <div className="reactTable">
            {responseDataPro && responseDataPro.length > 0 ? (
              <ListingTable
                tableData={googleEventTablePro}
                dataset={responseDataPro}
                modifyHeaders={modifyTableHeaders}
              />
            ) : (
              <p>No Records Found</p>
            )}
          </div>
        </div>

        <div>
          <ModalBox
            open={show}
            close={closeModal}
            data={ActionModalPro.current}
          />
        </div>
      </div>
    </>
  );
}
export default ListingPro;
