import React from 'react';
import "./BackendFooter.css";

const BackendFooter = () => {
  return (
    <div className="footerContent"><p>Copyright © {new Date().getFullYear()} Calendar Management.</p></div>
  )
}
export default BackendFooter;



