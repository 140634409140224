import React, { useEffect } from 'react'
import { InputLabel, TextField, Icon, FormControl } from '@mui/material';
import InputAdornment from '@mui/material/InputAdornment';


export const TextSearch = ({ fielddata, setValue, getValues }) => {

  return (
    <div className={fielddata.field}>
      {/* HEADING */}
      {fielddata.heading && <InputLabel ><div dangerouslySetInnerHTML={{ __html: fielddata.heading }}></div></InputLabel>}
      {/* FIELD */}
      <FormControl style={{ width: '100%', margin: "10px" }}>
        <TextField
          type={(fielddata.inputType !== undefined || fielddata.inputType !== "") ? fielddata.inputType : "text"}
          value={getValues(fielddata.field) ? getValues(fielddata.field) : ""}
          onChange={(e) => {
            if (fielddata.inputType === "number") {
              setValue(fielddata.field, Number(e.target.value));
            } else {
              setValue(fielddata.field, e.target.value);
            }
          }}
          label={fielddata.label}
          InputProps={fielddata.adornments !== undefined ? (fielddata.adornments.position === "start" ? {
            startAdornment: (
              <InputAdornment position={fielddata.adornments.position}>
                <div dangerouslySetInnerHTML={{ __html: fielddata.adornments.content }}></div>
              </InputAdornment>
            ),
          } : {
            endAdornment: (
              <InputAdornment position={fielddata.adornments.position}>
                <div dangerouslySetInnerHTML={{ __html: fielddata.adornments.content }}></div>
              </InputAdornment>
            ),
          }
          ) : {}
          }
        />
      </FormControl>

    </div>
  )
}