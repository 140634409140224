import React, { useEffect, useRef } from 'react'
import { Box, LinearProgress } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import ListingTable from '../../../../../listing/listing';
import { getConflictingSlotsList } from '../developerInfoReducer';
import { TableSkeleton } from '../../../../Common/TableSkeleton';

const ConflictingSlots = ({ env, endPoint, autoEnpointBase }) => {
    const dispatch = useDispatch();
    const { pathname } = useLocation();

    const conflictingSlotsListLoading = useSelector(state => state?.developerInfoSlice?.conflictingSlotsListLoading ? state.developerInfoSlice.conflictingSlotsListLoading : null);
    const conflictingSlotsList = useSelector(state => state?.developerInfoSlice?.conflictingSlotsList ? state.developerInfoSlice.conflictingSlotsList : null);

    let reqBody = {
        source: '',
        condition: { limit: 10, skip: 0 },
        sort: { type: 'asc', field: '_id' },
        searchcondition: {},
        token: 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJmb28iOiJiYXIiLCJleHAiOjE2NDg1NDAyNTgsImlhdCI6MTY0ODQ1Mzg1OH0.QGs0yduTqQSjHj1mEmmuu4Ivb1IO5sgcnTCIH_DOu1E',
        secret: 'JwX.(1QHj'
    };

    // Conflicting Slots Table
    const modifyTableHeadersConflictingSlots = [
        { val: "event_user_info", name: "Event Details" },
        { val: 'deletion_type_slot_type', name: 'D-Type/S-Type' },
        { val: 'gcalendar_slot_created_at', name: 'G-Created', type: "datetime", format: "DD/MM/YYYY hh:mm:ss A" },
        { val: 'gcalendar_slot_entered_at', name: 'G-Entered', type: "datetime", format: "DD/MM/YYYY hh:mm:ss A" },
        { val: 'gcalendar_slot_deleted_at', name: 'G-Deleted', type: "datetime", format: "DD/MM/YYYY hh:mm:ss A" },
        { val: 'startdate_slot', name: 'Start Date/Slot' },
        { val: 'gap_deletion_format', name: 'Gap G-Cal' },
        { val: 'gap_deletion_system_format', name: 'Gap Sys' },
        { val: 'g_event_created_after_flag_format', name: 'G-Creation' },
    ]
    const conflictingSlots = useRef({
        tableId: 'conflictingSlots',
        tableTitle: 'Conflicting Slots',
        showReload: true,
        showFilter: true,
        api: {
            url: env,
            // deleteUrl: env,
            endPoint: endPoint,
            tableCountEndpoint: endPoint,
            deleteSingleUserEndpoint: "googleapi/delete-temp-conflicting-slot",
        },
        reqBody: reqBody,
        deleteSingleUserReqBody: {
            "token": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJmb28iOiJiYXIiLCJleHAiOjE2NTM3MTM5ODEsImlhdCI6MTY1MzYyNzU4MX0.QT_QnUUm_0niY3UvVIIZH4baUUKs5I4ZAORzuqRWwWE"
        },
        rowsPerPageOptions: [5, 10, 20, 30, 50, 100],
        sortFields: ["email", "calendar_status"],
        enableRowActionLoader: true,
        // paginationType: "stackPagination",
        customBtnHead: [],
        buttons: [],
        searchData: {
            heading: "Search Records",
            type: "drawer",
            // condition_type: "$or",
            formData: {
                id: 'searchForm',
                // formWrapperClass: "formWrapperClass",// Can be undefined default will be formCls
                // formInlineCLass: "searchForm", // Can be undefined default will be formInline
                formButtonClass: "submitbtnsection",
                // submitBtnName: "Search",
                // submitBtnEndIcon: "search",
                // resetBtnName: "Reset",
                // resetBtnEndIcon: "replay",
                resetForm: true,
                formAdditionalSubmissionData: {},
                fields: [
                    // Autocomplete Search Section Start
                    {
                        id: 0,
                        label: "Search By Name",
                        name: "name",
                        type: "autoComplete",
                        // autoStage:true,
                        className: "formGroup countDiv__column--col4",
                        base_url: env + `${autoEnpointBase}calendar-status-name-autocomplete`,
                        reqBody: {
                            condition: { limit: 10, skip: 0 },
                            sort: { type: "asc", field: "name" },
                            searchcondition: {},
                        },
                        sx: { m: 1, minWidth: 300 },
                        payloadFormat: { key: "_id" }
                    },
                    {
                        id: 1,
                        label: "Search By Related Email",
                        name: "email",
                        type: "autoComplete",
                        className: "formGroup countDiv__column--col4",
                        base_url: env + `${autoEnpointBase}calendar-status-email-autocomplete`,
                        reqBody: {
                            condition: { limit: 10, skip: 0 },
                            sort: { type: "asc", field: "email" },
                            searchcondition: {},
                        },
                        sx: { m: 1, minWidth: 300 },
                        payloadFormat: { key: "_id" },
                    },
                    // Autocomplete Search Section End

                    // Select Search Section Start
                    // {
                    //     id: 2,
                    //     label: "Search by Status",
                    //     name: "status_show",
                    //     type: "select",
                    //     multiple: false,
                    //     values: [
                    //         { val: 1, name: "Active" },
                    //         { val: 0, name: "Inactive" },
                    //     ],
                    //     sx: { m: 1, minWidth: 120 },
                    //     className: "formGroup countDiv__column--col4",
                    //     payloadFormat: { key: "status" },
                    // },

                    {
                        id: 2,
                        label: "Search By Authentication Type",
                        name: "calendar_status_message",
                        className: 'formGroup countDiv__column--col4',
                        type: "select",
                        // multiple: true,
                        values: [
                            { val: 'Slots: No', name: "No Slot" },
                            { val: 'not done', name: "Not Authenticated Yet" },
                            { val: 'Issue', name: "Authentication Issue" }
                        ],
                        payloadFormat: { key: "calendar_status_message", param: "$regex" },
                    },
                    // Select Search Section Start

                    // Date Search Section Start
                    // {
                    //     id: 3,
                    //     //   heading: "Search By Creation Time",
                    //     label: "Search by Created on",
                    //     name: "createdon_datetime",
                    //     className: "formGroup countDiv__column--col4",
                    //     type: "datePicker",
                    //     // fullDay: true,
                    //     sx: { m: 1, width: 300 },
                    //     payloadFormat: { key: "createdon_datetime", param: "$lte" },
                    //     // minDate:{minDate}
                    //     // errorMessage: {
                    //     //   // required: "This Field is Required",
                    //     //   custom: "End Date can not be less than Start Date"
                    //     // },
                    // },
                    {
                        id: 3,
                        label: "Search by Created On Date From",
                        name: autoEnpointBase == 'googleapi/' ? 'created_at' : 'createdon_datetime',
                        type: 'datePicker',
                        dateFormat: "dd/MM/yyyy", //////////////////////////// MM should be capital //////////////////
                        className: 'formGroup countDiv__column--col6',
                        payloadFormat: { key: autoEnpointBase == 'googleapi/' ? 'created_at' : 'createdon_datetime', param: "$gte" }
                    },
                    {
                        id: 4,
                        label: "Search by Created On Date To",
                        name: autoEnpointBase == 'googleapi/' ? 'created_at_to' : 'createdon_datetime_to',
                        type: 'datePicker',
                        dateFormat: "dd/MM/yyyy", //////////////////////////// MM should be capital //////////////////
                        className: 'formGroup countDiv__column--col6',
                        payloadFormat: { key: autoEnpointBase == 'googleapi/' ? 'created_at' : 'createdon_datetime', param: "$lte" }
                    },
                    // Date Search Section End
                ],
            }
        },
    });

    useEffect(() => {
        dispatch(getConflictingSlotsList({ reqBody, env, endPoint }));
    }, [])

    return (
        <div className='reactTableWrapper'>
            <div className="reactTable">
                {conflictingSlotsListLoading && <Box sx={{ width: "100%", mt: "10px" }}><LinearProgress sx={{ height: "5px" }} /></Box>}
                {conflictingSlotsList ? <ListingTable tableData={conflictingSlots.current} dataset={conflictingSlotsList} modifyHeaders={modifyTableHeadersConflictingSlots} /> : <TableSkeleton count={5} />}
            </div>
        </div>
    )
}

export default ConflictingSlots