import MetaTags from "react-meta-tags";

const NoPage = () => {
    return (
      <>
        {/* {/ Meta Service Start Here /} */}
        <MetaTags>
          <title>Login</title>
          <meta id="meta-description" name="description" content="One with Dawn for BioEnergetics, Michael for PECE, Integration meeting for PECE and another integration meeting for BioEnergetics." />
          <meta id="og-title" property="og:title" content="Page Not Found" />
          <meta
            id="og-image"
            property="og:image"
            content="path/to/og_image.jpg"
          />
        </MetaTags>
        {/* {/ Meta Service End Here /} */}

        404 Page
      </>
      
    )
  };
  
  export default NoPage;
  