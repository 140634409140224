import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";


export const fetchMiddlewareData = createAsyncThunk("middleware/", async (reqBody) => {

  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(reqBody),
  };
  console.log("process.env==>", process.env);
  const response = await fetch(
    process.env.REACT_APP_API_URL + "api3/fetch-formdata",
    requestOptions
  );
  const respdata = await response.json();

  console.log("respdata after endpoint fetch==>", respdata);
  return respdata;
});

const middlewareData = createSlice({
  name: "middleware",
  initialState: {
    midlleWareData: [],
    loading: false,
  },
  reducers: {

  },
  extraReducers: {
    [fetchMiddlewareData.pending]: (state, action) => {
      state.loading = true;
    },
    [fetchMiddlewareData.rejected]: (state, action) => {
      state.loading = false;
    },
    [fetchMiddlewareData.fulfilled]: (state, action) => {
      state.loading = false;
      if (action.payload.status === "error") {
        state.errorMessage = action.payload.message;
      }
      if (action.payload.status === "success") {
        console.log("action.payload.data", action.payload);
        state.midlleWareData = [...state.midlleWareData, ...action.payload.result];
        console.log("data goes here>>>>>>>>>>>..", state.midlleWareData)
      }
    },

  },
});






export const { } = middlewareData.actions;

export default middlewareData.reducer; 