import { Box, Button, LinearProgress, Snackbar } from '@mui/material';
import MetaTags from "react-meta-tags";
import React, { useEffect, useState } from 'react';
import './calender.css';
import ListingTable from '../../../../listing/listing';
import { Link, useNavigate, useParams } from 'react-router-dom';
import store from '../../../../store';
import { useDispatch } from 'react-redux';
import { cancelEvent, cancelSubmissionStateReset, fetchBookedSlotData, fetchEventWithIdentifier } from './calendarReducer';
import { timeConverter } from '../../../../helper/helperFunctions';
import { Cookies } from 'react-cookie';

const CancelSlot = () => {
    const dispatch = useDispatch();
    const [loaderFlag, setloaderFlag] = useState(true);
    const [slotData, setSlotData] = useState(null);
    const [snakbaropen, setSnakbaropen] = useState(false);
    const [snackBarMsg, setSnackBarMsg] = useState('');
    const [cancelSubmissionState, setCancelSubmissionState] = useState(0);
    const [cancelData, setCancelData] = useState({});
    const [cancelslotFlag, setcancelslotFlag] = useState(true);

    const abbrs = {
        HST: 'Hawaii Standard Time',
        EDT: 'Eastern Daylight Time',
        CST: 'Central Standard Time',
        CDT: 'Central Daylight Time',
        MST: 'Mountain Standard Time',
        MDT: 'Mountain Daylight Time',
        PST: 'Pacific Standard Time',
        PDT: 'Pacific Daylight Time',
        IST: 'India Standard Time',
        AKDT: 'Alaska Standard Time',
        EST: 'Eastern Standard Time',
    };


    let { identifier } = useParams();
    const cookies = new Cookies();
    const getcookies = cookies.getAll();
    const navigate = useNavigate();
    console.log("params", identifier);
    store.subscribe(() => {
        const storeData = store.getState();
        setloaderFlag(storeData.calenderEventsStore.loading);
        // console.log("storeData.calenderEventsStore 888", storeData.calenderEventsStore)
        setSlotData(storeData.calenderEventsStore.fetchIdentifierData);
        setCancelSubmissionState(storeData.calenderEventsStore.cancelSubmissionState);
        if (storeData.calenderEventsStore.fetchIdentifierData.status === 2) {
            setcancelslotFlag(false);
        }
        if (storeData.calenderEventsStore.success && storeData.calenderEventsStore.successMsg !== "") {
            setSnakbaropen(true);
            setSnackBarMsg(storeData.calenderEventsStore.successMsg)
        }
        if (storeData.calenderEventsStore.error && storeData.calenderEventsStore.errorMsg !== "") {
            setSnakbaropen(true);
            setSnackBarMsg(storeData.calenderEventsStore.errorMsg)
        };
        if (storeData.calenderEventsStore.fetchIdentifierData && Object.keys(storeData.calenderEventsStore.fetchIdentifierData).length > 0 && storeData.calenderEventsStore.fetchIdentifierData.condition) {
            // console.log("storeData.calenderEventsStore.fetchIdentifierData.condition.userid 666", storeData.calenderEventsStore.fetchIdentifierData.condition.userid)
            setCancelData(storeData.calenderEventsStore.fetchIdentifierData);
        }
    })
    useEffect(() => {
        dispatch(fetchEventWithIdentifier({ identifier: identifier }))
    }, [])
    useEffect(() => {
        console.log("rescheduleSubmissionState before", cancelSubmissionState);
        if (cancelSubmissionState === 2) {
            console.log("cancelSubmissionState");
            ///////////////////////// Clear Middleware cookies ////////////////////////
            //   const savedCookies = new Cookies();
            //   savedCookies.remove("middleware", { path: "/" });    

            dispatch(cancelSubmissionStateReset());
            const savedCookies = new Cookies();
            // savedCookies.remove("SlotData", { path: "/" });
            cookies.set('SlotData', cancelData, { path: '/' });
            navigate('/thankyou/cancel', { replace: true });
            // resetFunction();
        }
    }, [cancelSubmissionState]);
    useEffect(() => {
        if (snakbaropen) {
            setTimeout(() => {
                setSnakbaropen(false);
            }, 3000)
        }
    }, [snakbaropen])

    const handleCancel = (confirm) => {
        const requestbody = {
            "identifier": identifier
        }
        // setLoaderFlag(true);
        if (confirm) {
            dispatch(cancelEvent(requestbody));
        } else {
            console.log("don't cancel slot", slotData);
            // window.open(`/${slotData.locationPath}`, '_blank');
            navigate(`/${slotData.locationPath}`, { replace: true });
        }
        console.log("cancel slot", requestbody);
        // if (confirm) {
        // } else {
        //     console.log("don't cancel slot");
        // }
    }
    const handleClose = () => {

    }

    return (
        <>
            {/* {/ Meta Service Start Here /} */}
            {/* <MetaTags>
                <title>Cancel Slot</title>
                <meta
                    id="meta-description"
                    name="description"
                    content="One with Dawn for BioEnergetics, Michael for PECE, Integration meeting for PECE and another integration meeting for BioEnergetics."
                />
                <meta id="og-title" property="og:title" content="Available Slot" />
                <meta
                    id="og-image"
                    property="og:image"
                    content="path/to/og_image.jpg"
                />
            </MetaTags> */}
            {/* {/ Meta Service End Here /} */}

            {/* Loader */}
            {/* {loaderFlag == true ? (
                <Box sx={{ width: "100%" }}>
                    <LinearProgress sx={{ height: "5px" }} />
                </Box>
            ) : (
                ""
            )} */}
            {cancelslotFlag === true ?
                <div>
                    <div className="ContentWrapper">
                        {loaderFlag == true && (
                            <Box sx={{ width: "100%" }}>
                                <LinearProgress sx={{ height: "5px" }} />
                            </Box>
                        )}
                        <div className="Formdiv">

                            <div className="FormBlock">
                                <div className="calender_body formCls">
                                    {slotData !== null && Object.keys(slotData).length > 0 ?
                                        <div className="calender_block" >
                                            <h2>Are you sure want to cancel ?</h2>
                                            <p>
                                                <span></span>{slotData.event_title}
                                            </p>
                                            {/* <p>
                                            <span>Description :</span>{slotData.description}
                                        </p> */}
                                            <p>
                                                <span> Date: </span>{timeConverter({ time: slotData.startDateTime, format: "dddd MMMM Do YYYY", timezone: slotData.user_timezone })} <span>to</span> {timeConverter({ time: slotData.endDateTime, format: "dddd MMMM Do YYYY", timezone: slotData.user_timezone })}
                                            </p>
                                            <p>
                                                {/* <span>Event Time : </span>{timeConverter({ time: slotData.startDateTime, format: "h:mm:ss a" })} <span>to</span> {timeConverter({ time: slotData.endDateTime, format: "h:mm:ss a" })} */}
                                                <span>Time: </span>{timeConverter({ time: slotData.startDateTime, format: "h:mm A", timezone: slotData.user_timezone })} <span>to</span> {timeConverter({ time: slotData.endDateTime, format: "h:mm A", timezone: slotData.user_timezone })}
                                            </p>
                                            {/* <p>
                                                <span>Slot Time : </span>{timeConverter({ time: slotData.startDateTime, format: " h:mm:ss a" })} <span>to</span> {timeConverter({ time: slotData.endDateTime, format: "h:mm:ss a" })}
                                                <span>Time: </span>{timeConverter({ time: new Date(slotData.startDateTime), format: "h:mm:ss A", timezone: slotData.user_timezone })} <span>to</span> {timeConverter({ time: new Date(slotData.endDateTime), format: "h:mm:ss A", timezone: slotData.user_timezone })}
                                            </p> */}
                                            <p>
                                                {/* <span>Timezone : </span> {slotData.timezoneName} */}
                                                <span>Timezone: </span> {abbrs[timeConverter({ time: new Date(slotData.endDateTime), format: "zz", timezone: slotData.user_timezone })]}
                                            </p>
                                            {slotData.atten_email.length > 0 && (
                                                <>
                                                    <p>
                                                        <span>Attendee Name(s): </span>
                                                        {slotData.atten_email.map((cur, index) =>
                                                            <span className='innerSpan' key={index}>{cur.name}
                                                                {index === (slotData.atten_email.length - 1) ? '' : ', '}
                                                            </span>
                                                        )}
                                                    </p>
                                                    <p>
                                                        <span>Attendee Email(s): </span>
                                                        {slotData.atten_email.map((cur, index) =>
                                                            <span className='innerSpan' key={index}>
                                                                {cur.email}
                                                                {index === (slotData.atten_email.length - 1) ? '' : ', '}
                                                            </span>
                                                        )}
                                                    </p>
                                                </>
                                            )}

                                            <div className='cancelSlotBtnSection' >
                                                <Button variant='contained' onClick={() => handleCancel(true)}>Yes</Button>
                                                <Button variant='contained' onClick={() => handleCancel(false)}>No</Button>
                                            </div>
                                        </div>
                                        : <div>
                                            <h3><em>Fetching Slot!!! Please Wait...</em></h3>
                                        </div>}
                                </div>
                            </div>
                        </div>

                        {loaderFlag == true && (
                            <Box sx={{ width: "100%", marginTop: "2rem" }}>
                                <LinearProgress sx={{ height: "5px" }} />
                            </Box>
                        )}
                    </div>
                </div>
                :
                <div className='thankyou-container canceledAlready'>
                    <div className='thankyou-block'>
                        <div className='content'>
                            <h1>Your booked slot has already been canceled, you can no longer rescheduled from here. </h1>
                        </div>
                    </div>
                </div>
            }
            <Snackbar
                // anchorOrigin={{ vertical, horizontal }}
                autoHideDuration={3000}
                open={snakbaropen}
                onClose={handleClose}
                message={snackBarMsg}
                key='bottomcenter'
            />
        </>

    )
}

export default CancelSlot
